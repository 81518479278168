import { useEffect, useRef, useState } from 'react'

import {
    Stack,
    Paper,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Chip,
    Typography,
    ListItemSecondaryAction,
    IconButton,
    Box,
    Toolbar,
    Menu,
    TextField,
    Dialog,
    DialogContent,
    Divider,
    AppBar,
    Snackbar,
    Alert,
    ListSubheader
} from '@mui/material'

import { Logout, Clear } from '@mui/icons-material'
import Upload from '../../../../Components/Upload/Upload';

import { fetchAdminClientsList, fetchAdminPropertiesList, fetchPropertySortOrder, fetchSortOrder, fetchUnknownPropertyStats } from '../../../../API/API'
import { useDispatch, useSelector } from 'react-redux'
import { updateClient, updateProperty, updateCpAccessID } from '../../../../Redux/actions'
import { SuspenseLoading } from '../../../../Components/SuspenseLoading'
import { useNavigate } from "react-router-dom";

import { requestSearch } from '../../../../Tools/searchTool';

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { sortByBillStats } from '../../../../Tools/sortByBillstats';

const _GUTTER = 2.5

function ClientsList({
    cp_Access_id,
    goToServiceRoute = () => { },
}) {

    const dispatch = useDispatch()
    const [clientsList, setClientsList] = useState()
    const [sourceClientsList, setSourceClientsList] = useState([])
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        if (cp_Access_id) {
            getClientsList()
        }
    }, [])

    async function getClientsList() {
        setClientsList()
        let tempClientList = await fetchAdminClientsList(cp_Access_id)
        let sortOrder = await fetchSortOrder()
        if (Array.isArray(tempClientList) && tempClientList?.length > 0) {
            sortByBillStats(tempClientList)
            fifoSort(tempClientList, sortOrder)
            setClientsList(tempClientList)
            setSourceClientsList(tempClientList)
            if (!selectedClient) {
                dispatch(updateClient(tempClientList[0]))
            }
        }
    }

    function fifoSort(array, sortOrder) {
        array.sort((a, b) => {
            const indexA = sortOrder.indexOf(a.url);
            const indexB = sortOrder.indexOf(b.url);

            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });
    }

    function searchFunction(e) {
        let keysToSearch = ['name'];
        let result = requestSearch(e, sourceClientsList, keysToSearch)
        setSearchQuery(result.query)
        setClientsList(result.filterData)
    }

    const listRef = useRef(null);

    const scrollToIndex = (index) => {
        const listItem = listRef.current?.childNodes?.[index];
        if (listItem) {
            listItem.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (selectedClient && clientsList) {
            let scrollIndex = clientsList.findIndex(i => i['url'] === selectedClient['url'])
            if (scrollIndex !== -1) {
                scrollToIndex(scrollIndex)
            }
        }
    }, [clientsList])

    return (
        <>
            <Toolbar disableGutters variant='dense'
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 20
                }}
            >
                <TextField
                    size="small"
                    placeholder="Search Client"
                    variant='outlined'
                    onChange={(e) => searchFunction(e)}
                    value={searchQuery}
                    fullWidth
                />
            </Toolbar>
            <Box height={'calc(100% - 48px)'}>
                {clientsList ? clientsList.length > 0 ?
                    <List sx={{ height: '100%', overflow: 'auto' }} ref={listRef}
                        subheader={
                            <ListSubheader disableGutters>
                                <ListItem secondaryAction={
                                    <>
                                        <Chip
                                            size="small"
                                            label={clientsList.reduce((total, obj) => total + obj.to_be_posted, 0)}
                                            color="primary"
                                        />
                                        &nbsp;
                                        <Chip
                                            size="small"
                                            label={clientsList.reduce((total, obj) => total + obj.need_attention, 0)}
                                            color="warning"
                                        />
                                    </>
                                }
                                >
                                    <ListItemText primary='All Clients' />
                                </ListItem>
                            </ListSubheader>
                        }
                    >
                        {clientsList.map((client, clientIndex) => (
                            <ListItem
                                disablePadding
                                key={clientIndex}
                                divider
                                // rgba(25, 118, 210, 0.3)
                                sx={{
                                    opacity: (client.to_be_posted === 0 && client.need_attention === 0) ? 0.65 : 1,
                                    "& .Mui-selected": { ":hover": { backgroundColor: 'rgba(25, 118, 210, 0.3)' }, backgroundColor: 'rgba(25, 118, 210, 0.3)' },
                                }}
                                secondaryAction={
                                    <>
                                        <Chip
                                            size="small"
                                            label={client.to_be_posted}
                                            color="primary"
                                            onClick={() => {
                                                sessionStorage.setItem('tabvalue', 1);
                                                goToServiceRoute('bills', 'all')
                                                dispatch(updateClient(client))
                                            }}
                                        />
                                        &nbsp;
                                        <Chip
                                            size="small"
                                            label={client.need_attention}
                                            color="warning"
                                            onClick={() => { goToServiceRoute('need_attention', 'all'); dispatch(updateClient(client)) }}
                                        />
                                    </>
                                }
                            >
                                <ListItemButton
                                    selected={selectedClient?.['_id'] === client['_id']}
                                    onClick={() => { dispatch(updateClient(client)) }}
                                >
                                    <Typography fontWeight={550} fontSize={16} color="initial">
                                        {`${client.name}`}
                                    </Typography>
                                </ListItemButton>

                            </ListItem>
                        ))}
                    </List>
                    :
                    <SuspenseLoading loading={false} NotFound={
                        searchQuery === "" ?
                            `No Clients are found` :
                            `No clients found with "${searchQuery}"`
                    }
                    />
                    :
                    <SuspenseLoading loading />
                }
            </Box>
        </>
    )

}

function PropertiesList({
    cp_Access_id,
    goToServiceRoute = () => { },
}) {
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const client_uuid = selectedClient?.['userID']
    const client_url = selectedClient?.['url']

    const [propertiesList, setPropertiesList] = useState()
    const [searchQuery, setSearchQuery] = useState("")
    const [unKnownPropertyStats, setUnknownPropertyStats] = useState(null)
    const [sourcePropertiesList, setSourcePropertiesList] = useState([])

    const [uploadDialog, setUploadDialog] = useState({
        open: false,
        property: null,
    })

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: null
    })

    function closeUploadDialog() {
        setUploadDialog({
            open: false,
            property: null,
        })
    }

    useEffect(() => {
        if (selectedClient) {
            fetchPropertiesList()
            getUnknownProperty()
        }
    }, [selectedClient])

    async function fetchPropertiesList() {
        setPropertiesList()
        let tempProperties = await fetchAdminPropertiesList({ cp_Access_id, client_uuid, client_url })
        let sortOrder = await fetchPropertySortOrder(client_uuid, client_url)
        sortByBillStats(tempProperties)
        fifoSort(tempProperties, sortOrder)
        setPropertiesList(tempProperties)
        setSourcePropertiesList(tempProperties)
    }

    function fifoSort(array, sortOrder) {
        array.sort((a, b) => {
            const indexA = sortOrder.indexOf(a.corporationID);
            const indexB = sortOrder.indexOf(b.corporationID);

            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });
    }

    async function getUnknownProperty() {
        setUnknownPropertyStats()
        let tempUnknownStats = await fetchUnknownPropertyStats(client_uuid)
        setUnknownPropertyStats(tempUnknownStats)
    }

    function searchFunction(e) {
        let keysToSearch = ['corporationName', 'bookkeepingDirectory'];
        let result = requestSearch(e, sourcePropertiesList, keysToSearch)
        setSearchQuery(result.query)
        setPropertiesList(result.filterData)
    }

    return (
        <>
            <Stack gap={_GUTTER} height={'100%'}>
                <Toolbar component={Paper} variant='dense' sx={{ gap: 1 }}>
                    <Box flexGrow={1} />
                    <TextField
                        size="small"
                        placeholder="Search Properties"
                        variant='standard'
                        onChange={(e) => searchFunction(e)}
                        value={searchQuery}
                    />
                </Toolbar>

                <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 600: 2, 900: 3, 1200: 4, 1536: 4 }} style={{ overflow: 'auto', height: '100%' }}>
                    {propertiesList ? propertiesList.length !== 0 ?
                        <Masonry gutter='12px'>

                            <Paper sx={{ p: 1, minHeight: 150 }}>
                                {unKnownPropertyStats ?
                                    <>
                                        <ListItem>
                                            <ListItemText
                                                primary={selectedClient?.name}
                                                secondary='unclassified property'
                                            />
                                        </ListItem>

                                        <ListItemButton
                                            divider
                                            disabled={unKnownPropertyStats['to_be_posted'] === 0}
                                            onClick={() => {
                                                sessionStorage.setItem('tabvalue', 1);
                                                goToServiceRoute('bills', 'none')
                                            }}
                                        >
                                            <ListItemText primary='To be Approved' sx={{ color: '#030192' }} />
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={unKnownPropertyStats['to_be_posted']}
                                                    variant='filled'
                                                    color='primary'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>

                                        <ListItemButton
                                            disabled={unKnownPropertyStats['need_attention'] === 0}
                                            onClick={() => goToServiceRoute('need_attention', 'none')}
                                        >
                                            <ListItemText primary='Need Attention' sx={{ color: '#030192' }} />
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={unKnownPropertyStats['need_attention']}
                                                    variant='filled'
                                                    color='warning'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                    </>
                                    :
                                    <SuspenseLoading loading />
                                }
                            </Paper>

                            {propertiesList.map((property, propertyIndex) => (
                                <Paper key={propertyIndex} sx={{ p: 1 }}>

                                    <ListItem
                                        secondaryAction={
                                            <IconButton size="small"
                                                onClick={() =>
                                                    setUploadDialog(prev => ({
                                                        ...prev,
                                                        open: true,
                                                        property: property.bookkeepingDirectory !== '' ? property.bookkeepingDirectory : property.corporationName
                                                    }))}
                                            >
                                                <Logout fontSize="small" sx={{ transform: "rotate(-90deg)" }} />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText sx={{ mr: 2 }} primary={property.corporationName} secondary={property.bookkeepingDirectory} />
                                    </ListItem>

                                    <ListItemButton divider
                                        onClick={() => {
                                            sessionStorage.setItem('tabvalue', 1);
                                            goToServiceRoute('bills', property['corporationID'])
                                        }}
                                        disabled={property.to_be_posted === 0}
                                    >
                                        <ListItemText primary='To be Approved' sx={{ color: '#030192' }} />
                                        {property.to_be_posted !== 0 ?
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={property.to_be_posted}
                                                    variant='filled'
                                                    color='primary'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                            : property.need_attention !== 0 &&
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={property.to_be_posted}
                                                    variant='filled'
                                                    color='primary'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItemButton>

                                    <ListItemButton
                                        onClick={() => goToServiceRoute('need_attention', property['corporationID'])}
                                        disabled={property.need_attention === 0}
                                    >
                                        <ListItemText primary='Need Attention' sx={{ color: '#030192' }} />
                                        {property.need_attention !== 0 ?
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={property.need_attention}
                                                    variant='filled'
                                                    color='warning'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                            :
                                            property.to_be_posted !== 0 &&
                                            <ListItemSecondaryAction>
                                                <Chip
                                                    label={property.need_attention}
                                                    variant='filled'
                                                    color='warning'
                                                    size="small"
                                                />
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItemButton>

                                </Paper>
                            ))}

                        </Masonry>
                        :
                        <SuspenseLoading loading={false} NotFound={
                            searchQuery === "" ?
                                `No properties found for this ${selectedClient.name}` :
                                `No properties found with "${searchQuery}"`
                        }
                        />
                        :
                        <SuspenseLoading loading />
                    }
                </ResponsiveMasonry>
            </Stack>

            <Dialog open={uploadDialog.open} fullWidth onClose={closeUploadDialog}>
                <AppBar position='static' color='inherit' sx={{ boxShadow: 'none' }}>
                    <Toolbar variant='dense'>
                        <Typography flexGrow={1} variant='h6' color={'#151D48'}>Upload</Typography>
                        <IconButton onClick={() => closeUploadDialog()} >
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />

                <DialogContent>
                    <Upload
                        client={selectedClient}
                        setAlert={setAlert}
                        callback={() => { closeUploadDialog() }}
                        property={uploadDialog.property}
                    />
                </DialogContent>
            </Dialog>

            <Snackbar
                open={alert.open}
                autoHideDuration={2000}
                onClose={() => setAlert({ open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={alert.severity ? alert.severity : 'success'}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default function AdminInvoiceProcessing({
    serviceDetails = {}
}) {
    const dispatch = useDispatch()
    const navigation = useNavigate()

    const cp_Access_id = serviceDetails['cp_Access_id']

    function goToServiceRoute(path, propertyId) {
        navigation(`/invoice_processing/${path}`)
        dispatch(updateProperty(propertyId))
        dispatch(updateCpAccessID(cp_Access_id))
    }


    return (
        <Stack height={'100%'} direction={'row'} gap={_GUTTER}>
            <Paper sx={{ height: '100%', width: '25%', pr: 0.5, pl: 0.5, overflow: 'auto', }}>
                <ClientsList cp_Access_id={cp_Access_id} goToServiceRoute={goToServiceRoute} />
            </Paper>
            <Box height={'100%'} width={'75%'}>
                <PropertiesList cp_Access_id={cp_Access_id} goToServiceRoute={goToServiceRoute} />
            </Box>
        </Stack>
    )
}