import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { getPaymentTypeList } from '../../../../../API/NimbleAPI';

const PaymentType = () => {

    const { values, touched, errors, setFieldValue } = useFormikContext()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const [paymentTypeList, setPaymentTypeList] = useState([])

    useEffect(() => {
        setPaymentTypeList([])
        if (values['property']) {
            fetchPaymentTypeList()
        }
    }, [values['property']])

    useEffect(() => {
        let vendor = values['vendor']

        if (vendor && paymentTypeList.length > 0) {


            let paymentMethodID = vendor['paymentMethodID']
            let defaultPaymentMethod = ""

            setFieldValue('payment_type', defaultPaymentMethod);

            if (paymentMethodID && paymentMethodID !== '') {
                defaultPaymentMethod = paymentTypeList.find(i => i['id'] === paymentMethodID) || ""
            }
            else {
                defaultPaymentMethod = paymentTypeList.find(i => i['name']?.toLowerCase() === "check") || ""
            }

            setFieldValue('payment_type', defaultPaymentMethod);
        }

    }, [values['vendor'], paymentTypeList])

    async function fetchPaymentTypeList() {
        let tempPaymentList = await getPaymentTypeList(values['property']['corporationID'], client)
        setPaymentTypeList(tempPaymentList['types'])
    }

    // disabled={values['transactionType'] === 3}

    return (
        <FormControl disabled={paymentTypeList.length === 0} required fullWidth size="small" >
            <InputLabel>Payment Type</InputLabel>
            <Select
                label="Payment Type"
                onChange={(e) => setFieldValue("payment_type", e.target.value)}
                value={values["payment_type"] || ""}
                error={Boolean(touched.payment_type && errors.payment_type)}
            >
                {paymentTypeList.map((option, index) =>
                    <MenuItem key={index} value={option}>
                        {option.name}
                    </MenuItem>
                )}
            </Select>

            <FormHelperText>
                {touched.payment_type && errors.payment_type}
            </FormHelperText>

        </FormControl>
    )
}

export default PaymentType