export const Bill_Process_Status = {
    TO_BE_POSTED: 0,
    POSTED: 1,
    FAILED_BY_USER: -1,
    DUPLICATE: -2,
    EXTRACTION_FAILED: -3,
    MARK_AS_INACTIVE: -4,
    WITH_OUT_ATTACHMENT: -5,
    OCR_FAILED: -6,
    INVALID_FILE: -7,
    PROCESS_Failed_File: -8
};