import { useRouteLoaderData } from 'react-router-dom'

import React, { useState } from 'react'

import {
    Button,
    Grid,
    Card,
    CardContent,
    CardHeader
} from '@mui/material';

import { Logout } from '@mui/icons-material';
import UserInvoiceProcessing from './UserInvoiceProcessing/UserInvoiceProcessing'

const UserDashboard = ({ microServices }) => {
    // let data = useRouteLoaderData('main')
    // const microServices = data['micro_services']
    const [uploadDialog, setUploadDialog] = useState(() => microServices.reduce((obj, i) => ({ ...obj, [i.micro_service_name]: false }), {}))

    const MicroServiceCard = ({ value }) => {
        let service_name = value['micro_service_name']

        const components = {
            invoice_processing: UserInvoiceProcessing,
            // daily_sales: ,
            // str: ,
            // payroll: ,
        };
        const Component = components[service_name];

        if (!Component) {
            return null; // or handle unknown service names accordingly
        }

        return (
            <Component
                closeUploadDialog={closeUploadDialog}
                openDialog={uploadDialog[service_name]}
                serviceDetails={value}
            />
        );
    };

    function closeUploadDialog() {
        let tempUploadDialog = { ...uploadDialog }
        let openedDialog = Object.keys(tempUploadDialog).find(key => tempUploadDialog[key] === true)
        tempUploadDialog[openedDialog] = false
        setUploadDialog(tempUploadDialog)
    }

    return (
        <Grid container spacing={2} height={'100%'} overflow={'auto'}>
            {microServices.map((value, index) => {
                let service_name = value['micro_service_name']
                let microserviceCount = microServices.length
                return (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={microserviceCount === 1 ? 12 : 6}
                        xl={microserviceCount === 1 ? 12 : 6}
                        sx={{ height: microserviceCount > 2 ? '50%' : '100%' }}
                        key={`${service_name}+${index}`}
                    >
                        <Card sx={{ height: '100%' }}>
                            <CardHeader title={service_name?.replace('_', ' ')}
                                action={
                                    <Button
                                        variant="outlined"
                                        size='large'
                                        color='uploadBtn'
                                        onClick={() => setUploadDialog({ ...uploadDialog, [service_name]: true })}
                                    >
                                        <Logout fontSize='small' sx={{ transform: 'rotate(-90deg)' }} />
                                    </Button>
                                }
                                sx={{ '& .MuiCardHeader-title': { textTransform: 'capitalize' } }}
                            />
                            <CardContent sx={{ height: 'calc(100% - 64px)' }}>
                                <MicroServiceCard value={value} />
                            </CardContent>
                        </Card>
                    </Grid>
                )
            })}

        </Grid>
    )
}

export default UserDashboard