import React from 'react'
import {
    AdminPanelSettings,
    AppRegistration,
    Business,
    Dashboard,
    Error,
    Home,
    Inbox,
    Newspaper,
    People,
    PlaylistRemove,
    Settings,
    Speed,
    Unsubscribe
} from "@mui/icons-material";
import Bills from '../Pages/Bills/Bills';
import InactiveBills from '../Pages/InactiveBills/InactiveBills';
import NeedAttention from '../Pages/NeedAttention/NeedAttention';
import Users from '../Pages/Users/Users'
import { InboxComponent } from '../Pages/InboxComponent/InboxComponent';
import Accuracy from '../Pages/Accuracy/Accuracy';

export const routings = [
    {
        name: "Inbox",
        route: "inbox",
        icon: <Inbox />,
        component: <InboxComponent />
    },
    {
        name: "Bills",
        route: "bills",
        icon: <Newspaper />,
        component: <Bills />
    },
    {
        name: "Need Attention",
        route: "need_attention",
        icon: <Error />,
        component: <NeedAttention />
    },
    {
        name: "Inactive Bills",
        route: "inactive_bills",
        icon: <PlaylistRemove />,
        component: <InactiveBills />
    },
    {
        name: "Accuracy",
        route: "accuracy",
        icon: <Speed />,
        component: <Accuracy />
    },
    // {
    //     name: "Vendor",
    //     route: "vendor",
    //     icon: <Business />,
    // },
    {
        name: "Users",
        route: "users",
        icon: <People />,
        component: <Users />
    },
    // {
    //     name: "Control Panel",
    //     route: "control_panel",
    //     icon: <Settings />,
    //     component: ControlPanel
    // },
];

export const fetchRoutes = ({ tempServices = [] }) => {
    let final_array = []
    routings.forEach(route => {
        let filterServices = tempServices?.filter(service => route.route === service.service_name);
        filterServices?.forEach(service => {
            let { component, icon, ...props } = route
            let final_obj = { ...props, service_id: service['service_id'] }
            final_obj['icon'] = icon
            final_array.push(final_obj)
        })
    })
    return final_array
}
