import { Box, CircularProgress, Typography } from "@mui/material";

export function SuspenseLoading({ NotFound = '', loading = false, height = '100%', width = '100%' }) {
    return (
        <Box height={height} display={'grid'} sx={{ placeItems: 'center' }} width={width}>
            {
                loading ?
                    <CircularProgress color='nav' />
                    :
                    <Typography>{NotFound}</Typography>
            }
        </Box>
    )
}