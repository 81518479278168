import { Autocomplete, Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux'
import { SuspenseLoading } from '../../../../SuspenseLoading'
import { getCountries, getStates, getVendors, saveVendor } from '../../../../../API/NimbleAPI'
import { useSnackbar } from 'notistack';

const AddVendor = ({ invoiceDetails, reFetchingVendors, closeAddVendorDialog }) => {
    const { values, setFieldValue } = useFormikContext()
    const { enqueueSnackbar } = useSnackbar();

    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const initialValues = {
        "vendor_address": invoiceDetails?.['vendor_address'],
        "vendor_city": invoiceDetails?.['vendor_city'],
        "vendor_name": invoiceDetails?.['vendor_name'],
        "vendor_zipcode": invoiceDetails?.['vendor_zipcode'],
        "federal_id": "",
        "state": null,
        "country": null
    }

    async function addVendorToNimble(props) {
        const { vendor_address, vendor_city, vendor_zipcode, vendor_name, federal_id, state, country } = props
        let propertyId = values['property']['corporationID']
        let body = {
            "ID": "",
            "CorporationID": propertyId,
            "Domain": client?.url,
            "VendorName": vendor_name,
            "Address1": vendor_address,
            "Address2": "",
            "FederalID": federal_id,
            "ZipCode": vendor_zipcode,
            "City": vendor_city,
            "StateID": state?.['id'],
            "CountryID": country?.['id']
        }

        let data = await saveVendor(body, client)

        let message = data['message']
        let variant = 'error'

        if (data['status'] === 100) {
            setFieldValue('vendor', null)
            let vendorList = await reFetchingVendors()
            let defaultVendor = Array.isArray(vendorList) ? vendorList.find(i => i['id'] === data['id']) : null
            console.log(defaultVendor)
            setFieldValue('vendor', defaultVendor)
            variant = 'info'
            closeAddVendorDialog()
        }
        enqueueSnackbar(message, { variant })
        return
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={addVendorToNimble}
        >
            {({ handleChange, values, isSubmitting, handleSubmit }) => (
                !isSubmitting ?
                    <Form onSubmit={handleSubmit}>
                        <Box gap={2} display={'grid'} gridTemplateColumns={"repeat(2, 1fr)"}>
                            <TextField
                                size='small'
                                label="Name"
                                value={values['vendor_name']}
                                name='vendor_name'
                                onChange={handleChange}
                                required
                            />
                            <InputMask
                                mask="99-9999999"
                                value={values['federal_id']}
                                onChange={handleChange}
                                maskChar={null}
                            >
                                {() =>
                                    <TextField
                                        size='small'
                                        label="Federal ID"
                                        name='federal_id'
                                        required
                                    />
                                }
                            </InputMask>
                            <TextField
                                size='small'
                                label="Address"
                                value={values['vendor_address']}
                                name='vendor_address'
                                onChange={handleChange}
                            />

                            <CountryList />
                            <StateList />

                            <TextField
                                size='small'
                                label="City"
                                value={values['vendor_city']}
                                name='vendor_city'
                                onChange={handleChange}
                            />

                            <TextField
                                size='small'
                                label="Zip Code"
                                value={values['vendor_zipcode']}
                                name='vendor_zipcode'
                                onChange={handleChange}
                            />
                        </Box>
                        <DialogActions>
                            <Button color='error' onClick={closeAddVendorDialog}>Cancel</Button>
                            <Button color='nav' type='submit'>Submit</Button>
                        </DialogActions>
                    </Form>
                    :
                    <SuspenseLoading loading />
            )}


        </Formik>
    )
}

function CountryList() {
    const [countryList, setCountryList] = useState(null)
    const { handleChange, values } = useFormikContext()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    useEffect(() => {
        async function fetchCountryList() {
            let tempCountryList = await getCountries(client)
            setCountryList(tempCountryList)
        }
        fetchCountryList()
    }, [])

    return countryList ?
        <FormControl fullWidth size="small" disabled={countryList?.length === 0}>
            <InputLabel>Country</InputLabel>
            <Select
                label="Country"
                name='country'
                onChange={handleChange}
                value={values['country'] || ""}
            >
                {countryList.map((country) => (
                    <MenuItem key={country['id']} value={country}>
                        {country['name']}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        :
        <SuspenseLoading loading />
}

function StateList() {
    const [stateList, setStateList] = useState([])
    const { values, setFieldValue } = useFormikContext()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const country = values['country']

    useEffect(() => {
        async function fetchStateList() {
            setStateList(null)
            let tempStateList = await getStates(country['id'], client)
            setStateList(tempStateList)
        }
        if (country) {
            fetchStateList()
        }
    }, [country])

    return stateList ? stateList.length > 0 &&
        <Autocomplete
            size='small'
            value={values['state']}
            options={stateList}
            disabled={!stateList || stateList?.length === 0}
            getOptionLabel={(option) => option.name}
            onChange={(e, v) => setFieldValue('state', v)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label='State'
                />
            }
        />
        :
        <SuspenseLoading loading />

}

export default AddVendor