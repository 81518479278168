import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import Vendor from './DropDowns/Vendor'
import Contract from './DropDowns/Contract'
import PaymentType from './DropDowns/PaymentType'
import BanksAndCredit from './DropDowns/BanksAndCredit'
import BillPayGrid from './BillPayGrid/BillPayGrid'
import COA from './COA/COA'

import {
    Autocomplete,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Divider,
    IconButton,
    Stack
} from '@mui/material'

import {
    CalendarMonth,
    Delete,
    GradingOutlined,
    ReportProblem,
    Splitscreen,
    Visibility,
} from '@mui/icons-material'

import InputMask from 'react-input-mask'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import BooksDate from './BooksDate'
import currency from 'currency.js'

export const convert_PstD_InvNum = (key, update) => {
    let date = new Date()
    let value = moment(date).format('MM/DD/YYYY')
    update(key, value)
}

const convert_InvD_InvNum = (date, update) => {
    let invDt = moment(date).format('MM/DD/YYYY')
    if (invDt !== undefined) {
        let value = invDt.replaceAll("/", '')
        update('invoice_number', value)
    }
}

function calcCreditDays(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2);
    // Difference in milliseconds
    var timeDiff = d2.getTime() - d1.getTime();
    // Convert milliseconds to days
    var daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
}

function amountDifference(values) {
    const invoiceAmount = currency(values['invoice_amount']).value
    const splitTotal = values['splitDetails'].reduce((acc, obj) => acc + currency(obj.Amount).value, 0)
    return currency(invoiceAmount - splitTotal).value
}

const ReviewForm = ({ invoiceDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { values, handleChange, touched, errors, setFieldValue } = useFormikContext()
    const propertiesList = useSelector(state => state.SNAP_STORE.propertiesList) || []
    const transactionType = values['transactionType']

    const convert_creediDt_InvNum = (vendor, invoice_date, update) => {
        let inv_dt = new Date(invoice_date)
        var numberOfDaysToAdd
        let value;

        // let tempDueDate = fetchPaymentDueDate(invoice_date)

        if (vendor) {
            if (vendor.creditDays && vendor.creditDays !== "") {
                numberOfDaysToAdd = Number(vendor.creditDays);
                inv_dt.setDate(inv_dt.getDate() + numberOfDaysToAdd);
                value = moment(inv_dt).format('MM/DD/YYYY')
            }
            // else if (tempDueDate !== "") {
            //     value = tempDueDate
            // }
            else {
                let person = prompt("Credit days are not Available for this vendor, Enter Credit Days :", 15);
                if (person == null || person == "") {
                    value = ''
                }
                else {
                    numberOfDaysToAdd = Number(person);
                    inv_dt.setDate(inv_dt.getDate() + numberOfDaysToAdd);
                    value = moment(inv_dt).format('MM/DD/YYYY')
                }
            }

            update('due_date', value)
        }
        else {
            enqueueSnackbar('Please select the vendor', { variant: 'warning' })
        }
    }

    const GUTTER = 1.5

    return (
        <Stack p={GUTTER} width={'100%'} gap={GUTTER} overflow={'auto'} flexGrow={1}>
            <Autocomplete
                size='small'
                disabled={transactionType !== 0}
                options={propertiesList}
                getOptionLabel={option => option.name}
                value={propertiesList.find(property => property._id === values['property']?._id) || null}
                fullWidth
                onChange={(e, value) => setFieldValue("property", value)}
                renderInput={params => (
                    <TextField
                        label="Properties"
                        required
                        error={Boolean(touched.property && errors.property)}
                        helperText={touched.property ? errors.property :
                            values['property']?.['address1'] !== "" ?
                                values['property']?.['address1'] :
                                values['property']?.['address2']
                        }
                        FormHelperTextProps={{ sx: { color: 'black' } }}
                        {...params}
                    />
                )}
            />

            <Vendor invoiceDetails={invoiceDetails} />

            <Box gap={GUTTER} display={'grid'} gridTemplateColumns={"repeat(2, 1fr)"}>
                <Contract />
                <FormControl fullWidth size="small">
                    <InputLabel>Transaction Type</InputLabel>
                    <Select
                        label="Transaction Type"
                        onChange={(e) => {
                            setFieldValue('transactionType', e.target.value);
                            setFieldValue('memo', "")
                            setFieldValue('check_number', "")
                        }}
                        value={transactionType}
                    >
                        {["Bill Entry (default)", "Bill Pay", "Check", "Credit Card"].map((transactionType, trTypeIndex) => (
                            <MenuItem key={trTypeIndex} value={trTypeIndex}>
                                {transactionType}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {[0, 1].includes(transactionType) &&
                    <PaymentType />
                }

                {transactionType !== 0 && <BanksAndCredit />}
            </Box>
            <Divider />
            <Box gap={GUTTER} display={'grid'} gridTemplateColumns={"repeat(2, 1fr)"}>
                {/* //!Check Number */}
                {(transactionType === 2 || (transactionType === 1 && values['payment_type']?.name?.toLowerCase() === "check")) &&
                    < TextField
                        type='number'
                        size="small"
                        label="Check Number"
                        onChange={handleChange}
                        value={values['check_number']}
                        name={'check_number'}
                        error={Boolean(touched.check_number && errors.check_number)}
                        helperText={touched.check_number && errors.check_number}
                        required
                    />
                }

                {/* //! ----{'>'} Start Invoice Fields */}
                {/* //!Number */}
                <TextField
                    label={values['transactionType'] === 0 ? 'Invoice Number' : 'Ref.Number'}
                    value={values['invoice_number']}
                    name='invoice_number'
                    onChange={handleChange}
                    error={Boolean(touched.invoice_number && errors.invoice_number)}
                    helperText={touched.invoice_number && errors.invoice_number}
                    required
                    size='small'
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => convert_InvD_InvNum(values['invoice_date'], setFieldValue)}>
                                <CalendarMonth />
                            </IconButton>
                        )
                    }}
                    InputLabelProps={values['invoice_number'] ? { shrink: true } : {}}
                />

                {/* //!Date */}
                <InputMask
                    mask="99/99/9999"
                    value={values['invoice_date']}
                    onChange={handleChange}
                    alwaysShowMask
                    maskPlaceholder='MM/DD/YYYY'
                >
                    {() =>
                        <TextField
                            label={values['transactionType'] === 0 ? 'Invoice Date' : 'Payment Date'}
                            error={Boolean(touched.invoice_date && errors.invoice_date)}
                            helperText={touched.invoice_date && errors.invoice_date}
                            required
                            size='small'
                            name='invoice_date'
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => convert_PstD_InvNum('invoice_date', setFieldValue)}>
                                        <CalendarMonth />
                                    </IconButton>
                                )
                            }}
                        />
                    }
                </InputMask>

                {/* //!Amount */}
                <TextField
                    label={values['transactionType'] === 0 ? 'Invoice Amount' : 'Payment Amount'}
                    value={values['invoice_amount']}
                    disabled={values['transactionType'] === 1}
                    name='invoice_amount'
                    onChange={(e) => {
                        handleChange(e);
                        if (values['splitDetails'].length === 1) {
                            values['splitDetails'][0]['Amount'] = e.target.value
                        }
                    }}
                    error={Boolean(touched.invoice_amount && errors.invoice_amount)}
                    helperText={touched.invoice_amount && errors.invoice_amount}
                    required
                    size='small'
                    InputLabelProps={values['invoice_amount'] ? { shrink: true } : {}}
                />

                {/* //! ----{'>'} End Invoice Fields */}

                {transactionType === 0 &&
                    <>
                        {/* //! DueDate */}
                        <InputMask
                            mask="99/99/9999"
                            value={values['due_date']}
                            onChange={handleChange}
                            alwaysShowMask
                            maskPlaceholder='MM/DD/YYYY'
                        // maskChar={null}
                        >
                            {() =>
                                <TextField
                                    label='Payment Due date'
                                    error={Boolean(touched.due_date && errors.due_date)}
                                    name='due_date'
                                    helperText={touched.due_date ?
                                        errors.due_date :
                                        <>
                                            {`Credit days Added ${calcCreditDays(values['invoice_date'], values['due_date'])}`}
                                            {(values['vendor']?.creditDays && values['vendor']?.creditDays !== "") &&
                                                <>
                                                    <br />
                                                    {`Credit days from nimble ${values['vendor']?.creditDays}`}
                                                </>
                                            }
                                        </>
                                    }
                                    required
                                    size='small'
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => convert_creediDt_InvNum(values['vendor'], values['invoice_date'], setFieldValue)}>
                                                <CalendarMonth />
                                            </IconButton>
                                        )
                                    }}
                                />
                            }
                        </InputMask>
                        <Box />
                        <BooksDate />
                    </>
                }
            </Box>
            <Divider />
            <TextField multiline minRows={1} label='Memo' value={values['memo']} name='memo' onChange={handleChange} />
            <Divider />
            {transactionType === 1 ?
                <Box>
                    <BillPayGrid invoiceDetails={invoiceDetails} />
                </Box>
                :
                <>
                    <COA />
                    <Stack alignItems={'end'}>
                        <TextField
                            label={'Difference'}
                            size='small'
                            InputProps={{ readOnly: true }}
                            error={amountDifference(values) !== 0}
                            value={amountDifference(values)}
                        />
                    </Stack>

                </>
            }
        </Stack>
    )
}

export default ReviewForm