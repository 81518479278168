import React, { useEffect, useState } from 'react'
import {
    Box,
    TextField,
    Toolbar,
    IconButton,
    Dialog,
    Badge,
} from '@mui/material'
import { RemoveRedEye } from '@mui/icons-material'
import { requestSearch } from '../../../Tools/searchTool'
import CustomTable from '../../../Components/CustomTable/CustomTable'
import { Transition } from '../../../Components/Transition'
import { useOutletContext } from 'react-router-dom'
import CheckIsActionAllow from '../../../Tools/checkIsActionAllow'
import { useDispatch } from 'react-redux'
import { updateOpenReviewForm, updatePageProperties } from '../../../Redux/actions'
import ReviewPage from '../../../Components/ReviewPage/ReviewPage'

const ToBeApproved = ({
    loading = false,
    data = [],
    callback = () => { }
}) => {

    const dispatch = useDispatch()
    const outletContext = useOutletContext()

    let allowedActions = outletContext['allowedActions']
    const [selectedBillIndex, setSelectedBillIndex] = useState(-1)

    const _SERIES_KEY = 'from_Address'
    const _GROUP_HEADER = 'file_name'

    const [tableData, setTableData] = useState([])
    const [sourceTableData, setSourceTableData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')

    const tableColumns = [
        {
            'key': '#',
            'label': 'From Address',
            'flexgrow': 2,
        },
        {
            'key': 'formatted_timestamp',
            'label': 'Created Time',
            'flexgrow': 2,
        },
        {
            'key': 'corporation_name',
            'label': 'Property',
            'flexgrow': 2,
        },
        {
            'key': 'vendor_name',
            'label': 'Vendor Name',
            'flexgrow': 2,
        },
        {
            'key': 'invoice_number',
            'label': 'Invoice Number',
            'flexgrow': 1,
        },
        {
            'key': 'invoice_date',
            'label': 'Invoice Date',
            'flexgrow': 1,
        },
        {
            'key': 'invoice_amount',
            'label': 'Invoice Amount',
            'flexgrow': 0,
        }
    ]

    useEffect(() => {
        setTableData(data)
        setSourceTableData(data)
    }, [data])

    function searchFunction(e) {
        // let keysToSearch = [...tableColumns.map(i => i.key), _GROUP_HEADER, _SERIES_KEY];
        let keysToSearch = [...tableColumns.map(i => i.key), _GROUP_HEADER, _SERIES_KEY].filter(key => key !== '#');
        let result = requestSearch(e, data, keysToSearch)
        setSearchQuery(result.query)
        setTableData(result.filterData)
    }

    function closeReviewPanel() {
        setSearchQuery('')
        callback()
        setSelectedBillIndex(-1)
        dispatch(updatePageProperties({
            blocked_pages: [],
            split_pages: [],
            invoice_pages: [],
            checked_pages: []
        }))
        dispatch(updateOpenReviewForm(false))
    }

    function updateBills(id) {
        let tempData = [...sourceTableData]
        let index = tempData.findIndex(i => i['_id'] === id)
        if (index !== -1) {
            tempData.splice(index, 1);
            setSourceTableData(tempData)
        }
    }

    return (
        <>

            <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
                <TextField
                    size='small'
                    placeholder='Search'
                    value={searchQuery}
                    onChange={(e) => searchFunction(e)}
                    disabled={data.length === 0}
                />
            </Toolbar>

            <Box sx={{ height: 'calc(100% - 64px)', }}>
                <CustomTable
                    groupBy='file_table_id'
                    groupHeader={_GROUP_HEADER}
                    data={Array.isArray(tableData) ? tableData : []}
                    columns={tableColumns}
                    searchQuery={searchQuery}
                    seriesKey={_SERIES_KEY}
                    loader={loading}
                    action={CheckIsActionAllow(allowedActions, 'review')}
                    ActionComponent={(rowData) =>
                        <IconButton size='small' color='nav'
                            onClick={() => {
                                let index = data.findIndex(i => i['_id'] === rowData['_id'])
                                setSelectedBillIndex(index)
                            }}
                        >
                            <Badge
                                badgeContent={rowData['no_of_pages']} //!Add this key to the extractor
                                color="add"
                                invisible={rowData['no_of_pages'] <= 1}
                                sx={{ '& span': { color: 'white' } }}

                            >
                                <RemoveRedEye />
                            </Badge>
                        </IconButton>
                    }
                    stampOnRow
                    isTree
                    isProperty
                    rowHeight={58}
                />
            </Box>

            <Dialog open={selectedBillIndex !== -1} onClose={closeReviewPanel} fullScreen TransitionComponent={Transition}>
                <ReviewPage
                    bills={sourceTableData}
                    initialBillIndex={selectedBillIndex}
                    closeReviewPanel={closeReviewPanel}
                    updateBills={updateBills}
                />
            </Dialog>

        </>
    )
}
export default ToBeApproved