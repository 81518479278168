import axios from "axios";

const NIMBLE_API =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_NIMBLE_API_PRODUCTION
    : process.env.REACT_APP_NIMBLE_API_STAGING;

export const nimble = axios.create({
  baseURL: `${NIMBLE_API}/api/data`,
  // headers: {
  //     "Content-Type": "application/json",
  //     "token": client.token,
  //     "Domain": client.url,
  //     "UserID": client.userID
  // }
});

function getHeaders(client) {
  return {
    "Content-Type": "application/json",
    token: client.token,
    Domain: client.url,
    UserID: client.userID,
  };
}

export async function isBookDateEnabled(client) {
  try {
    let response = await nimble.post(
      "/IsBookDateEnabled",
      {},
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function addBillToAttachment(body, client) {
  let headers = getHeaders(client)
  headers['UserID'] = client['userDetails']['userID']
  headers['token'] = client['userDetails']['token']
  try {
    let response = await nimble.post("/Addbillattachments", body, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getVendors(CorporationID, client) {
  try {
    let response = await nimble.post(
      "/VendorsList",
      { CorporationID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function saveVendor(body, client) {
  try {
    let response = await nimble.post("/VendorSave", body, {
      headers: getHeaders(client),
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getCountries(client) {
  try {
    let response = await nimble.post(
      "/LoadCountryList",
      {},
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getStates(ID, client) {
  try {
    let response = await nimble.post(
      "/LoadStateList",
      { ID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getDefaultAccount(VendorID, client) {
  try {
    let response = await nimble.post(
      "/LoadVendorPrimaryAccount",
      { VendorID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getContracts(VendorID, client) {
  try {
    let response = await nimble.post(
      "/contractlist",
      { VendorID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getPaymentTypeList(CorporationID, client) {
  try {
    let response = await nimble.post(
      "/GetPaymentTypeList",
      { CorporationID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAccountTypeList(CorporationID, client) {
  try {
    let response = await nimble.post(
      "/GetAccountTypeList",
      { CorporationID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getAccountsList(CorporationID, client) {
  try {
    let response = await nimble.post(
      "/GetAccountsList",
      { CorporationID },
      { headers: getHeaders(client) }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getProfitCenter(CorporationID, client) {
  let timestamp = new Date().getTime();
  try {
    let response = await nimble.post(
      "/GetProfitCentersList/",
      { CorporationID },
      { headers: getHeaders(client), params: { timestamp } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getBillsList(body, client) {
  try {
    let response = await nimble.post("/Getbillslist", body, {
      headers: getHeaders(client),
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

// Get List of All Vendors, Employee, Customers and Others for a specific
export async function getVECOList(CorporationID, client) {
  try {
    let response = await nimble.post("/VECOList", { CorporationID }, {
      headers: getHeaders(client),
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function sendToNimbleAPI(endpoint, body, client) {
  let headers = getHeaders(client)
  headers['UserID'] = client['userDetails']['userID']
  headers['token'] = client['userDetails']['token']
  try {
    let response = await nimble.post(endpoint, body, {
      headers: headers,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}
