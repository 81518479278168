import { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  ListItemText,
  Divider,
  MenuList,
  Autocomplete,
  TextField,
  Breadcrumbs,
  Link,
  Badge,
  Dialog,
  DialogContent,
  List,
  ListItem,
  IconButton,
  CardContent,
  CardActions,
  CardHeader,
  Card,
  Stack,
  Backdrop,
  Chip,
  Box,
  Zoom
} from "@mui/material";

import { Toolbar as MuiToolbar } from "@mui/material"

import {
  ArrowBackIos,
  AttachFile,
  Clear,
  CopyAll,
  EmailOutlined,
  Favorite,
  Home,
  KeyboardArrowDown,
  Logout,
  MoveToInboxSharp,
  PersonOutlined,
  Refresh,
  Send,
  SettingsOutlined,
  Share,
  Visibility,
} from "@mui/icons-material";
import { fetchClientList, fetchCpList, fetchPropertiesList, logout, move_to_need_attention } from "../API/API";
import { SuspenseLoading } from "../Components/SuspenseLoading";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { updateClient, updateClientsList, updatePropertiesList, updateProperty } from "../Redux/actions";
import { nimble } from "../API/NimbleAPI";
import axios from "axios";
import { getTimeStamp } from "../Tools/formattedTimeStamp";
import { red } from "@mui/material/colors";
import { Transition } from "../Components/Transition";
import zIndex from "@mui/material/styles/zIndex";

import {
  Worker,
  Viewer,
  ProgressBar,
  MinimalButton,
  Icon

} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { SnackbarProvider, enqueueSnackbar } from "notistack";

export default function Header() {


  const params = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);

  const cp_access_id = useSelector(state => state.SNAP_PERSIST_STORE.cp_access_id)
  const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
  const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
  const user = JSON.parse(sessionStorage.getItem('user'))

  const propertiesList = useSelector(state => state.SNAP_STORE.propertiesList)
  const clientList = useSelector(state => state.SNAP_STORE.clientList)

  const clientLoadRef = useRef()
  const propertyLoadRef = useRef()

  async function handleLogout() {
    window.location.href = "/";
    sessionStorage.clear();
    await logout()
  }

  useEffect(() => {
    if (cp_access_id) {
      get_ClientList()
    }
    if (selectedClient && cp_access_id) {
      console.log(selectedClient)
      getProperties(selectedClient['userID'], selectedClient['url'])
    }
  }, [cp_access_id])

  async function get_ClientList() {
    clientLoadRef.current = true
    let tempClientList = await fetchClientList(cp_access_id)
    dispatch(updateClientsList(tempClientList))
    clientLoadRef.current = false
  }

  async function getProperties(client_id, client_url) {
    if (client_id !== 'all') {
      propertyLoadRef.current = true
      dispatch(updatePropertiesList(null))
      let tempPropertiesList = await fetchPropertiesList(cp_access_id, client_id, client_url)
      dispatch(updatePropertiesList(tempPropertiesList))
      propertyLoadRef.current = false
    }
  }

  function modifyPropertyList() {
    if (propertiesList) {
      let tempPropertyList = [...propertiesList]
      let sortedData = tempPropertyList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      sortedData.unshift({ name: "Select All Properties", corporationID: "all" })
      sortedData.push({ name: "Unclassified", corporationID: "none" })
      return sortedData
    }
    else {
      return []
    }
  }

  const [failedFiles, setFailedFiles] = useState([])
  const [openFailedDialog, setOpenFailedDialog] = useState(false)

  useEffect(() => {
    getFailedMails()
  }, [])

  async function getFailedMails() {
    let res = await axios.get('https://nimbleio.ai/tasks/failed_fails')
    if (typeof (res.data) === "string") {
      let data = JSON.parse(res.data)
      setFailedFiles(data)
    }
  }

  function closeFailedFailsDialog() {
    setOpenFailedDialog(false)
    setS3_URL()
  }

  function getInitials(name) {
    if (name) {
      let words = name.split(' ')

      if (words.length === 1) {
        return name.charAt(0);
      }
      return words[0].charAt(0) + words[1].charAt(0);
    }
  }

  function Receipts({ receipts, type }) {
    if (receipts.length === 0) {
      return undefined
    }
    return (
      <Stack direction={'row'} gap={0.5}>
        <span>{type}:</span>
        {receipts.map(obj => obj.emailAddress.address).join(', ')}
      </Stack>
    )
  }

  const [showAttachment, setShowAttachment] = useState(false)
  const [APILoading, setAPILoading] = useState(false)

  async function reProcess(item) {
    closeFailedFailsDialog()
    setAPILoading(true)
    let { status, data } = await axios.post('https://nimbleio.ai/tasks/reprocess_file/', { "_id": item['_id'] })
    enqueueSnackbar(data[0]['message'], { variant: status === 200 ? "default" : 'error' })
    if (status !== 200) {
      setOpenFailedDialog(true)
    }
    else {
      getFailedMails()
    }
    setAPILoading(false)
  }

  async function moveToNeedAttention(item) {
    closeFailedFailsDialog()
    setAPILoading(true)
    let toRecipients = item['toRecipients'].map(obj => obj.emailAddress.address)
    let ccRecipients = item['ccRecipients'].map(obj => obj.emailAddress.address)
    let receipts = [...toRecipients, ...ccRecipients]
    let billAutomationMail = receipts.find(email => email.includes('@billautomation.com'))

    if (billAutomationMail) {
      billAutomationMail = billAutomationMail.split(/[@.]/)
      let body = {
        client: billAutomationMail[0],
        property: billAutomationMail.length > 2 ? billAutomationMail[1] : null,
        s3_key: item['s3_key'],
        reference_id: item['reference_id'],
        receivedDateTime: item['receivedDateTime'],
        from: item['from']['address'],
        to: billAutomationMail,
        body_HTML: item['body_HTML'],
        id: item['_id']
      }
      let data = await move_to_need_attention(body)
      if (data['status'] === 200) {
        enqueueSnackbar(data['message'])
        await removeFile(item['_id'])
        return
      }
      enqueueSnackbar(data['message'], { variant: 'error' })
      setOpenFailedDialog(true)
      setAPILoading(false)
    }
  }

  async function removeFile(id) {
    closeFailedFailsDialog()
    setAPILoading(true)
    await axios.get('https://nimbleio.ai/tasks/update_file_status', { params: { id } })
    setAPILoading(false)
    getFailedMails()
    enqueueSnackbar('Successfully removed', { variant: 'error' })
  }

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const [S3_URL, setS3_URL] = useState()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.key === 'Backspace' && event.shiftKey) && S3_URL) {
        setS3_URL()
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [S3_URL]);

  return (
    <SnackbarProvider maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: 'white' }} >
        <MuiToolbar sx={{ alignItems: 'center', gap: 3 }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            {Object.keys(params).length === 0 ?
              <Typography
                fontWeight={'bold'}
                fontSize={'1.4rem'}
                color={"#151D48"}
              >
                Home
              </Typography>
              :
              <RouterLink
                style={{ display: 'flex', alignItems: 'center', color: '#030192' }}
                to={'/home'}
              >
                <Home sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </RouterLink>
            }

            {params['micro_service_route'] &&
              <Typography
                color="text.primary"
                textTransform={'capitalize'}
              >
                {params['micro_service_route'].replace('_', " ")}
              </Typography>
            }
            {params['service_route'] &&
              <Typography
                color="text.primary"
                textTransform={'capitalize'}
              >
                {params['service_route'].replace('_', " ")}
              </Typography>
            }
          </Breadcrumbs>

          {!['inbox', "home", 'users'].includes(params['service_route']) &&
            <>
              {clientList ?
                <Autocomplete
                  value={
                    clientList?.length === 1 ? clientList[0] :
                      (clientList.find(i => i['url'] === selectedClient?.['url']) || null)
                  }
                  options={clientList}
                  disabled={clientList.length === 1}
                  getOptionLabel={option => option?.name ? option.name : ''}
                  sx={{ width: 250 }}
                  onChange={(e, v) => {
                    dispatch(updateClient(v));
                    if (v) {
                      getProperties(v['userID'], v['url'])
                      dispatch(updateProperty(null))
                    }
                  }}
                  renderInput={params =>
                    <TextField
                      {...params}
                      placeholder="Client"
                      variant='standard'
                    />
                  }
                />
                :
                <SuspenseLoading loading={clientLoadRef.current} width='250px' />
              }

              {(selectedClient?.['userID'] === 'all' || propertiesList) ?
                <Autocomplete
                  value={modifyPropertyList().find(i => i['corporationID'] === selectedProperty) || null}
                  options={modifyPropertyList()}
                  getOptionLabel={option => option?.name ? option.name : ''}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name && option.corporationID === value.corporationID
                  }
                  disabled={selectedClient?.['userID'] === 'all'}
                  sx={{ width: 250 }}
                  onChange={(e, v) => dispatch(updateProperty(v?.['corporationID']))}
                  renderInput={params =>
                    <TextField
                      {...params}
                      placeholder="Property"
                      variant='standard'
                    />
                  }
                />
                :
                <SuspenseLoading loading={propertyLoadRef.current} width='250px' />
              }
            </>
          }

          <Button
            disableRipple
            sx={{
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            startIcon={
              <Badge overlap="circular" color='error' badgeContent={failedFiles.length}>
                <Avatar>{user['username']?.charAt(0)}</Avatar>
              </Badge>
            }
            endIcon={<KeyboardArrowDown />}
          >
            {user['username']}
          </Button>

        </MuiToolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{
          "& .MuiPaper-root": {
            marginTop: (theme) => theme.spacing(1),
          },
        }}
      >
        <MenuList disablePadding sx={{ minWidth: "208px" }}>
          <MenuItem>
            <ListItemIcon>
              <PersonOutlined />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>


          <MenuItem onClick={() => { setOpenFailedDialog(true); setAnchorEl(null) }}>
            <ListItemIcon>
              <EmailOutlined />
            </ListItemIcon>
            <Badge color='error' badgeContent={failedFiles.length}>
              <ListItemText>Notifications</ListItemText>
            </Badge>
          </MenuItem>

          <MenuItem disabled>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText>Account Settings</ListItemText>
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ textAlign: "right" }}>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      <Dialog TransitionComponent={Transition} onClose={closeFailedFailsDialog} open={openFailedDialog} maxWidth={'lg'} fullWidth>
        <Zoom in={!S3_URL} unmountOnExit>
          <Box height={'100%'} width={'100%'}>
            <AppBar color="nav" position="sticky" sx={{ top: 0 }}>
              <MuiToolbar variant="dense">
                <Typography flexGrow={1} variant="h5">Failed Files</Typography>
                <IconButton color="inherit" onClick={closeFailedFailsDialog}>
                  <Clear />
                </IconButton>
              </MuiToolbar>
            </AppBar>

            <DialogContent sx={{ backgroundColor: '#f8f8ff', p: 2 }}>
              <Stack gap={2}>
                {failedFiles.map((item, index) =>
                  <Card key={index}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: red[500], width: 46, height: 46 }} aria-label="recipe">
                          {getInitials(item['from']['name'])}
                        </Avatar>
                      }
                      action={
                        <Stack alignItems={'end'}>
                          <IconButton onClick={() => removeFile(item['_id'])}>
                            <Clear />
                          </IconButton>
                          <Typography variant='caption' >{getTimeStamp(item['timestamp'], 'Asia/Kolkata')}</Typography>
                        </Stack>
                      }
                      title={`${item['from']['name']} <${item['from']['address']}>`}
                      subheader={
                        <Stack gap={0.5}>
                          <Receipts receipts={item.toRecipients} type={"To"} />
                          <Receipts receipts={item.ccRecipients} type={"Cc"} />
                        </Stack>
                      }
                    />

                    <CardContent>
                      <Typography variant='body2'>Subject: {item['subject']}</Typography>
                      <Typography mt={1} mb={1} component={'pre'} variant="body2" color="text.secondary">
                        {item['body'] !== "" ? item['body'] : "No preview"}
                      </Typography>
                      <Chip
                        onClick={() => setS3_URL(
                          `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${encodeURIComponent(item['s3_key'])}`
                        )}
                        onDelete={() => {
                          navigator.clipboard.writeText(item['fname']);
                          enqueueSnackbar('Copied')
                        }}
                        icon={<AttachFile fontSize="small" />}
                        deleteIcon={<CopyAll />}
                        label={item['fname']}
                      />
                    </CardContent>

                    <CardActions disableSpacing sx={{ gap: 1 }}>
                      <Button size='small' variant="outlined" color="success" startIcon={<Refresh />} onClick={() => reProcess(item)}>Re-Process</Button>
                      <Button size='small' variant="outlined" color="warning" startIcon={<MoveToInboxSharp />} onClick={() => moveToNeedAttention(item)}>Need Attention</Button>
                    </CardActions>

                  </Card>

                )}
              </Stack>
            </DialogContent>
          </Box>
        </Zoom>
        {S3_URL &&
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <DialogContent sx={{ height: '95vh', overflow: 'hidden', p: 0.5 }}>
              <div
                className="rpv-core__viewer"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '0.25rem',
                  }}
                >
                  <MinimalButton
                    onClick={() => setS3_URL()}
                  >
                    <Icon size={18}>
                      <ArrowBackIos />
                    </Icon>
                  </MinimalButton>
                  <Toolbar>
                    {renderDefaultToolbar((slots) => (
                      {
                        ...slots,
                        Open: () => <></>,
                        SwitchTheme: () => <></>,
                        ShowSearchPopover: () => <></>,
                        GoToNextPage: () => <></>,
                        GoToPreviousPage: () => <></>,
                      }
                    ))}
                  </Toolbar>
                </div>
                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Viewer
                    fileUrl={S3_URL}
                    renderLoader={(percentages) => (
                      <Box width={'240px'}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </Box>
                    )}
                    plugins={[toolbarPluginInstance]}
                  />
                </div>
              </div>
            </DialogContent>
          </Worker>
        }
      </Dialog >

      <Backdrop open={APILoading} sx={{ zIndex: zIndex.appBar + 1 }}>
        <SuspenseLoading loading />
      </Backdrop>
    </SnackbarProvider>
  );
}
