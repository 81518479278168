import { useState, useEffect, useRef } from "react";
import {
    Box,
    Paper,
    Toolbar,
    TextField,
    LinearProgress,
    IconButton,
    Dialog,
    Typography,
    DialogContent,
    AppBar
} from "@mui/material";

import { useSelector } from "react-redux";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { requestSearch } from "../../Tools/searchTool";
import { Clear, Download, RemoveRedEye } from "@mui/icons-material";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Transition } from "../../Components/Transition";
import { getBills, getInactiveBills } from "../../API/API";
import { formattedTimeStamp, getTimeStamp } from "../../Tools/formattedTimeStamp";
import { Bill_Process_Status } from "../../Tools/constants";
import { s3ToFileName } from "../../Tools/s3ToFileName";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function InactiveBills() {
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client) || {}
    const [tableData, setTableData] = useState([]);
    const [editableTableData, setEditableTableData] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const cp_access_id = useSelector(state => state.SNAP_PERSIST_STORE.cp_access_id)

    useEffect(() => {
        fetchInactiveBills()
    }, [selectedProperty, selectedClient]);

    async function fetchInactiveBills() {
        setEditableTableData([]);
        if ((selectedClient && selectedProperty) || selectedClient['url'] === "all") {
            setLoading(true)
            let body = {
                client_url: selectedClient['url'],
                property_id: selectedProperty,
                cp_access_id: cp_access_id,
                status: [Bill_Process_Status.MARK_AS_INACTIVE]
            }
            let bills = await getBills(body)
            bills.forEach((obj) => {
                obj['file_name'] = s3ToFileName(obj['s3_key'])
                obj['formatted_timestamp'] = getTimeStamp(obj['timestamp'], 'Asia/Kolkata')
            });
            setTableData(bills);
            setEditableTableData(bills);
            setLoading(false)
        }
    }

    function searchFuntion(e) {
        let keysToSearch = tableColumns.map(i => i.key)
        let result = requestSearch(e, tableData, keysToSearch)
        setSearchQuery(result.query)
        setEditableTableData(result.filterData)
    }

    const tableColumns = [
        {
            'key': 'from_Address',
            'label': 'From Address',
            'flexgrow': 1
        },
        {
            'key': 'formatted_timestamp',
            'label': 'Timestamp',
            'flexgrow': 1
        },
        {
            'key': 'file_name',
            'label': 'File Name',
            'flexgrow': 1
        },
        // {
        //     'key': 'client',
        //     'label': 'Client',
        //     'flexgrow': 1
        // },
        // {
        //     'key': 'property',
        //     'label': 'Property',
        //     'flexgrow': 1,
        // },
        {
            'key': 'corporation_name',
            'label': 'Property',
            'flexgrow': 1
        },
        {
            'key': 'action_performed_by',
            'label': 'Inactive by',
            'flexgrow': 1
        },
    ]

    const [selectedBill, setSelectedBill] = useState({})
    const [fileReview, setFileReview] = useState(false)

    function fileReviewClose() {
        setSelectedBill({})
        setFileReview(false)
    }

    return (
        <>
            <Paper sx={{ height: '100%', p: 2 }}>
                <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
                    <TextField size='small' placeholder='Search' value={searchQuery} onChange={(e) => searchFuntion(e)} />
                </Toolbar>
                <Box sx={{ height: 'calc(100% - 64px)' }}>
                    <CustomTable
                        data={editableTableData}
                        columns={tableColumns}
                        searchQuery={searchQuery}
                        loader={isLoading}
                        isProperty
                        action
                        ActionComponent={(rowData) => {
                            let index = tableData.findIndex(i => i['_id'] === rowData['_id'])
                            return (
                                <IconButton onClick={() => { setSelectedBill(tableData[index]); setFileReview(true) }}>
                                    <RemoveRedEye />
                                </IconButton>
                            )
                        }}
                    />
                </Box>
            </Paper>
            <Dialog open={fileReview} onClose={fileReviewClose} TransitionComponent={Transition} fullWidth maxWidth='md'>
                <AppBar position='static' color={'nav'}>
                    <Toolbar variant="dense">
                        <Typography variant="h6" flexGrow={1}>Preview</Typography>

                        <IconButton color='inherit' onClick={fileReviewClose}>
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Document
                        file={`https://nimbleocrbills.s3.us-east-1.amazonaws.com/${encodeURIComponent(selectedBill['s3_key'])}`}
                        onLoadSuccess={({ pages }) => console.log(pages)}
                        loading={<LinearProgress color='nav' />}
                    >
                        {selectedBill['bill_pages']?.map((page, index) =>
                            <Box border={1} borderColor={'divider'} width={'fit-content'} mb={1}>
                                <Page pageNumber={page} key={index} />
                            </Box>
                        )}
                    </Document>
                </DialogContent>
            </Dialog>

        </>

    )
}