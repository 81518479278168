import { memo } from "react";
import { Box, Toolbar, Typography } from "@mui/material";


export default function Footer() {
  return (
    <Typography color={"#8a909d"} marginLeft='80px' p={1.5}>
      © {new Date().getFullYear()} Copyright NimbleIO
    </Typography >
  );
}