import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { SuspenseLoading } from '../../../../SuspenseLoading';
import { getContracts } from '../../../../../API/NimbleAPI';

const Contract = () => {
    const [contractList, setContractList] = useState([])
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const { values, setFieldValue } = useFormikContext()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setFieldValue("contract", null);
        if (values['vendor']) {
            fetchContractList()
        }
    }, [values['vendor']])

    async function fetchContractList() {
        setIsLoading(true)
        setContractList([])
        let tempData = await getContracts(values['vendor'].id, client)

        if (tempData.length > 0) {
            let defaultVendor = tempData.length === 1 ? tempData[0] : tempData.find(i => i['isDefault'])

            if (defaultVendor) {
                setFieldValue("contract", defaultVendor)
            }

            setContractList(tempData)
        }
        setIsLoading(false)
    }

    return !isLoading ?
        <Autocomplete
            options={contractList}
            getOptionLabel={option => option.contractAccountNumber !== "" ? option.contractAccountNumber : "NA"}
            value={values["contract"] || null}
            // disabled={values['transactionType'] !== 0 || contractList.length === 0}
            fullWidth
            size='small'
            onChange={(e, value) => setFieldValue("contract", value)}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Contract"
                    helperText={contractList.length === 0 && 'Contracts not found'}
                />
            )}
        />
        :
        <SuspenseLoading loading={isLoading} />
}

export default Contract