import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { SuspenseLoading } from '../../Components/SuspenseLoading'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Card, CardContent, CardHeader, Chip, IconButton, List, ListItem, ListItemText, Paper, Stack, TablePagination, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { requestSearch } from '../../Tools/searchTool';
import { formattedTimeStamp, getTimeStamp } from '../../Tools/formattedTimeStamp'

import { ArrowDownward, ArrowDropDown, AttachMoney, CalendarMonth, CheckBox, GridView, PinDrop, Public, Receipt, ShoppingCart, Splitscreen, SupervisorAccount, WatchLater } from '@mui/icons-material';

import { green, blue, yellow, red } from '@mui/material/colors';
import { DateRangePicker } from 'rsuite'
import { startOfDay, endOfDay, subDays } from 'date-fns';
import moment from 'moment'

export const InboxComponent = () => {

    const [emailsList, setEmailsList] = useState()
    const [sourceEmailsList, setSourceEmailsList] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [grid, setGrid] = useState(2)
    const [pagination, setPagination] = useState({
        start: 0,
        limit: 10
    });
    const today = new Date();
    const [dateRange, setDateRange] = useState([startOfDay(today), endOfDay(today)]);

    useEffect(() => {
        fetchEmailList()
    }, [pagination, dateRange])

    async function fetchEmailList() {
        let start = pagination['start']
        let limit = pagination['limit']
        let start_date = moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
        let end_date = moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        setEmailsList()
        let res = await axios.get(`https://nimbleio.ai/tasks/emails`, { params: { start, limit, start_date, end_date } })
        let data = res.data
        if (typeof (data) === "string") {
            data = JSON.parse(data)
            data.forEach(i => {
                i['all_files_name'] = i['files'].reduce((total, obj) => total + obj.fname, "")
                i['toRecipients'] = i['toRecipients']?.join(", ")
            })
            setEmailsList(data)
            setSourceEmailsList(data)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            start: newPage * pagination.limit
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setPagination(prevPagination => ({
            start: 0,
            limit: newRowsPerPage
        }));
    };

    function searchFunction(e) {
        let keysToSearch = ['sender', 'subject', 'all_files_name', 'toRecipients'];
        let result = requestSearch(e, sourceEmailsList, keysToSearch)
        setSearchQuery(result.query)
        setEmailsList(result.filterData)
    }

    function FilesAccordion({ files, color }) {
        let body = color[200]
        return (
            <Accordion sx={{ color: 'inherit', backgroundColor: body }}>
                <AccordionSummary expandIcon={<ArrowDownward />}>
                    <Typography>Files {`(${files.length})`}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: color[100] }}>
                    {<FilesList files={files} />}
                </AccordionDetails>
            </Accordion>
        )
    }

    function FilesList({ files }) {
        return (
            <List disablePadding sx={{ border: 1, borderColor: "divider", color: 'inherit', backgroundColor: 'inherit', }}>
                {files.map((item, index) => {
                    let entities = item['ents'][0]?.['invoices'] || []
                    return (
                        <ListItem
                            key={index}
                            divider={files.length !== index + 1}
                            secondaryAction={<Stages stages={item['stages']} />}
                            disablePadding
                            sx={{ p: 1 }}
                        >
                            <Stack width={'fit-content'} maxWidth={'80%'}>
                                <Tooltip arrow title={item['fname']}>
                                    <ListItemText
                                        primary={item['fname']}
                                        primaryTypographyProps={{ style: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '14px' } }}
                                        secondary={entities.length > 1 && `Invoices: ${entities.length}`}
                                    />
                                </Tooltip>
                                {entities.length > 0 && <InvoiceStats entities={entities} />}
                            </Stack>
                        </ListItem>
                    )
                })}
            </List>
        )
    }

    function InvoiceStats({ entities }) {
        let columns = [
            {
                key: 'invoice_amount',
                icon: AttachMoney
            },
            {
                key: 'invoice_date',
                icon: CalendarMonth
            },
            {
                key: 'payment_due_date',
                icon: WatchLater
            },
            {
                key: 'invoice_number',
                icon: Receipt
            },
            {
                key: 'vendor_name',
                icon: ShoppingCart
            },
            {
                key: 'customer_name',
                icon: SupervisorAccount
            },
        ]
        return (
            <Stack gap={0.5}>
                {entities.map((item, index) =>
                    <Stack key={index} direction={'row'} width={'100%'} borderTop={1} borderBottom={1} borderRight={1} borderColor={'divider'} >
                        {columns.map((column, i) =>
                            (item[column.key] && item[column.key] !== "") && <Item key={i} item={item[column.key]} Icon={column.icon} />
                        )}
                    </Stack >
                )}
            </Stack>
        )
    }

    function Item({ item, Icon }) {
        return (
            <Stack
                direction={'row'}
                p={0.5}
                gap={0.5}
                alignItems={'center'}
                height={'100%'}
                borderLeft={1}
                borderColor={'divider'}
                width={'fit-content'}
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                color={'rgba(0, 0, 0, 0.6)'}
            >
                <Icon fontSize='small' />
                <Tooltip arrow title={item}>
                    <Typography
                        textOverflow={'ellipsis'}
                        noWrap
                        overflow={'hidden'}
                        variant='subtitle2'
                    >
                        {item}
                    </Typography>
                </Tooltip>
            </Stack>
        )
    }

    function Stages({ stages }) {
        const totalTimeTaken = stages.reduce((total, obj) => total + obj.stage.time_taken, 0);
        return (
            <Box border={1} borderColor={'divider'}>
                <Item item={`${totalTimeTaken.toFixed(2)} s`} Icon={CheckBox} />
            </Box>
        )

    }

    const EmailCard = ({ data }) => {
        let avatar = data['sender']?.split('.') || []
        avatar = avatar.length >= 3 ?
            `${avatar[0].charAt(0).toUpperCase()}${avatar[1].charAt(0).toUpperCase()}`
            :
            avatar.length == 2 ? avatar[0].charAt(0).toUpperCase() : ''

        let color =
            data['css'] === 'success' ? green
                : data['css'] === 'error' ? red
                    : data['css'] === 'warning' ? yellow : blue

        // 50 to 900
        // for A 100 to 700 ex A200
        let body = '100'
        let text = '900'

        let files = data['files']

        function TimeStamps() {
            let timestamp = data['timestamp']
            console.log(new Date(timestamp))
            let india_zone_timestamp = getTimeStamp(timestamp, 'Asia/Kolkata');
            // (UTC-07:00) Mountain Time (US & Canada) This is the outlook time zone
            let mountain_zone_timestamp = getTimeStamp(timestamp, 'America/Denver');
            return (
                <Stack gap={0.5}>
                    <Chip size='small' label={mountain_zone_timestamp} icon={<Public />} />
                    <Chip size='small' label={india_zone_timestamp} icon={<PinDrop />} />
                </Stack>
            )
        }

        return (
            <Card sx={{ backgroundColor: color[body], p: 1.5 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ color: color[text], backgroundColor: 'white', width: 56, height: 56 }}>{avatar}</Avatar>
                    }
                    title={data['sender']}
                    subheader={
                        <>
                            {data['toRecipients']}
                            <br />
                            {data['subject']}
                        </>
                    }
                    subheaderTypographyProps={{ title: data['toRecipients'] }}
                    titleTypographyProps={{ style: { fontSize: 22, } }}
                    sx={{ color: color[text], p: 0, pr: 1, pb: 1 }}
                    action={<TimeStamps />}
                />

                {files.length !== 0 ?
                    files.length <= 3 ?
                        <FilesList files={files} />
                        :
                        <FilesAccordion files={files} color={color} />
                    :
                    <Typography>No attachments</Typography>
                }

            </Card>
        )
    };

    return (
        <>
            <Stack height={'100%'} overflow={'auto'} gap={1}>

                <Toolbar component={Paper} variant='dense' sx={{ alignItems: 'center', gap: 2 }}>

                    <TextField
                        size='small'
                        placeholder='Search'
                        value={searchQuery}
                        onChange={(e) => searchFunction(e)}
                        disabled={sourceEmailsList.length === 0}
                        variant='standard'
                    />

                    <DateRangePicker
                        placeholder='Date range'
                        value={dateRange}
                        onChange={(v) => setDateRange(v)}
                        style={{ minWidth: '250px' }}
                    />

                    <Box flexGrow={1} />

                    <IconButton onClick={() => setGrid(prev => prev === 2 ? 1 : 2)}>
                        {grid === 2 ? <Splitscreen /> : <GridView />}
                    </IconButton>

                    <TablePagination
                        component="div"
                        count={-1}
                        rowsPerPage={pagination.limit}
                        page={pagination.start / pagination.limit}

                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                        rowsPerPageOptions={[10, 20, 30]}
                    />
                </Toolbar>

                <Box overflow={'auto'} flexGrow={1}>
                    {Array.isArray(emailsList) ?
                        emailsList.length > 0 ?
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 300: 1, 600: 1, 900: 1, 1200: grid, 1536: grid }}
                            >
                                <Masonry gutter='8px'>
                                    {emailsList.map((item, index) => <EmailCard key={index} data={item} />)}
                                </Masonry>
                            </ResponsiveMasonry>
                            :
                            <SuspenseLoading loading={false} NotFound='No data found' />
                        :
                        <SuspenseLoading loading={true} />
                    }
                </Box>

            </Stack >
        </>
    )
}