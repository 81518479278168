export function sortByBillStats(docs) {

    //!Optimize this sorting logic.
    // Define a custom sorting function
    docs.sort((a, b) => {
        // Compare the values of 'to_be_posted' and 'need_attention'
        const aSum = a.to_be_posted + a.need_attention;
        const bSum = b.to_be_posted + b.need_attention;

        // If both sums are 0, maintain the original order
        if (aSum === 0 && bSum === 0) {
            return 0;
        }

        // If either sum is greater than 0, prioritize it
        if (aSum > bSum) {
            return -1; // 'a' should come before 'b'
        } else if (aSum < bSum) {
            return 1; // 'b' should come before 'a'
        } else {
            // If sums are equal, maintain the original order
            return 0;
        }
    });

    return docs;
}