
export function formattedTimeStamp(timestamp) {
    const date = new Date(timestamp);
    // Get the local time zone offset and convert it to milliseconds
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    // Adjust the date to UTC time
    const utcDate = new Date(date.getTime() + offsetMs);

    const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    };

    const formattedDate = utcDate.toLocaleDateString('en-US', options);

    return formattedDate
}

export function getTimeStamp(timestamp, timeZone) {
    return new Date(timestamp).toLocaleDateString('en-US', {
        timeZone: timeZone,
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    })
}