import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { getDefaultAccount } from '../../../../../API/NimbleAPI'

const BanksAndCredit = () => {
    const { values, errors, touched, setFieldValue } = useFormikContext()
    const banksAndCredit = useSelector(state => state.SNAP_STORE.bankAndCredit) || []
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    useEffect(() => {
        if (values['vendor']) {
            fetchDefaultAccount()
        }
    }, [values['transactionType'], banksAndCredit])

    async function fetchDefaultAccount() {
        setFieldValue('bank_account', null)
        let data = await getDefaultAccount(values['vendor']?.['id'], client)
        if (data['status'] === 100) {
            if (data['id']) {
                let defaultAccount = fetchList().find(i => i['id'] === data['id'])
                setFieldValue('bank_account', defaultAccount)
            }
        }
        else {
            alert('Error while fetching LoadVendorPrimaryAccount', data['message'])
        }
    }

    function fetchList() {
        let transactionType = values['transactionType']
        let tempList = [...banksAndCredit]
        if (transactionType === 2) {
            tempList = tempList.filter(i => i['accountTypeName']?.toLowerCase() === 'bank')
        }
        else if (transactionType === 3) {
            tempList = tempList.filter(i => i['accountTypeName']?.toLowerCase() === 'credit card')
        }
        return tempList
    }

    const LABEL = values['transactionType'] === 1 ? "Cards/Banks" : values['transactionType'] === 2 ? "Banks" : "Cards";

    return (
        <Autocomplete
            options={fetchList()}
            groupBy={(option) => option.accountTypeName}
            getOptionLabel={(option) => option.accountDescription}
            // getOptionLabel={(option) => option.accountName}
            fullWidth
            size="small"
            renderInput={(params) =>
                <TextField
                    label={LABEL}
                    required
                    error={Boolean(touched.bank_account && errors.bank_account)}
                    helperText={touched.bank_account && errors.bank_account}
                    {...params}
                />
            }
            onChange={(e, v) => setFieldValue('bank_account', v)}
            value={values.bank_account}
        />
    )
}

export default BanksAndCredit