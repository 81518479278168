import React, { useEffect, useRef, useState } from 'react'
import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography,
    Snackbar,
    Alert,
    Card,
    CardActionArea,
    CardContent,
    CardActions,
    CardHeader
} from '@mui/material';
import { fetchUserPropertiesList } from '../../../../API/API';
import { SuspenseLoading } from '../../../../Components/SuspenseLoading';
// import { encryptData } from '../../../../Tools/Crypto';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { updateClient, updateCpAccessID, updateProperty } from '../../../../Redux/actions'
import Upload from '../../../../Components/Upload/Upload';
import { Clear } from '@mui/icons-material';
import { sortByBillStats } from '../../../../Tools/sortByBillstats';

// import { fetchInvoiceStats, fetchPropertiesList } from '../../../../API/API';
const UserInvoiceProcessing = ({
    closeUploadDialog = () => { },
    openDialog = false,
    serviceDetails = {}
}) => {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const [cpList, setCpList] = useState({})

    const loadRef = useRef()

    useEffect(() => {
        getPropertiesList()
    }, [])

    async function getPropertiesList() {
        loadRef.current = true
        setCpList({})
        let tempPropertiesList = await fetchUserPropertiesList(serviceDetails['cp_Access_id'])
        if (tempPropertiesList.hasOwnProperty('properties')) {
            tempPropertiesList['properties'] = sortByBillStats(tempPropertiesList['properties'])
        }
        setCpList(tempPropertiesList)
        loadRef.current = false
    }

    function goToServiceRoute(path, propertyId) {
        navigation(`/invoice_processing/${path}`)
        dispatch(updateProperty(propertyId))
        dispatch(updateClient(cpList['client']))
        dispatch(updateCpAccessID(serviceDetails['cp_Access_id']))
    }

    const [uploadDialog, setUploadDialog] = useState({
        open: false,
        property: null,
        client: null,
    })

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: null
    })

    return (
        <>
            {cpList['properties'] ?
                <TableContainer sx={{ height: '100%' }}>
                    <Table size='small' stickyHeader>

                        <TableHead>
                            <TableRow
                                sx={{
                                    '& .MuiTableCell-root': {
                                        backgroundColor: '#F1F1FB !important',
                                        lineHeight: '2.2rem '
                                    }
                                }}
                            >
                                <TableCell sx={{ fontSize: '0.9375rem', width: '50%' }}>Property</TableCell>
                                <TableCell align='center' sx={{ fontSize: '0.98rem', width: '25%', whiteSpace: 'nowrap' }}>To Be Approved</TableCell>
                                <TableCell align='center' sx={{ fontSize: '0.98rem', width: '25%', whiteSpace: 'nowrap' }}>Need Attention</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {cpList['properties'].map((property, propertyIndex) => {
                                return (
                                    <TableRow key={propertyIndex}>

                                        <TableCell sx={{ width: '50%' }}>{property.corporationName}</TableCell>

                                        <TableCell align='center' sx={{ width: '25%' }}>
                                            <Chip size='small' label={property.to_be_posted} color='success'
                                                onClick={(e) => { e.stopPropagation(); sessionStorage.setItem('tabvalue', 1); goToServiceRoute('bills', property['corporationID']) }}
                                            />
                                        </TableCell>

                                        <TableCell align='center' sx={{ width: '25%' }}>
                                            <Chip size='small' label={property.need_attention} color='error'
                                                onClick={(e) => { e.stopPropagation(); goToServiceRoute('need_attention', property['corporationID']) }}
                                            />
                                        </TableCell>

                                    </TableRow>
                                )
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                :
                <SuspenseLoading loading={loadRef.current} />}

            <Dialog open={openDialog} fullWidth onClose={closeUploadDialog}>
                <AppBar position='static' color='inherit' sx={{ boxShadow: 'none' }}>
                    <Toolbar variant='dense'>
                        <Typography flexGrow={1} variant='h6' color={'#151D48'}>Upload</Typography>
                        <IconButton onClick={() => closeUploadDialog()} >
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />

                <DialogContent>
                    <Autocomplete
                        size='small'
                        options={cpList['properties'] || []}
                        getOptionLabel={option => option.corporationName}
                        onChange={(e, v) => setUploadDialog(prev => ({ ...prev, property: v?.['bookkeepingDirectory'] }))}
                        renderInput={params =>
                            <TextField
                                {...params}
                                label={'Properties'}
                            />
                        }
                    />
                    <Upload
                        client={client}
                        setAlert={setAlert}
                        callback={() => { closeUploadDialog(); getPropertiesList() }}
                        property={uploadDialog.property}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UserInvoiceProcessing