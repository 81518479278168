import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from "react-redux";
import currency from "currency.js";
import { SuspenseLoading } from '../../../../SuspenseLoading';
import { getBillsList } from '../../../../../API/NimbleAPI';
import { enqueueSnackbar } from 'notistack';

const BillPayGrid = ({ invoiceDetails }) => {

    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const { values, setFieldValue } = useFormikContext()

    const columns = [
        { field: "invoiceNumber", headerName: "Invoice Number", flex: 1 },
        { field: "date_only", headerName: "Date", flex: 1 },
        { field: "due_date_only", headerName: "Due Date", flex: 1 },
        { field: "amount", headerName: "Amount", flex: 1 }
    ]

    const [billsList, setBillList] = useState()

    useEffect(() => {
        setBillList(undefined)
        if (values['vendor']) {
            fetchBillList()
        }
        else {
            setBillList([])
            enqueueSnackbar('Please select the vendor to get a bill pay list', { variant: 'info' })
        }
    }, [values['vendor']])

    async function fetchBillList() {
        let today = new Date();
        let payload = {
            CorporationID: values['property']['corporationID'],
            Domain: client.url,
            ApprovalStatus: 2,
            Paging: 0,
            PaymentStatus: 1,
            ToDate: today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate(),
            VendorID: values['vendor'].id.replace("0x", ""),
        };

        let data = await getBillsList(payload, client)
        data.forEach(i => {
            let split_date = i['date']?.split('T')
            let split_due_date = i['dueDate']?.split('T')
            i['date_only'] = split_date[0]
            i['due_date_only'] = split_due_date[0]
        })
        setBillList(data);
    }

    return billsList ?
        <DataGrid
            autoHeight
            sx={{ minHeight: '100px' }}
            checkboxSelection
            getRowId={(row) => row.billID}
            rows={billsList}
            density='compact'
            columns={columns}
            hideFooter
            onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = billsList.filter((row) =>
                    selectedIDs.has(row.billID),
                );
                let invoice_amount;
                if (selectedRows.length === 0) {
                    invoice_amount = currency(invoiceDetails['invoice_amount']).value?.toString()
                }
                else {
                    invoice_amount = Number(selectedRows.reduce((acc, obj) => acc + Number(obj.amount), 0))?.toFixed(2).toString()
                }
                setFieldValue('invoice_amount', invoice_amount)
                setFieldValue('selectedBillsToPay', selectedRows)
            }}
        />
        :
        <SuspenseLoading loading />
}

export default BillPayGrid