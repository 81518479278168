import React from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './Routes/Routes'
import { ThemeProvider } from '@emotion/react'
import theme from './Theme/Theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App