import React, { useEffect, useRef, useState } from 'react'
import { getEmailBody } from '../../../API/API'

import {
    AppBar,
    Box,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Toolbar,
    Checkbox,
    FormControlLabel,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Paper,
    LinearProgress,
    Snackbar,
    Alert
} from '@mui/material';
import {
    Check,
    Clear,
    ContentCopy,
    Download,
    Drafts,
    Mail,
    MoreVert,
    Receipt,
    Rotate90DegreesCcw,
    Splitscreen,
    ZoomIn,
    ZoomOut,
} from "@mui/icons-material";

import { Interweave } from 'interweave';
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenReviewForm, updatePageProperties } from '../../../Redux/actions';

import {
    Icon, MinimalButton, Position, Tooltip,
} from '@react-pdf-viewer/core';

function areAllArraysEmpty(obj) {
    for (let key in obj) {
        if (obj[key].length > 0) {
            return false;
        }
    }
    return true;
}

function findMissingNumbers(arr1, arr2) {
    // Create a Set from arr2 for faster lookup
    const set2 = new Set(arr2);

    // Use filter to get elements present in arr1 but not in arr2
    const missingNumbers = arr1.filter(num => !set2.has(num));

    return missingNumbers;
}

export function PageTabs({ scrollToPageIndex, no_of_pages = [], CurrentPageLabel }) {
    const dispatch = useDispatch()
    const page_properties = useSelector(state => state.SNAP_STORE.page_properties) || {}
    const { checked_pages = [], blocked_pages = [], split_pages = [], invoice_pages = [] } = page_properties

    useEffect(() => {
        if (checked_pages.length === 0) {
            let all_pages = new Set([...split_pages, ...blocked_pages, ...invoice_pages])
            let tempNewCheckedPages = findMissingNumbers(no_of_pages, all_pages)
            if (tempNewCheckedPages.length === 1) {
                let tempPageProperties = { ...page_properties }
                tempPageProperties['checked_pages'] = tempNewCheckedPages
                dispatch(updatePageProperties(tempPageProperties))
            }
        }
    }, [no_of_pages])

    return no_of_pages.length > 0 ?
        <CurrentPageLabel>
            {(props) =>
                <Tabs
                    value={props['currentPage']}
                    variant="scrollable"
                    sx={{
                        height: '100%',
                        flexGrow: 1,
                        alignItems: 'center',
                        '& .MuiTabs-scrollButtons.Mui-disabled': { opacity: 0.3 },
                        '& .MuiTabs-scrollButtons': { height: '100%' },
                    }}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    {no_of_pages.map((page, index) =>
                        <Tab
                            disableRipple
                            key={index}
                            sx={{
                                minWidth: '32px',
                                padding: '12px 6px'
                            }}
                            icon={
                                <Chip
                                    label={page}
                                    color={
                                        (blocked_pages.includes(page) || split_pages.includes(page)) ? 'success' :
                                            invoice_pages.includes(page) ? 'nav' :
                                                checked_pages.includes(page) ? 'add' : 'chipColor2'
                                    }
                                    icon={
                                        blocked_pages.includes(page) ? <Check fontSize='small' /> :
                                            invoice_pages.includes(page) ? <Receipt fontSize='small' /> :
                                                split_pages.includes(page) ? <Splitscreen fontSize='small' /> : <></>
                                    }
                                    variant={
                                        (
                                            checked_pages.includes(page) ||
                                            split_pages.includes(page) ||
                                            invoice_pages.includes(page) ||
                                            blocked_pages.includes(page)
                                        ) ? 'filled' : 'outlined'
                                    }
                                    sx={{
                                        color:
                                            (
                                                checked_pages.includes(page) ||
                                                split_pages.includes(page) ||
                                                invoice_pages.includes(page) ||
                                                blocked_pages.includes(page)
                                            ) ? 'white' : 'inherit'
                                    }}
                                />
                            }
                            onClick={() => scrollToPageIndex(page - 1)}
                        />
                    )}
                </Tabs>
            }
        </CurrentPageLabel>
        :
        <Box flexGrow={1} />
}

export function CheckAllPages({ no_of_pages = [] }) {
    const dispatch = useDispatch()
    const page_properties = useSelector(state => state.SNAP_STORE.page_properties) || {}
    const { checked_pages = [], blocked_pages = [], split_pages = [], invoice_pages = [] } = page_properties

    function checkAllPages(e) {
        let isChecked = e.target.checked
        let newCheckedPages = []
        if (isChecked) {
            newCheckedPages = getCheckablePages()
        }
        let tempPageProperties = { ...page_properties }
        tempPageProperties['checked_pages'] = newCheckedPages
        dispatch(updatePageProperties(tempPageProperties))
        dispatch(updateOpenReviewForm(false))
    }

    function isAllPagesChecked() {
        let all_selected_pages = [...checked_pages, ...blocked_pages, ...split_pages, ...invoice_pages]
        return no_of_pages.every(i => all_selected_pages.includes(i))
    }

    function getCheckablePages() {
        let temp_pages = [...blocked_pages, ...split_pages, ...invoice_pages]
        return no_of_pages.filter(i => !temp_pages.includes(i))
    }

    const checkboxRef = useRef(null);

    const triggerCheckboxChange = (e) => {
        if (checkboxRef.current) {
            checkboxRef.current.click()
        }
    };

    return (
        <MinimalButton
            onClick={triggerCheckboxChange}
        >
            <input
                ref={checkboxRef}
                style={{
                    height: '15px',
                    width: '15px',
                    cursor: 'pointer',
                    backgroundColor: 'inherit',
                    color: 'inherit'
                }}
                type='checkbox'
                onChange={checkAllPages}
                checked={isAllPagesChecked()}
                onClick={triggerCheckboxChange}
            />
        </MinimalButton>

    )
}