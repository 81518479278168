import React, { useEffect, useState } from 'react'
import { get_track_changes, get_users_data } from '../../API/API'

import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subDays, addDays } from 'date-fns';

import {
    ArrowBack,
    ArrowForward,
    AttachMoney,
    CalendarMonth,
    ExpandLess,
    ExpandMore,
    Receipt,
    ShoppingCart,
    Warning,
    WatchLater
} from '@mui/icons-material';

import {
    Box,
    Stack,
    Typography,
    ListItem,
    ListItemText,
    List,
    ListSubheader,
    ListItemAvatar,
    Avatar,
    Chip,
    ListItemSecondaryAction,
    Collapse,
    ListItemButton,
    Button,
    IconButton
} from "@mui/material"
import moment from 'moment';
import { blue, deepPurple, green, indigo, orange, pink, purple } from '@mui/material/colors';

const Accuracy = () => {

    const [dateRange, setDateRange] = useState([startOfDay(new Date()), endOfDay(new Date())])
    const [accuracy, setAccuracy] = useState()
    const [usersData, setUsersData] = useState()

    useEffect(() => {
        fetchTrackChanges()
        getUsersData()
    }, [dateRange])

    const prev = () => {
        const prevDay = subDays(dateRange[0], 1);
        setDateRange([startOfDay(prevDay), endOfDay(prevDay)]);
    };

    const next = () => {
        const nextDay = addDays(dateRange[1], 1);
        setDateRange([startOfDay(nextDay), endOfDay(nextDay)]);
    };

    async function fetchTrackChanges() {
        setAccuracy()
        let start = moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
        let end = moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        let data = await get_track_changes(start, end)
        setAccuracy(data)
    }

    async function getUsersData() {
        setUsersData()
        let start = moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
        let end = moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        let data = await get_users_data(start, end)
        setUsersData(data)
    }

    const [openCollapse, setOpenCollapse] = useState(null);

    const handleCollapseToggle = (index) => {
        setOpenCollapse(openCollapse === index ? null : index);
    };

    return (
        <>
            <Chip sx={{ ml: 20 }} icon={<Warning />} color='error' label='Full UI will be available soon.' />
            <Stack pl={18} mt={2} mb={2} width={'100%'} alignItems={'center'} direction={'row'} gap={1}>
                <IconButton onClick={prev}>
                    <ArrowBack />
                </IconButton>
                <DateRangePicker
                    placeholder='Date range'
                    value={dateRange}
                    onChange={(v) => setDateRange(v)}
                    style={{ minWidth: '250px' }}
                />
                <IconButton onClick={next}>
                    <ArrowForward />
                </IconButton>

            </Stack>


            <Stack pl={20} pr={20} direction={'row'} width={'100%'} height={'calc(100% - 128px)'} gap={3} overflow={'auto'}>
                {accuracy ?
                    <List sx={{ bgcolor: 'background.paper', width: '40%', overflow: 'auto' }}
                        subheader={
                            <ListSubheader>
                                <ListItemSecondaryAction>
                                    {`${parseFloat(accuracy['total_accuracy'].toFixed(2))} %`}
                                </ListItemSecondaryAction>
                                Total bills {`(${accuracy['count']})`}
                            </ListSubheader>
                        }
                    >
                        {Object.entries(accuracy['accuracy']).map(([key, value], index) =>
                            <ListItem
                                divider={Object.entries(accuracy['accuracy']).length !== index + 1}
                                key={index}
                                secondaryAction={`${parseFloat(value.toFixed(2))} %`}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: green[500] }}>
                                        {['vendor_name', 'vendor_address'].includes(key) && <ShoppingCart />}
                                        {key === "due_date" && <WatchLater />}
                                        {key === "invoice_date" && <CalendarMonth />}
                                        {key === "invoice_amount" && <AttachMoney />}
                                        {key === "invoice_number" && <Receipt />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }} primary={key.replace('_', " ")} />
                            </ListItem>
                        )}
                    </List>
                    :
                    <>Loading...</>
                }
                {usersData ?
                    <List
                        sx={{ bgcolor: 'background.paper', width: '60%', overflow: 'auto' }}
                        subheader={
                            <ListSubheader>
                                <ListItemSecondaryAction>
                                    {usersData.reduce((accumulator, currentValue) => {
                                        return accumulator + currentValue.total_bills;
                                    }, 0)}
                                </ListItemSecondaryAction>
                                Total bills
                            </ListSubheader>
                        }
                    >
                        {usersData.map((item, index) =>
                            <>
                                <ListItemButton
                                    divider={usersData.length !== index + 1}
                                    key={index}
                                    onClick={() => handleCollapseToggle(index)}
                                >
                                    <ListItemSecondaryAction>
                                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                                            {item['total_bills']}
                                            {openCollapse === index ? <ExpandLess /> : <ExpandMore />}
                                        </Stack>

                                    </ListItemSecondaryAction>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: orange[400] }}>{item['_id']?.charAt(0)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }}
                                        primary={item['_id']?.split('@')?.[0]}
                                    />
                                </ListItemButton>

                                <Collapse in={openCollapse === index} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item['bills_per_client'].map((client, clientIndex) =>
                                            <ListItem
                                                divider={item['bills_per_client'].length !== index + 1}
                                                key={clientIndex}
                                                secondaryAction={client['total_bills']}
                                                sx={{ pl: 4 }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar sx={{ bgcolor: indigo[800] }}>
                                                        {client['client']?.charAt(0)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }}
                                                    primary={client['client']}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                    </List>
                    :
                    <>Loading...</>
                }
            </Stack>
        </>
    )
}

export default Accuracy