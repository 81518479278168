import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isBookDateEnabled } from '../../../../API/NimbleAPI'
import { IconButton, TextField } from "@mui/material";
import InputMask from 'react-input-mask'
import { convert_PstD_InvNum } from "./ReviewForm";
import { CalendarMonth } from "@mui/icons-material";

export default function BooksDate() {
    const { values, setFieldValue, errors, touched, handleChange } = useFormikContext()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    useEffect(() => {
        booksDateState()
    }, []);

    async function booksDateState() {
        let res = await isBookDateEnabled(client)
        setFieldValue('isBooksDateEnabled', res["status"]);
    }

    return values['isBooksDateEnabled'] ?
        <InputMask
            mask="99/99/9999"
            value={values['books_date']}
            onChange={handleChange}
            alwaysShowMask
            maskPlaceholder='MM/DD/YYYY'
        >
            {() =>
                <TextField
                    label='Books Date'
                    error={Boolean(touched.books_date && errors.books_date)}
                    helperText={touched.books_date && errors.books_date}
                    required
                    size='small'
                    name="books_date"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => convert_PstD_InvNum('books_date', setFieldValue)}>
                                <CalendarMonth />
                            </IconButton>
                        )
                    }}
                />
            }
        </InputMask>
        :
        <></>
}