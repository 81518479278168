import {
    CP_ACCESS_ID,
    MICRO_SERVICES,
    SELECTED_CLIENT,
    SELECTED_PROPERTY,
} from "./constants"

const initialSnapStoreState = {
    client: null,
    property: null,
    microServiceData: {}
}

export const SNAP_PERSIST_STORE = (state = initialSnapStoreState, action) => {
    const { type, payload } = action

    switch (type) {

        case CP_ACCESS_ID:
            return { ...state, ...{ cp_access_id: payload } };

        case SELECTED_CLIENT:
            return { ...state, ...{ client: payload } };

        case SELECTED_PROPERTY:
            return { ...state, ...{ property: payload } };

        case MICRO_SERVICES:
            return { ...state, ...{ microServiceData: payload } };

        default:
            return state
    }
}