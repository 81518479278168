import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    DialogContentText
} from "@mui/material"
import { confirmable, createConfirmation } from "react-confirm";
import { Transition } from "./Transition";

const Confirmation = ({
    okLabel = "OK",
    cancelLabel = "Cancel",
    title = "Confirmation",
    confirmation,
    show,
    proceed,
    enableEscape = true
}) => {
    return (
        <Dialog
            TransitionComponent={Transition}
            open={show}
            onClose={() => proceed(false)}
        // backdrop={enableEscape ? true : "static"}
        // keyboard={enableEscape}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {confirmation}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button color="error" onClick={() => proceed(false)}>{cancelLabel}</Button>
                <Button onClick={() => proceed(true)}>{okLabel}</Button>
            </DialogActions>

        </Dialog>
    );
};

Confirmation.propTypes = {
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    confirmation: PropTypes.string,
    show: PropTypes.bool,
    proceed: PropTypes.func, // called when ok button is clicked.
    enableEscape: PropTypes.bool
};

export function confirm(
    confirmation,
    proceedLabel = "OK",
    cancelLabel = "cancel",
    options = {}
) {
    return createConfirmation(confirmable(Confirmation))({
        confirmation,
        proceedLabel,
        cancelLabel,
        ...options
    });
}
