import {
    CP_ACCESS_ID,
    PROPERTIES_LIST,
    SELECTED_BILL_INDEX,
    SELECTED_BILL_INVOICE_DETAILS,
    SELECTED_CLIENT,
    SELECTED_PROPERTY,
    UPDATE_ACCOUNTS_LIST,
    BILLS,
    CHECKED_PAGES,
    OPEN_REVIEW_FORM,
    BLOCKED_PAGES,
    MICRO_SERVICES,
    CLIENTS_LIST,
    NO_OF_PAGES,
    BANKS_AND_CREDIT,
    FINAL_FORM,
    CURRENT_PAGE_INDEX,
    CURRENT_PAGE_NUMBER,
    PAGE_PROPERTIES
} from "./constants"

export const updateClient = (data) => {
    return {
        type: SELECTED_CLIENT,
        payload: data
    }
}

export const updateProperty = (data) => {
    return {
        type: SELECTED_PROPERTY,
        payload: data
    }
}

export const UpdateAccountsList = (acc) => {
    return {
        type: UPDATE_ACCOUNTS_LIST,
        payload: acc
    }
}

export const updateClientsList = (data) => {
    return {
        type: CLIENTS_LIST,
        payload: data
    }
}

export const updatePropertiesList = (data) => {
    return {
        type: PROPERTIES_LIST,
        payload: data
    }
}

export const updateCpAccessID = (data) => {
    return {
        type: CP_ACCESS_ID,
        payload: data
    }
}

// ------------------- Dev
export const updatePageProperties = (data) => {
    return {
        type: PAGE_PROPERTIES,
        payload: data
    }
}

export const updateCurrentPageNumber = (data) => {
    return {
        type: CURRENT_PAGE_NUMBER,
        payload: data
    }
}

export const updateSelectedBillIndex = (data) => {
    return {
        type: SELECTED_BILL_INDEX,
        payload: data
    }
}

export const updateBillInvoiceDetails = (data) => {
    return {
        type: SELECTED_BILL_INVOICE_DETAILS,
        payload: data
    }
}

export const updateCheckedPages = (data) => {
    return {
        type: CHECKED_PAGES,
        payload: data
    }
}

export const updateOpenReviewForm = (data) => {
    return {
        type: OPEN_REVIEW_FORM,
        payload: data
    }
}

export const updateBlockedPages = (data) => {
    return {
        type: BLOCKED_PAGES,
        payload: data
    }
}

// --------------------------

export const updateMicroServices = (data) => {
    return {
        type: MICRO_SERVICES,
        payload: data
    }
}

export const updateNumberPages = (data) => {
    return {
        type: NO_OF_PAGES,
        payload: data
    }
}

export const updateBanksAndCredit = (data) => {
    return {
        type: BANKS_AND_CREDIT,
        payload: data
    }
}

export const updateFinalForm = (data) => {
    return {
        type: FINAL_FORM,
        payload: data
    }
}

