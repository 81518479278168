import { useState, useEffect } from "react";
import {
    Menu,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    Toolbar,
    TextField,
    Box,
    AppBar,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import {
    PreviewOutlined,
    AssignmentTurnedInOutlined,
    EditCalendarOutlined,
    SplitscreenOutlined,
    MoreVert,
    Clear,
    Preview,
    RemoveRedEye,
    Info
} from "@mui/icons-material";
import moment from "moment";
import CustomTable from "../../../Components/CustomTable/CustomTable";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

import CardContent from "@mui/material/CardContent";
import { Grid, Dialog, DialogContent, Card, Typography, Button, IconButton } from "@mui/material";

import { Transition } from "../../../Components/Transition"
import { requestSearch } from "../../../Tools/searchTool";
import { DateRangePicker } from "rsuite";
import { useSelector } from "react-redux";

import { CSVLink, CSVDownload } from "react-csv";
import { Stack } from "@mui/system";
import BillSummaryTable from "./BillSummaryTable";


export default function BillSummary({
    data = [],
    dateRange,
    loading,
    setDateRange,
    fetchPostedBills
}) {

    const [tableData, setTableData] = useState([])
    const [searchQuery, setSearchQuery] = useState('')

    const flexgrow = 1

    const tableColumns = [
        {

            'key': 'client',
            'label': 'Client',
            flexgrow: flexgrow,
        },
        {
            'key': 'corporationName',
            'label': 'Property',
            flexgrow: flexgrow,
        },
        {
            key: 'vendor',
            label: 'Vendor',
            flexgrow: flexgrow,
        },
        {
            key: 'Transac_type',
            label: 'Transaction Type',
            flexgrow: 0.7,
        },
        {
            key: 'InvoiceNumber',
            label: 'Invoice Number',
            flexgrow: flexgrow,
        },
        {
            key: 'BillDate',
            label: 'Invoice Date',
            flexgrow: flexgrow,
        },
        {
            key: 'Amount',
            label: 'Amount',
            flexgrow: 0.7,
        },
        {
            key: 'received_time',
            label: 'Received Time',
            flexgrow: flexgrow,
        },
        {
            key: 'createdTime',
            label: 'Posted Time',
            flexgrow: flexgrow,
        },
        {
            key: 'TurnAroundTime',
            label: 'Turn around time',
            flexgrow: flexgrow,
        },
        {
            key: 'createdBy',
            label: 'Created by',
            flexgrow: flexgrow,
        },
    ]

    useEffect(() => {
        if (Array.isArray(data)) {
            data.map(i => i.payload._id = i['_id'])
        }
        let payload = Array.isArray(data) ? data.map(i => i.payload) : []
        setTableData(payload)
    }, [data])

    const [renderElements, setRenderElements] = useState({
        selectedBill: undefined,
        previewDialog: false
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [infoDetails, setInfoDetails] = useState([
        {
            head: "Create Time",
            subHead: " 11 :00 AM ",
        },
        {
            head: "Created By",
            subHead: " Charu ",
        },
        {
            head: "Assign To",
            subHead: " Ajay Babu ",
        },
        {
            head: "Turn Around Time",
            subHead: "10 hrs ",
        },
    ])// info popup static data

    const [popOpen, setPopOpen] = useState(false)

    const openClick = () => {
        let temp = [
            {
                head: "Create Date",
                subHead: renderElements?.selectedBill?.payload?.createdTime.split(' ')[0],
            },
            {
                head: "Create Time",
                subHead: renderElements?.selectedBill?.payload?.createdTime.split(' ')[1].split('.')[0],
            },
            {
                head: "Created By",
                subHead: renderElements?.selectedBill?.payload?.createdBy
                ,
            },
            {
                head: "Assign To",
                subHead: " Ajay Babu ",
            },
            {
                head: "Turn Around Time",
                subHead: renderElements?.selectedBill?.payload.TurnAroundTime?.split('.')[0],
            }]
        setInfoDetails(temp)
        setPopOpen(true)
        handleClose()
    }

    const closeClick = () => {
        setPopOpen(false)
    }

    const previewOpenClick = () => {
        setRenderElements((prev) => ({
            ...prev,
            previewDialog: true,
        }));
        handleClose()
    }; // for preview

    const previewCloseClick = () => {
        setRenderElements((prev) => ({
            ...prev,
            previewDialog: false,
        }));
    }; // for preview

    const downloadFile = async () => {
        const pdfUrl = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${renderElements?.selectedBill?.payload?.Path1}`

        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = pdfUrl.split("/").pop();

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            handleClose()
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    // downloadFile();
    const menuItems = [
        {
            name: 'Approve',
            icon: <AssignmentTurnedInOutlined />,
            // onclick: () => handleOpenReviewPanel()
        },
        {
            name: 'Preview',
            icon: <PreviewOutlined />,
            onclick: () => previewOpenClick()
        },
        // {
        //     name: 'Download',
        //     icon: <EditCalendarOutlined />,
        //     onclick: () => downloadFile()
        // },
        {
            name: 'Info',
            icon: <SplitscreenOutlined />,
            onclick: () => openClick()
        }
    ]

    const handleClick = (index) => {
        let selectedBill = data[index]
        setRenderElements(prev => ({
            ...prev,
            selectedBill: selectedBill,
        }))
    };

    function searchFuntion(e) {
        let keysToSearch = tableColumns.map(i => i.key)
        let payload = data.map(i => i.payload)
        let result = requestSearch(e, payload, keysToSearch)
        setSearchQuery(result.query)
        setTableData(result.filterData)
    }

    function fetchExcelData() {

        let tempData = data.map(i => i.payload)
        tempData.sort((a, b) => {
            const timeA = new Date(a.createdTime).getTime(); //Sort by post time
            const timeB = new Date(b.createdTime).getTime();
            return timeA - timeB;
        });
        let newData = []
        tempData.forEach((item, index) => {
            let { Transac_type, vendor, property, createdBy, client, createdTime, InvoiceNumber, BillDate, Amount, received_time, TurnAroundTime } = item
            let obj = {
                "S.NO": index + 1,
                client,
                property,
                Transac_type,
                vendor,
                InvoiceNumber,
                BillDate,
                Amount,
                createdBy,
                createdTime,
                received_time,
                TurnAroundTime
            }
            newData.push(obj)
        })

        return newData
    }

    function Download() {
        return (
            <CSVLink
                filename={`NimbleIO Posted Bills ${moment(new Date()).format('MM/DD/YYYY hh:mm')}`}
                data={fetchExcelData()}
            />
        )
    }

    const [allClients, setAllClients] = useState(false)
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)

    function fetchAllPostedBills(e) {
        let isChecked = e.target.checked
        if (isChecked) {
            fetchPostedBills({ url: "all" })
        }
        else {
            fetchPostedBills()
        }
        setAllClients(isChecked)
    }

    useEffect(() => {
        if (allClients) {
            setAllClients(false)
        }
    }, [selectedClient, selectedProperty])

    return (

        <>
            <Box height={'100%'} width={'100%'}>
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>


                    <DateRangePicker
                        placeholder='Date range'
                        value={dateRange}
                        onChange={(v) => setDateRange(v)}
                        style={{ minWidth: '250px' }}
                    />
                    <Stack direction={'row'} gap={2}>
                        <FormControlLabel disabled={loading} labelPlacement='start' control={<Checkbox checked={allClients} onChange={fetchAllPostedBills} />} label="All clients" />
                        <TextField
                            size='small'
                            placeholder='Search'
                            value={searchQuery}
                            onChange={(e) => searchFuntion(e)}
                            disabled={data.length === 0}
                        />

                        {data.length === 0 ?
                            <Button disabled={data.length === 0} variant="contained">
                                Export
                            </Button>
                            :
                            <CSVLink
                                disabled={data.length === 0}
                                filename={`NimbleIO Posted Bills ${moment(new Date()).format('MM/DD/YYYY hh:mm')}`}
                                data={fetchExcelData()}
                                children={
                                    <Button disabled={data.length === 0} variant="contained">
                                        Export
                                    </Button>
                                }
                            />
                        }

                    </Stack>

                </Toolbar>

                <Box height={'calc(100% - 64px)'} width={'100%'}>
                    {/* <CustomTable
                        data={tableData}
                        columns={tableColumns}
                        action
                        ActionComponent={(rowData) => {
                            let index = data.findIndex(i => i['_id'] === rowData['_id'])
                            return (
                                // <IconButton onClick={(e) => { setAnchorEl(e.currentTarget); handleClick(index) }}>
                                //     <MoreVert />
                                // </IconButton>

                                <IconButton onClick={(e) => { previewOpenClick(); handleClick(index) }}>
                                    <RemoveRedEye />
                                </IconButton>
                            )
                        }}
                        searchQuery={searchQuery}
                        loader={loading}
                        isProperty
                        resizable
                    /> */}
                    <BillSummaryTable
                        data={tableData}
                        loader={loading}
                        searchQuery={searchQuery}
                        isProperty
                        ActionComponent={(rowData) => {
                            let index = data.findIndex(i => i['_id'] === rowData['_id'])
                            return (

                                <IconButton onClick={(e) => { previewOpenClick(); handleClick(index) }}>
                                    <RemoveRedEye />
                                </IconButton>
                            )
                        }}
                    />
                </Box>
            </Box>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
            >
                <MenuList dense disablePadding>
                    {menuItems.map((menu, menuIndex) =>
                        <MenuItem key={menuIndex} onClick={menu.onclick}>
                            <ListItemIcon>{menu.icon}</ListItemIcon>
                            <ListItemText>{menu.name} </ListItemText>
                        </MenuItem>
                    )}
                </MenuList>
            </Menu>

            <Dialog open={popOpen} onClose={closeClick}>
                <DialogTitle sx={{ m: 0, p: 2 }}>Information</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={closeClick}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent style={{ width: "500px" }} dividers>
                    <Grid container spacing={5}>
                        {infoDetails.map((i, index) => {

                            return (

                                <Grid item key={index} xs={6}>
                                    <Card
                                        elevation={2}
                                        style={{ width: "200px", height: "100px" }}
                                    >
                                        <CardContent>
                                            {i.head}
                                            <Typography variant="h5">{i.subHead}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ borderRadius: 10, backgroundColor: "#fe5461" }}
                        size="large"
                        onClick={closeClick}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth={"md"}
                open={renderElements.previewDialog}
                TransitionComponent={Transition}
                onClose={previewCloseClick}
            >
                <AppBar position="static" color="nav">
                    <Toolbar disableGutters variant="dense" sx={{ pl: 1, pr: 1, gap: 0.5 }}>
                        <Typography variant="body2" flexGrow={1}>
                            {renderElements?.selectedBill?.payload?.Path1?.match(/[^/]+$/)[0]}
                        </Typography>
                        {/* <IconButton color="inherit">
                            <Info />
                        </IconButton> */}
                        <IconButton color="inherit" onClick={previewCloseClick}>
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent sx={{ height: "100vh", p: 1 }}>
                    <iframe
                        src={`https://nimbleocrbills.s3.us-east-1.amazonaws.com/${decodeURIComponent(renderElements?.selectedBill?.payload?.Path1)}`}
                        style={{ width: "100%", height: "calc(100% - 8px)" }}
                    >
                    </iframe>
                </DialogContent>
            </Dialog>

        </>
    )
}