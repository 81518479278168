export const SELECTED_CLIENT = "SELECTED_CLIENT"
export const SELECTED_PROPERTY = "SELECTED_PROPERTY"
export const SELECTED_BILL_INDEX = "SELECTED_BILL_INDEX"
export const SELECTED_BILL_INVOICE_DETAILS = "SELECTED_BILL_INVOICE_DETAILS"
export const UPDATE_ACCOUNTS_LIST = "UPDATE_ACCOUNTS_LIST"
export const CLIENTS_LIST = "CLIENTS_LIST"
export const PROPERTIES_LIST = "PROPERTIES_LIST"
export const CP_ACCESS_ID = "CP_ACCESS_ID"
export const BILLS = "BILLS" //We are using this for both need_attention and to_bo_posted
export const CHECKED_PAGES = "CHECKED_PAGES"
export const OPEN_REVIEW_FORM = "OPEN_REVIEW_FORM"
export const BLOCKED_PAGES = "BLOCKED_PAGES"
export const MICRO_SERVICES = "MICRO_SERVICES"
export const NO_OF_PAGES = "NO_OF_PAGES"
export const BANKS_AND_CREDIT = "BANKS_AND_CREDIT"
export const FINAL_FORM = "FINAL_FORM"
export const CURRENT_PAGE_NUMBER = "CURRENT_PAGE_NUMBER"
export const PAGE_PROPERTIES = "PAGE_PROPERTIES"