import { memo, useEffect, useMemo, useState } from "react";
import logo from "../images/sidebar_menu.png";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import './Layout.css'
import { NavLink, useParams, } from "react-router-dom";
import "./Layout.css";
import { fetchRoutes } from "../Tools/fetchRoutes";
import { useDispatch, useSelector } from 'react-redux';

export default function NavBar({ routes }) {

  return (
    <Box component={"aside"} className="sideber-wrapper">
      {/* <Toolbar disableGutters sx={{ gap: 2, p: 1.5 }}>

        <Box component={"img"} src={logo} width={"55px"} />

        <Typography variant="h5" color={"#030192"}>NIMBLEIO.AI</Typography>
      </Toolbar> */}


      <List>
        <ListItem sx={{ mb: 2, mt: 1 }}>
          <ListItemIcon>
            <Box component={"img"} width={'60px'} src={logo} />
          </ListItemIcon>
          <ListItemText primary={
            <Typography ml={1} variant="h5" color={"#030192"}>NIMBLEIO.AI</Typography>
          }
          />
        </ListItem>
        {fetchRoutes({ tempServices: routes }).map(route => (
          <NavLink
            key={route.service_id}
            to={route.route}
            onClick={() => sessionStorage.setItem('tabvalue', 0)}
            style={{ fontFamily: 'inherit', textDecoration: 'none' }}
          >
            {({ isActive }) => (
              <ListItemButton
                disableRipple
                selected={isActive}
                sx={{
                  color: isActive ? '#030192' : '#737791',
                  height: '62px',
                  '&.Mui-selected': { backgroundColor: '#F1F1FB' },
                  pl: 3.5,
                  pr: 3.5
                }}
              >
                <ListItemIcon sx={{ color: isActive ? '#030192' : '#737791' }}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={
                  <Typography noWrap textOverflow="unset" fontFamily="inherit">
                    {route.name}
                  </Typography>
                } />
              </ListItemButton>
            )}
          </NavLink>
        ))}
      </List>
    </Box >
  );
}
