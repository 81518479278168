import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAccountsList, updateBanksAndCredit } from '../../../../../Redux/actions';
import { FieldArray, useFormikContext } from 'formik';
import { Autocomplete, Box, Card, Collapse, Dialog, DialogContent, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { TransitionGroup } from "react-transition-group";
import { AddCircle, Clear, ConstructionRounded, FunctionsOutlined, MenuSharp, RemoveCircle } from '@mui/icons-material';
import currency from 'currency.js';
import { SuspenseLoading } from '../../../../SuspenseLoading';
import { ListboxComponent } from '../../../../ListBoxWithVirtualization';
import { getAccountTypeList, getAccountsList, getProfitCenter } from '../../../../../API/NimbleAPI';

import axios from "axios";

const COA = () => {
    const dispatch = useDispatch()
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const account_list = useSelector(state => state.SNAP_STORE.accountslist) || []

    const { values, errors, touched, setFieldValue } = useFormikContext()
    const [COADialog, setCOADialog] = useState({
        open: false,
        index: undefined,
    });

    const [profitCenterList, setProfitCenterList] = useState([])

    useEffect(() => {
        if (values['property']) {
            fetchProfitCenter()
        }
    }, [values['property']])

    useEffect(() => {
        let vendor = values['vendor']
        const getVendorPurpose = async () => {
            let vendorId = vendor['id']
            let res = await axios.get("https://nimbleio.ai/kb/vendorpurpose", { params: { vendorId } })
            const vendorPurpose = res.data
            if (vendorPurpose) {
                const isDefault = vendorPurpose.find(item => item.isDefault === true);
                setFieldValue('vendorDefaultPurpose', isDefault)
            }
        }
        if (vendor) { getVendorPurpose() }
    }, [values['vendor']])

    useEffect(() => {
        let finalList = []

        if (values['vendor']) {
            let contractDefaultSplitIDs = values['contract']?.defaultSplitIDs;
            let vendorDefaultSplitIDs = values['vendor'].defaultSplitIDs;
            let tempList = [];

            if (contractDefaultSplitIDs && contractDefaultSplitIDs !== "") {
                tempList = contractDefaultSplitIDs.split(",");
            }
            else if (vendorDefaultSplitIDs && vendorDefaultSplitIDs !== "") {
                tempList = vendorDefaultSplitIDs.split(",");
            }
            if (tempList.length > 0) {
                account_list?.forEach((i) => {
                    tempList.forEach((j) => {
                        if (i.id?.trim()?.toLowerCase() === j?.trim()?.toLowerCase()) {
                            finalList.push({
                                AccountCode: i["accountNumber"],
                                name: i["accountName"],
                                Description: "",
                                Amount: "0",
                                storeID: "",
                            });
                        }
                    });
                });
            }

            if (finalList.length === 0) {
                finalList.push({
                    AccountCode: "",
                    name: "",
                    Description: "",
                    Amount: "",
                    storeID: "",
                })
            }
            finalList[0]['Amount'] = currency(values['invoice_amount']).value.toString();
        }
        setFieldValue('splitDetails', finalList)
    }, [values['vendor'], values['contract']]);

    useEffect(() => {
        if (values['property']) {
            fetchFinancialData()
        }
    }, [values['property']])

    async function fetchProfitCenter() {
        let res = await getProfitCenter(values['property']?.['corporationID'], client)
        if (Array.isArray(res)) {
            setProfitCenterList(res)
        }
    }

    async function fetchFinancialData() {
        let corporationID = values['property']['corporationID']

        let tempAccounts = await getAccountsList(corporationID, client)
        let tempAccountTypes = await getAccountTypeList(corporationID, client)

        tempAccounts = tempAccounts['account']
        tempAccountTypes = tempAccountTypes['account']

        // *Filter the account type (only fetch Bank and Credit Card id's)
        let filterBankId = tempAccountTypes.filter((obj) =>
            obj.accountTypeName === "Bank" ||
            obj.accountTypeName === "Credit Card"
        ).map((obj) => obj.id);

        //*Filter the accounts based on filterBankId
        let finalList = tempAccounts.filter((i) => filterBankId.includes(i.accountType));

        dispatch(updateBanksAndCredit(finalList))
        dispatch(UpdateAccountsList(tempAccounts))
    }

    function handleOpenCOADialog(index) {
        setCOADialog({ open: true, index: index });
    }
    function handleCloseCOADialog() {
        setCOADialog({ open: false, index: undefined });
    }

    function addToSplitDetails(index, key, value) {
        const updatedSplitDetails = [...values['splitDetails']];
        updatedSplitDetails[index][key] = value
        setFieldValue('splitDetails', updatedSplitDetails);
    }

    return (
        <>
            <FieldArray name="splitDetails">
                {({ insert, remove, push }) => (
                    <>
                        {values['splitDetails'].length > 0 &&
                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <MenuSharp />
                                <Typography>Split Details</Typography>
                                <IconButton color="success" onClick={() => push(
                                    {
                                        AccountCode: "",
                                        name: "",
                                        Description: "",
                                        Amount: "0",
                                        storeID: "",
                                    }
                                )}>
                                    <AddCircle />
                                </IconButton>
                            </Stack>
                        }

                        <TransitionGroup>
                            {values['splitDetails'].map((item, index) => {
                                let error = errors['splitDetails']?.[index]
                                return (
                                    <Collapse key={index}>
                                        {
                                            // <Stack component={Card} boxShadow={'0px 5px 15px -3px rgba(3, 1, 146, 0.15)'} direction={'row'} mb={1} p={1}>
                                            //     <Stack alignItems={'center'} justifyContent='flex-start'>
                                            //         <Typography variant='h6'>
                                            //             {index + 1}
                                            //         </Typography>
                                            //         <IconButton
                                            //             color="error"
                                            //             onClick={() => remove(index)}
                                            //             disabled={values['splitDetails'].length <= 1}
                                            //         >
                                            //             <RemoveCircle />
                                            //         </IconButton>

                                            //     </Stack>
                                            //     <Stack gap={1} flexGrow={1}>
                                            //         <Stack direction={'row'} gap={1}>
                                            //             <TextField
                                            //                 fullWidth
                                            //                 size='small'
                                            //                 label='Purpose'
                                            //                 value={item['purpose']}
                                            //                 InputProps={{ readOnly: true }}
                                            //             />

                                            //             <TextField
                                            //                 fullWidth
                                            //                 size='small'
                                            //                 label='Desc'
                                            //                 value={item['Description']}
                                            //                 onChange={(e) => addToSplitDetails(index, "Description", e.target.value)}
                                            //             />

                                            //             <TextField
                                            //                 sx={{ width: '50%' }}
                                            //                 size='small'
                                            //                 label='Amount'
                                            //                 value={item['Amount']}
                                            //                 onClick={(e) => { if (Number(e.target.value) === 0) addToSplitDetails(index, 'Amount', "") }}
                                            //                 // onBlur={(e) => { if (item['Amount'] === "" && !item['AccountCode']) addToSplitDetails(index, 'Amount', "0") }}
                                            //                 onChange={(e) => addToSplitDetails(index, 'Amount', e.target.value)}
                                            //             />

                                            //         </Stack>
                                            //         <Stack direction={'row'} gap={1}>
                                            //             <TextField
                                            //                 fullWidth
                                            //                 size="small"
                                            //                 placeholder="Account code"
                                            //                 value={item['AccountCode'] || ""}
                                            //                 error={Boolean(error?.['AccountCode'])}
                                            //                 InputProps={{ readOnly: true }}
                                            //                 onClick={() => handleOpenCOADialog(index)}
                                            //             />

                                            //             <TextField
                                            //                 fullWidth
                                            //                 size="small"
                                            //                 placeholder="Account Name"
                                            //                 value={item['name'] || ""}
                                            //                 error={Boolean(error?.['name'])}
                                            //                 InputProps={{ readOnly: true }}
                                            //                 onClick={() => handleOpenCOADialog(index)}
                                            //             />

                                            //             <FormControl fullWidth size="small" disabled={profitCenterList.length === 0}>
                                            //                 <InputLabel>PC</InputLabel>
                                            //                 <Select
                                            //                     label="PC"
                                            //                     onChange={(e) => addToSplitDetails(index, "storeID", e.target.value)}
                                            //                     value={item["storeID"] || ""}
                                            //                 >
                                            //                     {/* <MenuItem value={" "}>Select</MenuItem> */}
                                            //                     {profitCenterList.map((item) => (
                                            //                         <MenuItem value={item.id} key={item.id}>
                                            //                             {item.name}
                                            //                         </MenuItem>
                                            //                     ))}
                                            //                 </Select>
                                            //             </FormControl>
                                            //         </Stack>
                                            //     </Stack>

                                            // </Stack>
                                        }
                                        {
                                            <Stack direction={'row'} mb={1} gap={1} border={1} borderColor={'divider'} borderRadius={1} p={1}>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => remove(index)}
                                                    disabled={values['splitDetails'].length <= 1}
                                                >
                                                    <RemoveCircle />
                                                </IconButton>

                                                {/* <TextField
                                                    size='small'
                                                    sx={{ width: '50%' }}
                                                    label='Purpose'
                                                    value={item['purpose']}
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                /> */}

                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Account code"
                                                    value={item['AccountCode'] || ""}
                                                    error={Boolean(error?.['AccountCode'])}
                                                    InputProps={{ readOnly: true }}
                                                    onClick={() => handleOpenCOADialog(index)}
                                                />

                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Account Name"
                                                    value={item['name'] || ""}
                                                    error={Boolean(error?.['AccountCode'])}
                                                    InputProps={{ readOnly: true }}
                                                    onClick={() => handleOpenCOADialog(index)}
                                                />

                                                <FormControl size="small" fullWidth disabled={profitCenterList.length === 0}>
                                                    <InputLabel>PC</InputLabel>
                                                    <Select
                                                        label="PC"
                                                        onChange={(e) => addToSplitDetails(index, "storeID", e.target.value)}
                                                        value={item["storeID"] || ""}
                                                    >
                                                        {/* <MenuItem value={" "}>Select</MenuItem> */}
                                                        {profitCenterList.map((item) => (
                                                            <MenuItem value={item.id} key={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>

                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    label='Desc'
                                                    value={item['Description']}
                                                    onChange={(e) => addToSplitDetails(index, "Description", e.target.value)}
                                                />

                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    label='Amount'
                                                    value={item['Amount']}
                                                    onClick={(e) => { if (Number(e.target.value) === 0) addToSplitDetails(index, 'Amount', "") }}
                                                    // onBlur={(e) => { if (item['Amount'] === "" && !item['AccountCode']) addToSplitDetails(index, 'Amount', "0") }}
                                                    onChange={(e) => addToSplitDetails(index, 'Amount', e.target.value)}
                                                    error={Boolean(error?.['Amount'])}
                                                />

                                            </Stack>
                                        }
                                    </Collapse>
                                )
                            })}
                        </TransitionGroup>
                    </>
                )}
            </FieldArray>

            <Dialog disableRestoreFocus open={COADialog.open} fullWidth maxWidth={'sm'} onClose={handleCloseCOADialog}>
                <Toolbar variant="dense">
                    <Typography variant="h5" flexGrow={1}>
                        Accounts
                    </Typography>
                    <IconButton onClick={handleCloseCOADialog}>
                        <Clear />
                    </IconButton>
                </Toolbar>
                <Divider />
                <DialogContent sx={{ height: "55vh", p: 0, pt: 2, pr: 1, pl: 1 }}>
                    {account_list?.length > 0 ?
                        < Autocomplete
                            size="small"
                            openOnFocus
                            options={account_list || []}
                            getOptionLabel={(option) => option.accountDescription}
                            ListboxComponent={ListboxComponent}
                            fullWidth
                            // value={account_list.find(i => i['id'] === values['splitDetails'][COADialog['index']]?.['id']) || null}
                            ListboxProps={{ sx: { height: '55vh' } }}
                            onChange={(e, v) => {
                                if (v) {
                                    addToSplitDetails(COADialog['index'], 'AccountCode', v['accountNumber'])
                                    addToSplitDetails(COADialog['index'], 'name', v['accountName'])
                                    // addToSplitDetails(COADialog['index'], 'id', v['id'])
                                    setCOADialog({ open: false, index: -1 });

                                    let tempSplitDetails = [...values['splitDetails']]
                                    if (tempSplitDetails[COADialog['index']]['Amount'] === "0") {
                                        tempSplitDetails[COADialog['index']]['Amount'] = ""
                                        setFieldValue("splitDetails", tempSplitDetails)
                                    }

                                }
                            }}
                            //!We ara saving accountCode And name separately instead of this save selected Object then make change in the payload
                            renderInput={(params) => <TextField {...params} label="Accounts" autoFocus />}
                            renderOption={(props, option) => (
                                <Box {...props} key={option.id}>
                                    <Typography variant="body1" flexGrow={1}>
                                        {option.accountDescription || ""}
                                    </Typography>
                                    <Typography variant="body2">
                                        {option.accountTypeName || ""}
                                    </Typography>
                                </Box>
                            )}
                        />
                        :
                        <SuspenseLoading loading />
                    }
                </DialogContent>
            </Dialog>
        </>

    )
}

export default COA