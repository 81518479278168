import React, { useState, useEffect } from 'react'
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Fade,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    Stack,
    TextField,
    Typography,
    Snackbar,
    Alert,
    useTheme,
    IconButton
} from "@mui/material";

import ForgotForm from './ForgotForm/ForgotForm';
import { useNavigate } from 'react-router-dom';
import LoadingButton from "@mui/lab/LoadingButton";

import logo from "../../images/logo_white.png";
import bg from "../../images/blue_bg.jpg";
import "./Login.css";
import { userLogin } from '../../API/API';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../Redux/actions';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function CustomInput(props) {

    return (
        <Stack gap={1} width={'100%'} >
            <Typography color={"#18345E"} component={'label'} textTransform={'capitalize'}>{props.name}</Typography>
            <TextField
                size="small"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        background: '#eaeaf9',
                        borderRadius: '0.25rem',
                        '& fieldset': { borderColor: '#e5e9f2', },
                        '&:hover fieldset': { borderColor: '#e5e9f2' },
                        '&.Mui-focused fieldset': { borderColor: '#e5e9f2' },
                    },
                }}
                {...props}
            />
        </Stack>

    )
}

export function CustomButton({ loading, title }) {
    return (
        <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            color="login"
            variant="contained"
            sx={{ color: "white" }}
        >
            {title}
        </LoadingButton>
    )
}


const Login = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch()

    const [emailField, setEmailField] = useState({ error: false, helperText: null })
    const [passwordField, setPasswordField] = useState({ error: false, helperText: null })
    const [imageLoaded, setImageLoaded] = useState(false);
    const [states, setstates] = useState({ loading: false, alert: false, forgotPassword: false })

    async function userAuthentication(event) {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        let email = data.get('email')
        let password = data.get('password')
        let valid_email = (/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email) //Verify the email format to prevent unnecessary API calls.
        let valid_password = !(/^\s*$/).test(password) //Check if string contains only spaces

        if (valid_email && valid_password) {
            setstates(prev => ({ ...prev, loading: true }))
            let result = await userLogin(data)
            if (result.status === 100) {
                navigate('/home', { state: { "access_id": result['user']['AccessID'] } })
                sessionStorage.setItem('user', JSON.stringify(result['user'])) //*Only maintain user session storage do not use global storage like redux, useContext it makes redirect slow 
            }
            else if (result.status === 403) {
                setPasswordField({ error: true, helperText: 'Incorrect Password' })
            }
            else if (result.status === 404) {
                setEmailField({ error: true, helperText: 'User Not Found or Invalid Mail-ID' })
            }
            else {
                setstates(prev => ({ ...prev, alert: true }))
            }
            setstates(prev => ({ ...prev, loading: false }))
        }
        else {
            if (!valid_email && !valid_password) {
                setEmailField({ error: true, helperText: 'Enter Valid EMail' })
                setPasswordField({ error: true, helperText: 'Enter Valid Password' })
            }
            else if (!valid_email) {
                setEmailField({ error: true, helperText: 'Enter Valid EMail' })
            }
            else if (!valid_password) {
                setPasswordField({ error: true, helperText: 'Enter Valid Password' })
            }
        }
    }

    useEffect(() => {
        const img = new Image();
        img.src = bg;
        img.onload = () => {
            setImageLoaded(true);
        };
    }, []);

    const [showPassword, setShowPassword] = useState(false)

    return (
        <>
            <Snackbar
                open={states.alert}
                onClose={() => setstates(prev => ({ ...prev, alert: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={'error'}>
                    Error while login please contact admin.
                </Alert>
            </Snackbar>

            <Grid container component="main" height={'100vh'} width={'100%'} position={'relative'} sx={{ backgroundColor: 'lightgray' }}>

                <Grid item
                    xs={false}
                    sm={false}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                        backgroundImage: `url(${bg})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        visibility: imageLoaded ? 'visible' : 'hidden',
                    }}
                />

                <Grid item position={'absolute'} top={0} bottom={0} left={0} right={0}>
                    <Stack
                        p={7}
                        sx={{
                            height: '100%',
                            width: '100%',
                            alignItems: 'flex-end',
                            [theme.breakpoints.only('xs')]: {
                                alignItems: 'center',
                            },
                            [theme.breakpoints.only('sm')]: {
                                alignItems: 'center',
                            },
                            [theme.breakpoints.up('md', 'lg', 'xl')]: {
                                pt: 14, pr: 14
                            },
                        }}
                    >
                        <Stack alignItems={'center'} height={'100%'} borderRadius={5} p={2}
                            sx={{
                                backgroundColor: 'white', width: '30%',
                                [theme.breakpoints.only('xs')]: {
                                    width: '100%'
                                },
                                [theme.breakpoints.only('sm')]: {
                                    width: '100%'
                                },
                                [theme.breakpoints.only('md')]: {
                                    width: '40%'
                                }
                            }}
                            overflow={'hidden'}
                        >
                            <Box
                                component={"img"}
                                src={logo}
                                height={states.forgotPassword ? '20%' : '35%'}
                                width={'100%'}
                                sx={{ objectFit: 'contain' }}
                                alt="Logo"
                            />
                            <Stack height={states.forgotPassword ? '80%' : '65%'} width={'100%'} justifyContent={'center'}
                                pr={states.forgotPassword ? 0 : 5} pl={states.forgotPassword ? 0 : 5} pb={states.forgotPassword ? 0 : 5}
                            >

                                {!states.forgotPassword ?
                                    <Stack gap={2} component={'form'} onSubmit={userAuthentication} >

                                        <CustomInput
                                            error={emailField.error}
                                            helperText={emailField.helperText}
                                            onClick={() => setEmailField({ error: false, helperText: null })}
                                            placeholder="jondoe@gmail.com"
                                            name="email"
                                        />

                                        <CustomInput
                                            error={passwordField.error}
                                            helperText={passwordField.helperText}
                                            onClick={() => setPasswordField(prev => ({ error: false, helperText: null }))}
                                            placeholder="*****************"
                                            name={"password"}
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                )
                                            }}
                                        />

                                        <Stack alignItems={'center'} direction={'row'} width={'100%'} justifyContent={'space-between'}>

                                            <FormControlLabel
                                                control={<Checkbox size="small" />}
                                                label={<Typography color={"#8a909d"} variant="body2" component={'label'} textTransform={'capitalize'}>Remember Me</Typography>}
                                            />

                                            <Link sx={{ cursor: 'pointer' }} component={'a'}
                                                onClick={() => setstates(prev => ({ ...prev, forgotPassword: true }))}
                                                underline="none" color={"#8a909d"}
                                            >
                                                Forgot password?
                                            </Link>
                                        </Stack>

                                        <CustomButton title={'Submit'} loading={states.loading} />
                                    </Stack>
                                    :
                                    <ForgotForm setstates={setstates} />
                                }

                            </Stack>
                        </Stack>
                    </Stack>

                </Grid>

            </Grid>

            <Backdrop open={states.loading} >
                <CircularProgress />
            </Backdrop>

        </>
    )
}

export default Login