import {
    Autocomplete, Backdrop, Box, AppBar, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel,
    IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Toolbar, Typography,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemAvatar,
    Card,
    CardActionArea,
    CardHeader,
} from '@mui/material'

import axios from 'axios'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ReportProblem, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import { updateClient } from '../../Redux/actions'

import { requestSearch } from '../../Tools/searchTool';
import { SuspenseLoading } from '../../Components/SuspenseLoading';
import { fetchClientList, fetchPropertiesList } from '../../API/API';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const _GUTTER = 2.5

function ClientsList({ group }) {

    const dispatch = useDispatch()
    const [clientsList, setClientsList] = useState()
    const [sourceClientsList, setSourceClientsList] = useState([])
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client) || {}
    const cp_access_id = useSelector(state => state.SNAP_PERSIST_STORE.cp_access_id)
    const [selectedClients, setSelectedClients] = useState([])

    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        if (cp_access_id) {
            getClientsList()
        }
    }, [])

    useEffect(() => {
        if (group) {
            sessionStorage.removeItem('cpList')
            setSelectedClients([])
        }
    }, [group])

    async function getClientsList() {
        setClientsList()
        let tempClientList = await fetchClientList(cp_access_id)
        setClientsList(tempClientList)
        setSourceClientsList(tempClientList)
    }

    function searchFunction(e) {
        let keysToSearch = ['name'];
        let result = requestSearch(e, sourceClientsList, keysToSearch)
        setSearchQuery(result.query)
        setClientsList(result.filterData)
    }

    async function handleSelectedClients(e, client) {
        let isChecked = e.target.checked
        let tempSelectedClients = [...selectedClients]

        if (isChecked) {
            if (group === 'user') {
                let removeIndex = tempSelectedClients.indexOf(tempSelectedClients[0]);
                tempSelectedClients.splice(removeIndex, 1);
            }
            tempSelectedClients.push(client)
        }
        else {
            let removeIndex = tempSelectedClients.indexOf(client);
            if (removeIndex !== -1) {
                tempSelectedClients.splice(removeIndex, 1);
            }
        }

        let data = {
            client_uuids: tempSelectedClients.map(i => i['userID']),
            client_urls: tempSelectedClients.map(i => i['url'])
        }

        if (tempSelectedClients.length === sourceClientsList.length) {
            alert('You cant select all clients for operator role')
            return
        }

        let res = await axios.post('/api/get_cp_list', data)
        sessionStorage.setItem('cpList', JSON.stringify(res.data))

        setSelectedClients(tempSelectedClients)
    }

    return (
        <>
            <Toolbar disableGutters variant='dense'
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                }}
            >
                <TextField
                    size="small"
                    placeholder="Search Client"
                    variant='outlined'
                    onChange={(e) => searchFunction(e)}
                    value={searchQuery}
                    fullWidth
                />
            </Toolbar>

            {clientsList ? clientsList.length > 0 ?
                <List sx={{ height: 'calc(100% - 48px)' }}>

                    {clientsList.map((client, clientIndex) => (
                        <ListItem
                            disablePadding
                            key={clientIndex}
                            divider={clientIndex + 1 !== clientsList.length}
                            secondaryAction={
                                <IconButton
                                    size='small'
                                    onClick={() => dispatch(updateClient(client))}
                                    disabled={!selectedClients.find(i => i['_id'] === client['_id'])}
                                >
                                    <Edit fontSize='small' />
                                </IconButton>
                            }
                            sx={{ backgroundColor: selectedClient['_id'] === client['_id'] ? 'lightgray' : 'inherit' }}
                        >
                            <ListItemAvatar>
                                <Checkbox
                                    size='small'
                                    onChange={(e) => handleSelectedClients(e, client)}
                                    checked={selectedClients.find(i => i['_id'] === client['_id']) ? true : false}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={client.name} />

                        </ListItem>
                    ))}
                </List>
                :
                <SuspenseLoading loading={false} NotFound={
                    searchQuery === "" ?
                        `No Clients are found` :
                        `No clients found with "${searchQuery}"`
                }
                />
                :
                <SuspenseLoading loading />
            }
        </>
    )

}

function PropertiesList() {
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const [propertiesList, setPropertiesList] = useState()
    const [searchQuery, setSearchQuery] = useState("")
    const [sourcePropertiesList, setSourcePropertiesList] = useState([])

    const [selectedProperties, setSelectedProperties] = useState([])

    useEffect(() => {
        if (selectedClient) {
            let tempCpList = JSON.parse(sessionStorage.getItem('cpList')) || []

            let selectedCpList = tempCpList.find(i => (i['client_id'] === selectedClient['userID'] && i['client_url'] === selectedClient['url']))
            if (selectedCpList) {
                let tempProperties = selectedCpList['properties']
                setPropertiesList(tempProperties)
                setSourcePropertiesList(tempProperties)
                setSelectedProperties(tempProperties)
            }
        }
    }, [selectedClient])


    function searchFunction(e) {
        let keysToSearch = ['corporationName'];
        let result = requestSearch(e, sourcePropertiesList, keysToSearch)
        setSearchQuery(result.query)
        setPropertiesList(result.filterData)
    }

    function handleSelectedProperties(property) {

        let isChecked = selectedProperties.find(i => i['corporationID'] === property['corporationID']) ? false : true
        let tempSelectedProperties = [...selectedProperties]

        if (isChecked) {
            tempSelectedProperties.push(property)
        }
        else {
            let removeIndex = tempSelectedProperties.indexOf(property);
            if (removeIndex !== -1) {
                tempSelectedProperties.splice(removeIndex, 1);
            }
        }
        setSelectedProperties(tempSelectedProperties)
        updateCpList(tempSelectedProperties)
    }

    function updateCpList(properties) {
        let tempCpList = JSON.parse(sessionStorage.getItem('cpList')) || []
        let index = tempCpList.findIndex(i => (i['client_id'] === selectedClient['userID'] && i['client_url'] === selectedClient['url']));
        tempCpList[index]['properties'] = properties;
        sessionStorage.setItem('cpList', JSON.stringify(tempCpList));
    }

    function checkAllProperties(e) {
        let isChecked = e.target.checked
        let tempSelectedProperties = [...selectedProperties]

        if (isChecked) {
            tempSelectedProperties = sourcePropertiesList
        }
        else {
            tempSelectedProperties = []
        }
        setSelectedProperties(tempSelectedProperties)
        updateCpList(tempSelectedProperties)
    }

    return propertiesList ?
        <Stack gap={_GUTTER} height={'100%'}>
            <Toolbar component={Paper} variant='dense' sx={{ gap: 1 }}>
                <FormControlLabel
                    control={<Checkbox onChange={checkAllProperties} checked={selectedProperties.length === sourcePropertiesList.length} />}
                    label="Select all properties"
                    sx={{ flexGrow: 1 }}
                />
                <TextField
                    size="small"
                    placeholder="Search Properties"
                    variant='standard'
                    onChange={(e) => searchFunction(e)}
                    value={searchQuery}
                />
            </Toolbar>

            <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 600: 2, 900: 3, 1200: 4, 1536: 4 }} style={{ overflow: 'auto', height: '100%' }}>
                {propertiesList.length !== 0 ?
                    <Masonry gutter='12px'>
                        {propertiesList.map((property, propertyIndex) => (
                            <Card key={propertyIndex}>
                                <CardActionArea onClick={() => handleSelectedProperties(property)}>
                                    <CardHeader
                                        action={
                                            selectedProperties.find(i => i['corporationID'] === property['corporationID']) ?
                                                <CheckBox color='primary' />
                                                : <CheckBoxOutlineBlankIcon />
                                        }
                                        subheader={property.name}
                                    />
                                </CardActionArea>
                            </Card>
                        ))}

                    </Masonry>
                    :
                    <SuspenseLoading loading={false}
                        NotFound={
                            searchQuery === "" ?
                                `No properties found for this ${selectedClient.name}` :
                                `No properties found with "${searchQuery}"`
                        }
                    />
                }
            </ResponsiveMasonry>
        </Stack>
        :
        <SuspenseLoading loading={selectedClient} NotFound='Click on Setting icon to edit' />
}

const CreateUser = ({ callBack = () => { } }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [microServicesList, setMicroServicesList] = useState([])
    const [groupsList, setGroupsList] = useState([])
    const [selectedMicroServices, setSelectedMicroServices] = useState([])
    const [selectedGroup, setSelectedGroup] = useState({})
    const [backdropState, setBackDropState] = useState(false)

    const [formDetails, setFormDetails] = useState({
        email: '',
        password: '',
        username: '',
        employee_id: ''
    })
    const [showPassword, setShowPassword] = useState(false)
    const [fullAccess, setFullAccess] = useState(false)

    useEffect(() => getInitialData(), [])

    function getInitialData() {
        dispatch(updateClient(null))
        axios.get('/api/micro_services').then(res => setMicroServicesList(JSON.parse(res.data)))
        axios.get('/api/groups').then(res => setGroupsList(JSON.parse(res.data)))
        sessionStorage.removeItem('cpList')
    }

    function updateForm(e) {
        let key = e.target.name
        let value = e.target.value

        let tempFormDetails = { ...formDetails }
        tempFormDetails[key] = value
        setFormDetails(tempFormDetails)
    }

    function checkPropertiesLength(arr) {
        return arr.some(obj => obj.properties.length === 0);
    }

    async function onSubmit() {
        setBackDropState(true)
        const { email, password, username, employee_id } = formDetails;
        const valid_email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        const valid_password = !/^\s*$/.test(password);
        const valid_username = !/^\s*$/.test(username);
        const valid_employee_id = !/^\s*$/.test(employee_id);
        const valid_group = Object.keys(selectedGroup).length > 0
        const valid_micro_services = selectedMicroServices.length > 0;
        let cp_list = JSON.parse(sessionStorage.getItem('cpList')) || []

        if (valid_username && valid_employee_id && valid_email && valid_password && valid_group && valid_micro_services) {
            let micro_services_ids = selectedMicroServices.map(i => i['_id'])[0] //!Remove after add accordions based user creaction each accordian represents each micro service
            let entrypoint_service = selectedGroup['groupName'] === 'user' ? 'dashboard' : 'admin_dashboard'
            let group_id = selectedGroup['_id']

            if (cp_list.length > 0) {
                if (checkPropertiesLength(cp_list)) {
                    setBackDropState(false)
                    alert('Warning every client has its properties please check and update')
                    return
                }
                else {
                    cp_list.forEach(i => {
                        i['properties'] = i['properties'].map(i => i['corporationID'])
                    })
                }
            }

            await axios.post('/api/create_user', {
                email,
                password,
                username,
                employee_id,
                group_id,
                fullAccess,
                micro_services_ids,
                cp_list,
                entrypoint_service
            }).then(res => {
                let data = res.data
                if (data[1] === 200) {
                    navigate(0)
                }
                alert(data[0])
            });

            setBackDropState(false)
            return
        }
        setBackDropState(false)

        if (!valid_username) {
            alert("Please enter a valid User Name.");
            return;
        }

        if (!valid_email) {
            alert("Please enter a valid email address.");
            return;
        }

        if (!valid_password) {
            alert("Please enter a valid password.");
            return;
        }

        if (!valid_employee_id) {
            alert("Please enter a valid Employee ID.");
            return;
        }

        if (!valid_group) {
            alert("Please select a group ID.");
            return;
        }

        if (!valid_micro_services) {
            alert("Please select at least one micro service.");
            return;
        }

        if (cp_list.length === 0) {
            alert("Please select CP_List");
            return;
        }
    }

    return (
        <>
            <Stack height={'100%'} gap={1} >
                <Stack direction={'row'} gap={2} component={'form'} noValidate autoComplete='off'>

                    <TextField
                        value={formDetails['username']}
                        onChange={updateForm}
                        name='username'
                        size='small'
                        fullWidth
                        label='Username'
                        autoComplete="off"
                    />

                    <TextField
                        value={formDetails['email']}
                        onChange={updateForm}
                        name='email'
                        size='small'
                        fullWidth
                        label='Mail'
                        autoComplete="off"
                    />

                    <TextField
                        value={formDetails['employee_id']}
                        onChange={updateForm}
                        name='employee_id'
                        size='small'
                        fullWidth
                        label='Employee ID'
                        autoComplete="off"
                    />

                    <TextField
                        value={formDetails['password']}
                        onChange={updateForm}
                        name='password'
                        size='small'
                        fullWidth label='Password'
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            )
                        }}
                        autoComplete="new-password"
                    />


                    <Autocomplete
                        value={selectedMicroServices}
                        multiple
                        fullWidth
                        options={microServicesList}
                        getOptionLabel={(option) => option.micro_service_name?.replace('_', ' ')}
                        ChipProps={{ style: { textTransform: 'capitalize' } }}
                        ListboxProps={{ style: { textTransform: 'capitalize' } }}
                        onChange={(e, v) => setSelectedMicroServices(v)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                size='small'
                                label='Micro Services'
                            />
                        }

                        getOptionDisabled={(option) => option['micro_service_name'] !== 'invoice_processing'}
                    />

                    <FormControl sx={{ width: '100%' }} size='small'>
                        <InputLabel>Group</InputLabel>
                        <Select
                            value={Object.keys(selectedGroup).length > 0 ? selectedGroup : ''}
                            label="Group"
                            onChange={(e) => {
                                let value = e.target.value
                                if (value['groupName'] === 'user') {
                                    setFullAccess(false)
                                }
                                else if (value['groupName'] === 'super_admin' || value['groupName'] === 'admin') {
                                    setFullAccess(true)
                                }
                                else {
                                    setFullAccess(false)
                                }
                                setSelectedGroup(e.target.value)
                            }}
                        >
                            {groupsList.map(group =>
                                <MenuItem
                                    key={group['_id']}
                                    value={group}
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    {group['groupName']?.replace('_', ' ')}
                                </MenuItem>
                            )}

                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '80%' }} size='small'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fullAccess}
                                    onChange={(e) => setFullAccess(e.target.checked)}
                                    disabled={['user', 'super_admin'].includes(selectedGroup['groupName'])}
                                // disabled={['operator', 'user', 'super_admin'].includes(selectedGroup['groupName'])}
                                />
                            }
                            label="CP Full Access"
                        />
                    </FormControl>

                </Stack>

                <Stack direction={'row'} height={'100%'} gap={1} overflow={'auto'}>
                    {(!fullAccess) ? selectedGroup['groupName'] ?
                        <>
                            <Paper sx={{ height: '100%', width: '25%', pr: 0.5, pl: 0.5, overflow: 'auto', }}>
                                <ClientsList group={selectedGroup['groupName']} />
                            </Paper>
                            <Box height={'100%'} width={'75%'}>
                                <PropertiesList />
                            </Box>
                        </>
                        :
                        <SuspenseLoading loading={false} NotFound='Select group type' />
                        :
                        <SuspenseLoading loading={false} NotFound='CP List' />
                    }
                </Stack>

                <Button variant='contained' color='nav' fullWidth onClick={onSubmit}>
                    Submit
                </Button>
            </Stack>

            <Backdrop open={backdropState}>
                <CircularProgress color='nav' />
            </Backdrop>
        </>
    )
}

export default CreateUser;