import React, { useEffect, useRef, useState } from 'react'
import AutoSizer from "react-virtualized-auto-sizer";
import { Table } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import './BIllSummaryTable.css'
import _ from 'lodash';
import { Box, Tooltip, IconButton, Badge } from '@mui/material';
import { useSelector } from 'react-redux';

export default function BillSummaryTable({
    data = [],
    loader = false,
    searchQuery,
    isProperty,
    ActionComponent = () => { }
}) {
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const { Column, HeaderCell, Cell } = Table;

    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const flexgrow = 1

    const columns = [
        {

            'key': 'client',
            'label': 'Client',
            flexgrow: flexgrow,
        },
        {
            'key': 'corporationName',
            'label': 'Property',
            flexgrow: flexgrow,
        },
        {
            key: 'vendor',
            label: 'Vendor',
            flexgrow: flexgrow,
        },
        {
            key: 'Transac_type',
            label: 'Transaction Type',
            flexgrow: 0.7,
        },
        {
            key: 'InvoiceNumber',
            label: 'Invoice Number',
            flexgrow: flexgrow,
        },
        {
            key: 'BillDate',
            label: 'Invoice Date',
            flexgrow: flexgrow,
        },
        {
            key: 'Amount',
            label: 'Amount',
            flexgrow: 0.7,
        },
        {
            key: 'received_time',
            label: 'Received Time',
            flexgrow: flexgrow,
        },
        {
            key: 'createdTime',
            label: 'Posted Time',
            flexgrow: flexgrow,
        },
        {
            key: 'TurnAroundTime',
            label: 'Turn around time',
            flexgrow: flexgrow,
        },
        {
            key: 'createdBy',
            label: 'Created by',
            flexgrow: flexgrow,
        },
    ]

    function fetchColumns() {
        if (columns.length === 1 && data.length > 0) {
            return Object.keys(data[0]).map(key => ({
                'key': key,
                'label': key,
                'flexgrow': 1,
            }))
        }

        try {
            if (selectedClient?.['userID'] !== 'all') {
                let index = columns.findIndex(i => ["client"].includes(i['key']))
                if (index !== -1) {
                    columns.splice(index, 1)
                }
            }

            if (selectedProperty !== "all" && selectedProperty !== "none") {
                let index = columns.findIndex(i => ["corporationName", "corporation_name"].includes(i['key']))
                if (index !== -1) {
                    columns.splice(index, 1)
                }
            }
        }
        catch (err) {
            console.err(err)
        }

        return columns
    }

    function fetchData() {
        let key = sortColumn
        if (sortColumn === 'TurnAroundTime') {
            key = 'tat'
        }
        if (key && sortType) {
            return data.sort((a, b) => {
                let x = a[key];
                let y = b[key];

                if (sortColumn === 'createdTime' || sortColumn === 'received_time') {
                    x = new Date(x).getTime();
                    y = new Date(y).getTime();
                }
                else {
                    if (typeof x === 'string') {
                        x = x.charCodeAt();
                    }
                    if (typeof y === 'string') {
                        y = y.charCodeAt();
                    }
                }


                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data
    }

    function CustomCell({ rowData, tr, searchQuery }) {
        let str = Array.isArray(rowData[tr]) ? (rowData[tr][0] || '-') : (rowData[tr] || '-');
        str = str.toString()
        return (

            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                {str ? searchQuery ?
                    <span
                        dangerouslySetInnerHTML={{
                            __html: str?.replace(new RegExp(searchQuery, 'gi'), match =>
                                `<mark>
                                    ${match}
                                </mark>`
                            )
                        }}
                    />
                    :
                    str
                    :
                    '-'
                }
            </span>
        )
    }

    function CustomEmptyCell({ info }) {
        if (selectedProperty === null && isProperty) {
            return (
                <Box height={'100%'} display={'grid'} sx={{ placeItems: 'center' }}>
                    {!selectedClient ? 'Following the client selection, proceed to choose the property.' :
                        selectedClient['url'] === "all" ? info : 'Select the property'
                    }
                </Box>
            )
        }
        else if (searchQuery) {
            return (
                <Box height={'100%'} display={'grid'} sx={{ placeItems: 'center' }}>
                    No data found with "{searchQuery}"
                </Box>
            )
        }
        return info
    }

    const handleSortColumn = (dataKey, sortType) => {
        console.log(dataKey)
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(dataKey);
            setSortType(sortType);
        }, 500);
    };

    return (
        <AutoSizer>
            {({ height, width }) =>
                <Table
                    virtualized
                    width={width}
                    height={height}
                    data={fetchData()}
                    cellBordered
                    bordered
                    translate3d
                    loading={loading || loader}
                    renderEmpty={(info) => <CustomEmptyCell info={info} />}
                    // rowHeight={46}

                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                >

                    <Column fixed width={72}>
                        <HeaderCell align='center' style={{ backgroundColor: '#F1F1FB' }} className='table-header-cell-rs'>S.No</HeaderCell>
                        <Cell verticalAlign='center' align='center' children={(_, rowIndex) => rowIndex + 1} />
                    </Column>

                    {fetchColumns().map((tr, trIndex) =>
                        <Column
                            sortable
                            key={trIndex}
                            flexGrow={tr['flexgrow']}
                            fullText
                        // resizable
                        >
                            <HeaderCell style={{ backgroundColor: '#F1F1FB' }} className='table-header-cell-rs'>
                                {tr.label}
                            </HeaderCell>

                            <Cell verticalAlign='center' dataKey={tr['key']}
                                children={(rowData, rowIndex) =>
                                    <CustomCell
                                        tr={tr.key}
                                        rowData={rowData}
                                        searchQuery={searchQuery}
                                    />
                                }
                            />
                        </Column>
                    )}


                    <Column fixed={'right'} width={72} align={'center'}>
                        <HeaderCell style={{ backgroundColor: '#F1F1FB', position: 'relative' }} className='table-header-cell-rs'>
                            Action
                        </HeaderCell>

                        <Cell verticalAlign='center'
                            style={{ padding: 0 }}
                            children={(rowData, rowIndex) =>
                                ActionComponent(rowData) //!Note only return rowData only if isTree 
                            }
                        />
                    </Column>

                </Table>
            }

        </AutoSizer>

    )
}