import React, { useState, useEffect, useRef } from 'react'

import { useSnackbar } from 'notistack';

import {
    Worker,
    Viewer,
    ProgressBar,
    SpecialZoomLevel,
    RotateDirection,
    Icon, MinimalButton, Position, Tooltip,

} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import './FilePanel.css'

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { toolbarPlugin, MoreActionsPopover } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { getFilePlugin } from '@react-pdf-viewer/get-file';

import { AppBar, Box, Checkbox, Chip, Collapse, Fab, IconButton, Paper, Stack, Typography, } from '@mui/material';
import { getOCR_Response } from '../../../API/API';
import { CheckAllPages, PageTabs } from './FileHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateCheckedPages, updateOpenReviewForm, updatePageProperties } from '../../../Redux/actions';
import { DragIndicator, FitScreen, Rotate90DegreesCw, Download as DownloadIcon, ArrowUpward, ArrowDownward, KeyboardArrowUp, KeyboardArrowDown, ZoomIn, ZoomInOutlined, ZoomOutMapOutlined, ZoomOutOutlined, Email, Print, Drafts, Mail, DraftsOutlined, MailOutline } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { Bill_Process_Status } from '../../../Tools/constants';
import { Interweave } from 'interweave';
import moment from 'moment';


export default function FilePanel({ selectedBill, invoiceDetails, updatePages, no_of_pages = [], emailBody = [], setPDFErrorOccur }) {
    const { enqueueSnackbar } = useSnackbar();

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file) => {
            const path = selectedBill['s3_key']
            const parts = path.split('/');
            const filename = parts[parts.length - 1];
            return filename
        },
    });

    const { DownloadButton } = getFilePluginInstance;

    const url = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${encodeURIComponent(selectedBill['s3_key'])}`

    const defaultStyles = {
        position: 'absolute',
        border: '1px solid red',
        display: 'none',
        pointerEvents: 'none',
        boxSizing: 'border-box',
        zIndex: 1100,
    }

    const [styles, setStyles] = useState(defaultStyles)
    const [boundingBoxes, setBoundingBoxes] = useState([])

    async function onDocumentLoad({ doc: { numPages } }) {
        let no_of_pages = Array.from({ length: numPages }, (_, k) => k + 1)
        updatePages(no_of_pages)
        if (selectedBill['bill_status'] !== Bill_Process_Status.OCR_FAILED) {
            fetchBoundingBoxes()
        }

        // const innerPagesElement = document.querySelector('.rpv-core__inner-pages');
    }

    async function fetchBoundingBoxes() {
        let temp_OCR_response = await getOCR_Response(selectedBill['file_table_id'])
        if (Array.isArray(temp_OCR_response) && temp_OCR_response.length > 0) {
            setBoundingBoxes(temp_OCR_response)
        }
    }

    const getBoundingBox = (e, pageNumber, rotation) => {

        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        let page = boundingBoxes.find(i => i['page_number'] === pageNumber) || { 'page_blocks': [] }

        return page['page_blocks'].find(
            (box) => x >= box.Left * rect.width && x <= (box.Left + box.Width) * rect.width && y >= box.Top * rect.height && y <= (box.Top + box.Height) * rect.height
        );
    };

    function copyTextFromPDf(e, pageIndex, rotation) {
        let boundingBox = getBoundingBox(e, pageIndex, rotation)
        if (boundingBox) {
            let text = boundingBox['Text']
            let tempDate = moment(text, true).format("MM/DD/YYYY")
            if (moment(tempDate).isValid()) {
                text = tempDate
            }
            navigator.clipboard.writeText(text);
            enqueueSnackbar(`"${text}" copied to clipboard`, { variant: 'info' })
        }
    }

    function onMouseMoveHandler(e, pageIndex, rotation) {
        let rect = e.target.getBoundingClientRect();
        let boundingBox = getBoundingBox(e, pageIndex, rotation)

        if (boundingBox) {
            setStyles(prev => ({
                ...prev,
                display: 'block',
                width: `${boundingBox.Width * rect.width}px`,
                height: `${boundingBox.Height * rect.height}px`,
                top: `${boundingBox.Top * rect.height}px`,
                left: `${boundingBox.Left * rect.width}px`,
            }))
        }
        else {
            setStyles(defaultStyles)
        }
    }

    function PageRender(props) {
        let pageNumber = typeof (props['pageIndex']) === 'number' ? props['pageIndex'] + 1 : 0
        //In initial condition rotation is 90
        let rotation = props['rotation']
        return (
            <>
                {props.canvasLayer.children}

                <PaperComponent sx={{ position: 'fixed', top: 10, right: 10 }}>
                    <DragIndicator id="draggable-dialog-title" fontSize='small' sx={{ cursor: 'grab' }} />
                    <Typography>{pageNumber}</Typography>
                    <CheckBoxComponent pageNumber={pageNumber} />
                    <IconButton onClick={() => props.onRotatePage(RotateDirection.Forward)}>
                        <Rotate90DegreesCw />
                    </IconButton>
                </PaperComponent>

                <Box
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 1000,
                        cursor: 'pointer'
                    }}
                    onMouseMove={(e) => onMouseMoveHandler(e, pageNumber, rotation)}
                    onDoubleClick={(e) => copyTextFromPDf(e, pageNumber, rotation)}
                />
                <Box style={styles} />
                {props.annotationLayer.children}
                {props.textLayer.children}
            </>

        )
    }

    function fetchInitialPageNumber() {
        if (invoiceDetails?.hasOwnProperty('page_numbers') && invoiceDetails['page_numbers'].length > 0 && invoiceDetails['page_numbers'][0] !== 1) {
            return invoiceDetails['page_numbers'][0] - 1
        }
        return undefined
    }

    function ErrorLoading(error) {
        console.log(error)
    }

    const [openMailBody, setMailBody] = useState(false)
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar, } = toolbarPluginInstance;

    return (

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">

            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        borderBottom: openMailBody ? '1px solid rgba(0, 0, 0, 0.3)' : 'none',
                        overflow: 'hidden',
                        transition: 'height 400ms ease-in-out',
                        height: openMailBody ? '288px' : '2.6rem',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            backgroundColor: 'rgb(229, 229, 255)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            padding: '4px',
                            height: '2.6rem'
                        }}
                    >

                        <Toolbar>
                            {(slots) => {
                                const {
                                    CurrentPageLabel,

                                    Print,
                                    EnterFullScreen,
                                    Download,
                                    Zoom,
                                    ZoomIn,
                                    ZoomOut
                                } = slots
                                // selectedBill={selectedBill}

                                return (
                                    <>
                                        <PageTabs
                                            scrollToPageIndex={jumpToPage}
                                            no_of_pages={no_of_pages}
                                            CurrentPageLabel={CurrentPageLabel}
                                        />
                                        {/* <EnterFullScreen /> */}

                                        <ZoomOut />
                                        <Zoom />
                                        <ZoomIn />

                                        <DownloadButton />
                                        {/* <Print /> */}


                                        <MinimalButton
                                            ariaLabel="Toggle the bookmarks"
                                            isSelected={openMailBody}
                                            onClick={() => setMailBody((opened) => !opened)}
                                        >
                                            <Icon size={18}>
                                                {openMailBody ? <DraftsOutlined /> : <MailOutline />}
                                            </Icon>
                                        </MinimalButton>

                                        {no_of_pages.length > 1 &&
                                            <CheckAllPages no_of_pages={no_of_pages} />
                                        }
                                        <MoreActionsPopover toolbarSlot={slots} />
                                    </>
                                )
                            }}
                        </Toolbar>
                    </div>
                    {openMailBody &&
                        <div
                            style={{
                                overflow: 'auto',
                                padding: '8px',
                                height: 'calc(288px - 2.6rem)'
                            }}
                        >
                            <Interweave content={emailBody[0]} />
                            <Interweave content={emailBody[1]} />
                        </div>
                    }
                </div>

                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                    }}
                >
                    <Viewer
                        fileUrl={url}
                        renderLoader={(percentages) => (
                            <Box width={'240px'}>
                                <ProgressBar progress={Math.round(percentages)} />
                            </Box>
                        )}
                        onDocumentLoad={(e) => onDocumentLoad(e)}
                        renderError={({ message, name }) => {
                            enqueueSnackbar(message, { variant: 'error' });
                            setPDFErrorOccur(true)
                        }}
                        renderPage={PageRender}
                        plugins={[toolbarPluginInstance, pageNavigationPluginInstance, getFilePluginInstance]}
                        initialPage={fetchInitialPageNumber()}
                    // defaultScale={SpecialZoomLevel.PageWidth}
                    />
                </div>
            </div>

        </Worker>
    )
}

function CheckBoxComponent({ pageNumber }) {
    const dispatch = useDispatch()
    const page_properties = useSelector(state => state.SNAP_STORE.page_properties) || {}
    const { checked_pages = [], blocked_pages = [], split_pages = [], invoice_pages = [] } = page_properties

    function checkPages(e) {
        let isChecked = e.target.checked
        let tempPageProperties = { ...page_properties }
        let tempCheckedPages = [...tempPageProperties['checked_pages']]

        if (isChecked) {
            tempCheckedPages.push(pageNumber)
        }
        else {
            const removeIndex = tempCheckedPages.indexOf(pageNumber);
            if (removeIndex !== -1) {
                tempCheckedPages.splice(removeIndex, 1);
            }
        }

        tempPageProperties['checked_pages'] = tempCheckedPages
        dispatch(updateOpenReviewForm(false))
        dispatch(updatePageProperties(tempPageProperties))
    }

    return (
        <Checkbox
            color='warning'
            checked={
                checked_pages.includes(pageNumber) ||
                blocked_pages.includes(pageNumber) ||
                split_pages.includes(pageNumber) ||
                invoice_pages.includes(pageNumber)
            }
            disabled={
                blocked_pages.includes(pageNumber) ||
                split_pages.includes(pageNumber) ||
                invoice_pages.includes(pageNumber)
            }
            onChange={checkPages}
        />
    )
}

function PaperComponent(props) {
    const draggableRef = useRef(null);
    return (
        <Draggable
            nodeRef={draggableRef}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Stack component={Paper} alignItems={'center'} zIndex={1200} ref={draggableRef} {...props} />
        </Draggable>
    );
}
