import {
    SELECTED_BILL_INDEX,
    SELECTED_BILL_INVOICE_DETAILS,
    UPDATE_ACCOUNTS_LIST,
    PROPERTIES_LIST,
    CHECKED_PAGES,
    BILLS,
    OPEN_REVIEW_FORM,
    BLOCKED_PAGES,
    CLIENTS_LIST,
    NO_OF_PAGES,
    BANKS_AND_CREDIT,
    FINAL_FORM,
    CURRENT_PAGE_INDEX,
    CURRENT_PAGE_NUMBER,
    PAGE_PROPERTIES
} from "./constants"

const initial_SNAP_STORE_states = {
    openReviewPanel: false,
    selectedBillIndex: null,
    billInvoiceDetails: null,
    page_properties: {
        blocked_pages: [],
        split_pages: [],
        invoice_pages: [],
        checked_pages: [],
    }
}

export const SNAP_STORE = (state = initial_SNAP_STORE_states, action) => {
    const { type, payload } = action

    switch (type) {

        case UPDATE_ACCOUNTS_LIST:
            return { ...state, ...{ accountslist: payload } }

        case PROPERTIES_LIST:
            return { ...state, ...{ propertiesList: payload } }

        case CLIENTS_LIST:
            return { ...state, ...{ clientList: payload } }

        case SELECTED_BILL_INDEX:
            return { ...state, ...{ billIndex: payload } };

        case SELECTED_BILL_INVOICE_DETAILS:
            return { ...state, ...{ billInvoiceDetails: payload } };

        case BILLS:
            return { ...state, ...{ bills: payload } }

        case CHECKED_PAGES:
            return { ...state, ...{ checkedPages: payload } }

        case OPEN_REVIEW_FORM:
            return { ...state, ...{ openReviewForm: payload } }

        case BLOCKED_PAGES:
            return { ...state, ...{ blocked_pages: payload } }

        case NO_OF_PAGES:
            return { ...state, ...{ no_of_pages: payload } }

        case BANKS_AND_CREDIT:
            return { ...state, ...{ bankAndCredit: payload } }

        case FINAL_FORM:
            return { ...state, ...{ finalForm: payload } }

        case CURRENT_PAGE_NUMBER:
            return { ...state, ...{ current_page_number: payload } }

        case PAGE_PROPERTIES:
            return { ...state, ...{ page_properties: payload } }

        default:
            return state
    }
}