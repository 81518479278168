import { createTheme } from "@mui/material";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
    palette: {
        add: createColor("#F29557"),
        login: createColor("#FF6600"),
        icon: createColor("#ffffff"),
        progress: createColor("#fec400"),
        nav: createColor('#030192'),
        secondary_theme: createColor('#6d7de0'),
        to_be_approved: createColor("#a2efa2"),
        need_attension: createColor("#eda5a5"),
        secondaryToolBar: createColor("#E5E5FF"),
        chipColor: createColor('#ff8749'),
        chipColor2: createColor('#8484ff'),
        uploadBtn: createColor('#6d7de0')
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 5px 15px -3px rgba(0, 0, 0, 0.05)"
                }
            }
        },
    },
    typography: {
        fontFamily: "Poppins-Regular, sans-serif",
    }
});

export default theme