import React, { useEffect, useRef, useState } from 'react'
import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    Stack,
    Zoom,
    Dialog,
    DialogContentText,
    DialogContent,
    DialogActions,
    IconButton,
    Autocomplete,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    TextField,
    DialogTitle,
    Radio,
    Chip,
} from '@mui/material'
import { Clear, Delete, Download, MoveToInbox, ReportProblem, Send, ThumbUp, Visibility } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, useFormikContext } from 'formik'
import currency from 'currency.js'
import moment from 'moment'
import _ from 'lodash'

import logo from "../../../images/logo_white.png"
import { updateOpenReviewForm, updatePageProperties } from '../../../Redux/actions'
import { SplitAndSendToQueue, getSendMial, get_ocr_text, remove_bill, send_mail, splitBills, updatePageAndBillStatus, update_bill_status, update_mail_body, update_track_changes, update_verified_table } from '../../../API/API'
import ReviewForm from './ReviewForm/ReviewForm'
import { SuspenseLoading } from '../../SuspenseLoading'
import zIndex from '@mui/material/styles/zIndex'
import { fetchPayLoad } from './payload'
import { enqueueSnackbar } from 'notistack'
import { addBillToAttachment, sendToNimbleAPI } from '../../../API/NimbleAPI'
import { Bill_Process_Status } from '../../../Tools/constants'
import { Transition } from '../../Transition'
import { mailFormSchema, validationSchema } from './validationSchema'
import { confirm } from '../../Confirm'

function fetchInvoiceDate(invoiceDetails) {
    if (invoiceDetails?.['invoice_date']) {
        let modified_date = moment(invoiceDetails?.['invoice_date'])
        if (modified_date.isValid) {
            return modified_date.format('MM/DD/YYYY')
        }
    }
    return ""
}

function fetchPaymentDueDate(invoice_date, invoiceDetails) {
    if (invoiceDetails?.['payment_due_date']) {
        let modified_date = moment(invoiceDetails?.['payment_due_date'])
        if (modified_date.isValid()) {
            return modified_date.format('MM/DD/YYYY')
        }
        else if (invoice_date !== "") {
            let tempDueDateDuration = invoiceDetails?.['payment_due_date']
            let dueFromString = parseInt(tempDueDateDuration.match(/\d+/));

            if (!isNaN(dueFromString)) {
                if (dueFromString <= 90) {
                    let converted_invoice_date = new Date(invoice_date)
                    converted_invoice_date.setDate(converted_invoice_date.getDate() + dueFromString)
                    return moment(converted_invoice_date).format('MM/DD/YYYY')
                }
            }
        }
    }
    return ""
}

function check_effected_pages(bill_invoice_pages, checked_pages) {
    if (_.isEqual(bill_invoice_pages, checked_pages)) {
        return false
    }
    else if (bill_invoice_pages.every(val => checked_pages.includes(val))) {
        return false
    }
    return true
}

function confirmByUser(values, selectedBill) {
    function isAmountHigh(amount) {
        amount = parseFloat(amount);
        return amount >= 5000;
    }

    function checkDate(data) {
        let date = moment(data, 'MM/DD/YYYY', true);
        let presentYear = new Date().getFullYear();
        let year = new Date(date).getFullYear();
        return !(presentYear - 1 <= year)
    }

    function checkProperty(property) {
        function get_from_address() {
            let slittedS3 = selectedBill['s3_key']?.split('/')
            let index = Array.isArray(slittedS3) ? slittedS3.indexOf('bills') : -1
            if (index === 3) { //!Note The root of the file path is consistently located at index 3, indicating the 4th position.
                let bookingDirectory = slittedS3[2] //!BookingDirectory always located at before bills
                return bookingDirectory?.toLowerCase()
            }
            return ""
        }
        let bookkeepingDirectory = property['bookkeepingDirectory']?.toLowerCase()
        var stringSimilarity = require("string-similarity");
        let from_address = get_from_address()
        let match = stringSimilarity.compareTwoStrings(bookkeepingDirectory, from_address)
        return match < 0.7
    }

    let confirm = []

    if (isAmountHigh(values['invoice_amount'])) {
        let a = window.confirm(`Alert: The amount is above 4999, Please confirm the amount ${values['invoice_amount']}`)
        confirm.push(a)
    }
    if (checkDate(values['invoice_date'])) {
        let b = window.confirm(`Alert: Bill Posting in ${values['invoice_date']}. Please confirm...`)
        confirm.push(b)
    }
    // if (checkDate(values['books_date'])) {
    //     confirm = window.confirm(`Alert: Bill Posting in ${values['books_date']}. Please confirm...`)
    // }

    if (checkProperty(values["property"])) {
        let c = window.confirm(`Please confirm the property ${values["property"]['name']}`)
        confirm.push(c)
    }

    return confirm.every(i => i)
}

function getEndpoint(transactionType) {
    switch (transactionType) {
        case 0:
            return '/BillEntrySave';
        case 1:
            return '/BillPaymentSave';
        case 2:
            return '/CheckSave';
            break;
        case 3:
            return '/CreditCardSave';
    }
}

function compareStrings(str1, str2) {
    const stringSimilarity = require("string-similarity");
    return stringSimilarity.compareTwoStrings(str1.toLowerCase(), str2.toLowerCase()) * 100
}

function calculateMatchPercentage(str1, str2) {
    // Convert both strings to lowercase for case-insensitive comparison
    const lowerStr1 = str1?.toLowerCase();
    const lowerStr2 = str2?.toLowerCase();

    // Split strings into arrays of words
    const wordsStr1 = lowerStr1?.split(/\s+/)?.filter(word => word.length > 0);
    const wordsStr2 = lowerStr2?.split(/\s+/)?.filter(word => word.length > 0);

    // Initialize counters for matched words and total words in str1
    let matchedCount = 0;
    const totalWordsInStr1 = wordsStr1?.length;

    // Keep track of words in str1 that have been matched in str2
    const matchedWords = new Set();

    // Loop through each word in wordsStr1
    for (const word of wordsStr1) {
        // If the word is found in wordsStr2 and not already matched, increment the matchedCount
        if (wordsStr2?.includes(word) && !matchedWords.has(word)) {
            matchedCount++;
            matchedWords.add(word); // Add the matched word to the set
        }
    }

    let ADDRESS_THRESHOLD = 3
    // If at least 4 words match, return 100% match
    if (matchedCount >= ADDRESS_THRESHOLD) {
        return 100;
    }

    // Calculate the percentage match
    const percentageMatch = (matchedCount / totalWordsInStr1) * 100;
    return percentageMatch;
}

function getBestMatch(str1, str2) {
    let word_match = calculateMatchPercentage(str1, str2)
    let string_match = compareStrings(str1, str2)

    return word_match > string_match ? word_match : string_match
}

function alphabeticPercentage(str) {
    // Count the number of alphabetic characters
    const alphabeticCount = (str.match(/[a-zA-Z]/g) || []).length;
    // Calculate the percentage
    const percentage = (alphabeticCount / str.length) * 100;
    return percentage.toFixed(2); // Return the percentage rounded to 2 decimal places
}

function getLineItems(lineItems = []) {
    let newSplitDetails = []

    lineItems?.forEach(i => {
        newSplitDetails.push({
            AccountCode: "",
            name: "",
            Description: i['description'],
            Amount: i['net_amount'],
            storeID: "",
            purpose: i['purpose']
        })
    })

    if (newSplitDetails.length === 0) {
        newSplitDetails.push({
            AccountCode: "",
            name: "",
            Description: "",
            Amount: "",
            storeID: "",
            purpose: ""
        })
    }

    return newSplitDetails
}

const ReviewPanel = ({
    selectedBill = {},
    invoiceDetails = {},
    no_of_pages = [],
    updateBillIndex = () => { },
    PDFErrorOccur = false
}) => {
    const dispatch = useDispatch()
    const user = JSON.parse(sessionStorage.getItem('user'))
    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const openForm = useSelector(state => state.SNAP_STORE.openReviewForm) || false
    const propertiesList = useSelector(state => state.SNAP_STORE.propertiesList) || []
    const page_properties = useSelector(state => state.SNAP_STORE.page_properties) || {}
    const { bill_status, file_table_id } = selectedBill
    const action_performedby = user.email

    const [loading, setLoading] = useState(false)
    const [openMailForm, setOpenMailForm] = useState(false)
    const [generatedS3Key, setGeneratedS3Key] = useState()

    //!Note invoice_pages and bill_invoice_pages are not same 
    //* invoice_pages is from file
    //* bill_invoice_pages is from bill
    const { checked_pages = [], blocked_pages = [], split_pages = [], invoice_pages = [] } = page_properties
    const bill_invoice_pages = invoiceDetails['page_numbers'] || []
    const bill_id = selectedBill['_id']

    const defaultPayload = {
        ...page_properties,
        bill_invoice_pages,
        file_table_id,
        no_of_pages,
        action_performedby,
        bill_id,
        "create_dummy_bill": check_effected_pages(bill_invoice_pages, checked_pages)
    }

    const property = propertiesList.find(i => i['corporationID'] === selectedBill['corporation_id']) || null
    const invoice_amount = currency(invoiceDetails['invoice_amount']).value || ""
    const invoice_date = fetchInvoiceDate(invoiceDetails)
    const payment_due_date = fetchPaymentDueDate(invoice_date, invoiceDetails)

    const initialValues = {
        vendor: null,
        contract: null,
        payment_type: "",
        bank_account: null,
        transactionType: 0,
        check_number: "",
        property,
        invoice_number: invoiceDetails['invoice_number'],
        invoice_amount,
        invoice_date,
        due_date: payment_due_date !== "" ? payment_due_date : invoice_date,
        books_date: invoice_date,
        memo: "",
        splitDetails: [],
        // splitDetails: getLineItems(invoiceDetails['line_items']),
        selectedBillsToPay: [],
        isBooksDateEnabled: false,
        IsValidateBill: false,
        IsApprove: false,
        //UI Flags
        errorDialog: {
            open: false,
            status: null,
            message: null,
            BillID: null
        }
    }

    const mailFormInitialValues = {
        to: [],
        cc: [],
        description: '',
        type: null
    }

    function closeErrorDialog(setFieldValue) {
        setFieldValue('errorDialog', {
            "open": false,
            "message": null,
            "status": null,
            "BillID": null
        })
    }

    function closeMailFormDialog() {
        setOpenMailForm(false)
    }

    async function openFormHandler() {
        dispatch(updateOpenReviewForm(true))
        let tempS3Key = await getS3Key()
        setGeneratedS3Key(tempS3Key)
    }

    async function getS3Key() {
        let tempS3Key;
        if (no_of_pages.length === checked_pages.length) {
            tempS3Key = selectedBill['s3_key']
        }
        else {
            enqueueSnackbar('Creating file for selected pages', { variant: 'info' })
            let body = {
                s3_key: selectedBill['s3_key'],
                page_nums_array: checked_pages
            }
            let res = await splitBills(body)
            tempS3Key = res['fname']
        }
        return tempS3Key
    }

    async function reportBill(values, formikHelpers) {
        closeMailFormDialog()
        setLoading(true)
        const { setErrors, setFieldValue } = formikHelpers
        let body = {
            ...values,
            type: Number(values['type']),
            timestamp: selectedBill['timestamp'],
            email_table_id: selectedBill['email_table_id'],
            timestamp: selectedBill['timestamp']
        }
        enqueueSnackbar(`Mail sending......`, { variant: 'info' })
        let response = await send_mail(body)
        let mailData = response['data']

        if (response['status'] === 200) {
            let body = {
                mailData,
                action_performedby,
                checked_pages,
                bill_id
            }
            await update_mail_body(body)
            let { data, status } = await update_bill_status({ ...defaultPayload, status: Bill_Process_Status.FAILED_BY_USER })
            enqueueSnackbar(`${status}, ${data}`, { variant: status === 200 ? 'info' : 'error' })
            if (checkAllPagesEffected()) {
                updateBillIndex()
            }
            updateBlockedPages()
        }
        else {
            enqueueSnackbar(`Mail sending failed`, { variant: 'info' })
        }

        setLoading(false)
    }

    async function inActiveBill() {
        if (await confirm("Are your sure move this selected pages to in active bills.")) {
            setLoading(true)
            let { data, status } = await update_bill_status({ ...defaultPayload, status: Bill_Process_Status.MARK_AS_INACTIVE })
            enqueueSnackbar(`${status}, ${data}`, { variant: status === 200 ? 'info' : 'error' })
            if (checkAllPagesEffected()) {
                updateBillIndex()
            }
            updateBlockedPages()
            setLoading(false)
        }
    }

    async function duplicateBill(setSubmitting = () => { }) {
        setSubmitting(true)
        let { data, status } = await update_bill_status({ ...defaultPayload, status: Bill_Process_Status.DUPLICATE })
        enqueueSnackbar(`${status}, ${data}`, { variant: status === 200 ? 'info' : 'error' })
        if (checkAllPagesEffected()) {
            updateBillIndex()
        }
        updateBlockedPages()
        setSubmitting(false)
    }

    async function removeBill() {
        setLoading(true)
        let changeStatus = bill_status ===
            Bill_Process_Status.TO_BE_POSTED ?
            Bill_Process_Status.POSTED :
            Bill_Process_Status.DUPLICATE

        await remove_bill(bill_id, changeStatus)
        enqueueSnackbar('Removed please refresh the page', { variant: 'info' })
        setLoading(false)
        updateBillIndex()
    }


    async function downloadBill() {
        setLoading(true)
        let tempS3Key = await getS3Key()
        let uri = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${tempS3Key}`
        const response = await fetch(uri);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = uri.split("/").pop();

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        setLoading(false)
    }

    async function addToQueue() {
        setLoading(true)
        let payload = {
            ...page_properties,
            no_of_pages,
            action_performedby,
            "bill": selectedBill,
        }
        let res = await SplitAndSendToQueue(payload)
        let status = res['status']
        let message = res['message']
        if (status === 200) {
            let pages = res['pages'] || []
            if (checkAllPagesEffected(pages)) {
                updateBillIndex()
            }
            updateSplitPages(pages)
        }
        enqueueSnackbar(message, { variant: status === 500 ? 'error' : status === 200 ? 'info' : 'warning' })
        setLoading(false)
    }

    async function submitForm(values, formikHelpers) {
        try {
            const { setFieldValue } = formikHelpers
            const { transactionType } = values
            const { email_table_id, file_table_id } = selectedBill

            let confirm = confirmByUser(values, selectedBill)

            if (values['errorDialog']['open']) {
                closeErrorDialog(setFieldValue);
            }

            if (confirm) {
                let endpoint = getEndpoint(transactionType)
                console.table("final form", values)
                let payload = fetchPayLoad(values, generatedS3Key, enqueueSnackbar)
                if (payload) {
                    const { data, ...rest } = await sendToNimbleAPI(endpoint, payload, client)
                    // We are getting response as array from nimble
                    let response = Array.isArray(data) ? data[0] : data
                    let message = `Nimble API | ${response['message']}`
                    let status = response['status']

                    switch (status) {
                        case 100: // Saved successfully.
                            let res_id = response['id']
                            enqueueSnackbar(message, { variant: 'info' })
                            await handleSuccessResponse(res_id, payload, values)
                            if (transactionType === 0) { //Only calculate in bill entry
                                if (bill_status === Bill_Process_Status.TO_BE_POSTED) {
                                    let changes = track_changes(values)
                                    await update_track_changes(
                                        {
                                            ...changes,
                                            bill_id,
                                            res_id,
                                            email_table_id,
                                            file_table_id
                                        }
                                    )
                                }
                                setFieldValue('transactionType', 1);
                            }
                            // else {
                            //     if (checkAllPagesEffected()) {
                            //         updateBillIndex()
                            //     }
                            //     else {
                            //         dispatch(updateOpenReviewForm(false))
                            //     }
                            // }
                            if (values['IsApprove']) {
                                setFieldValue('IsApprove', false)
                            }

                            break;
                        case 402: // Validation error. You can get validation text in message.
                        case 403: // API exception. Which is from catch block.
                            enqueueSnackbar(message, { variant: 'error' })
                            break;
                        case 404: // Another bill with same invoice number without attachment.
                        case 405: // Entry exists with same date and amount
                        case 406: // Another bill with same invoice number with attachment.
                            setFieldValue('errorDialog', { open: true, status, message, BillID: response['id'] })
                            break
                    }
                }
            }

            if (values['IsValidateBill']) {
                setFieldValue('IsValidateBill', false)
            }
        }
        catch (error) {
            enqueueSnackbar(error, { variant: 'error' })
            console.info(error)
        }
    }

    async function handleSuccessResponse(res_id, payload, values = {}) {
        const { transactionType, vendor, property } = values
        const { client, timestamp, email_table_id, file_table_id } = selectedBill

        let body = {
            bill_id,
            res_id,
            email_table_id,
            file_table_id,
            checked_pages,
            action_performedby,
            "payload": {
                ...payload,
                client,
                "Path1": generatedS3Key,
                "Transac_type": transactionType,
                "vendor": vendor['name'],
                "property": property['name'],
                "createdBy": user['username'],
                "corporationName": property['corporationName'],
                "received_time": timestamp
            },
        }

        await update_verified_table(body)

        if (checked_pages.length !== 0 || no_of_pages.length === 0) {
            let { data, status } = await update_bill_status({ ...defaultPayload, status: Bill_Process_Status.POSTED })
            enqueueSnackbar(`${status}, ${data}`, { variant: status === 200 ? 'info' : 'error' })
        }
        if (no_of_pages.length !== 0) {
            updateBlockedPages()
        }
    }

    function track_changes(values = {}) {
        const { vendor: { name, address1 } } = values //Form
        const { vendor_address, vendor_name } = invoiceDetails //Modal

        let isUtilityBill = (
            values['invoice_date'].replace(/\//g, "") === values['invoice_number'] ||
            moment().format('MMDDYYYY') === values['invoice_number']
        )

        let overall_accuracy = {
            vendor_name: getBestMatch(name, vendor_name),
            vendor_address: getBestMatch(address1, vendor_address),
            invoice_amount: compareStrings(values['invoice_amount']?.toString(), invoice_amount?.toString()),
            invoice_date: compareStrings(values['invoice_date'], invoice_date),
            invoice_number: isUtilityBill ? 100 : compareStrings(values['invoice_number'], invoiceDetails['invoice_number']),
            due_date: getBestDueDateMatch(values),
        }

        let data = {
            overall_accuracy,
            changes: [
                {
                    key: "vendor_name",
                    text: name,
                    modal: vendor_name,
                    accuracy: overall_accuracy['vendor_name']
                },
                {
                    key: "vendor_address",
                    text: address1,
                    modal: vendor_address,
                    accuracy: overall_accuracy['vendor_address']
                },
                {
                    key: "invoice_amount",
                    text: values['invoice_amount']?.toString(),
                    modal: invoice_amount?.toString(),
                    accuracy: overall_accuracy['invoice_amount']
                },
                {
                    key: "invoice_date",
                    text: values['invoice_date'],
                    modal: invoice_date,
                    accuracy: overall_accuracy['invoice_date']
                },
                {
                    key: "invoice_number",
                    text: values['invoice_number'],
                    modal: invoiceDetails['invoice_number'],
                    accuracy: overall_accuracy['invoice_number']
                },
                {
                    key: "due_date",
                    text: values['due_date'],
                    modal: payment_due_date,
                    accuracy: overall_accuracy['due_date']
                }
            ]
        }

        function getBestDueDateMatch(values) {
            if (payment_due_date === "") {
                return 0
            }
            if (/^[a-zA-Z]+$/.test(payment_due_date)) {
                return 100
            }
            let matchWithInvoiceDate = compareStrings(values['due_date'], invoice_date)
            let matchWithDueDate = compareStrings(values['due_date'], payment_due_date)
            return matchWithInvoiceDate > matchWithDueDate ? matchWithInvoiceDate : matchWithDueDate
        }

        return data
    }

    async function attachBillToExist(values = {}, setSubmitting = () => { }) {
        const { property, errorDialog: { BillID } } = values
        let CorporationID = property['corporationID']
        setSubmitting(true)
        let body = {
            "Domain": client.url,
            "AttachmentInfo":
                [{
                    BillID,
                    "CorporationID": CorporationID.replace("0x", ""),
                    "Path": decodeURI(generatedS3Key)
                }],
        };
        let data = await addBillToAttachment(body, client)
        // We are getting response as array from nimble
        let response = Array.isArray(data) ? data[0] : data
        let message = `Nimble API | ${response['message']}`
        let status = response['status']

        if (status === 100) {
            let payload = {
                ...body,
                CorporationID,
            }
            await handleSuccessResponse(response['id'], payload, values)
            if (checkAllPagesEffected()) {
                updateBillIndex()
            }
            else {
                dispatch(updateOpenReviewForm(false))
            }
        }
        enqueueSnackbar(`${status}, ${message}`, { variant: status === 100 ? 'info' : 'error' })
        setSubmitting(false)
    }

    async function updateBlockedPages() {
        let tempPageProperties = { ...page_properties }
        tempPageProperties['blocked_pages'] = [...blocked_pages, ...checked_pages]
        tempPageProperties['checked_pages'] = []
        dispatch(updatePageProperties(tempPageProperties))
    }

    async function updateSplitPages(newSplitPages = []) {
        let tempPageProperties = { ...page_properties }
        tempPageProperties['split_pages'] = [...split_pages, ...newSplitPages]
        tempPageProperties['checked_pages'] = []
        dispatch(updatePageProperties(tempPageProperties))
    }

    function disabledBtnGroup() {
        if (PDFErrorOccur) {
            return false
        }
        return checked_pages.length === 0
    }

    function checkAllPagesModified() {
        if (no_of_pages.length === 0) {
            return false
        }
        let all_pages = new Set([...blocked_pages, ...invoice_pages, ...split_pages])
        all_pages = Array.from(all_pages)
        return all_pages.length === no_of_pages.length
    }

    function checkAllPagesEffected(pages = blocked_pages) {
        let all_pages = new Set([...pages, ...invoice_pages, ...split_pages, ...checked_pages])
        all_pages = Array.from(all_pages)
        return all_pages.length === no_of_pages.length
    }

    return (
        <>
            <Box height={'100%'} border={1} borderColor={'divider'}>
                <Zoom in={!openForm} unmountOnExit>
                    <Stack height={'100%'} justifyContent={'center'} alignItems={'center'} gap={3}>
                        <Box sx={{ opacity: 0.4, userSelect: 'none' }} onDragStart={(e) => e.preventDefault()} component={"img"} src={logo} width={"40%"} />
                        {checkAllPagesModified() ?
                            <Button onClick={removeBill} variant='contained' color='error' startIcon={<Clear />}>Duplicate</Button>
                            :
                            <>
                                <ButtonGroup color='nav' disabled={disabledBtnGroup()} >
                                    <Button onClick={openFormHandler} startIcon={<Visibility color='nav' />}>
                                        {bill_status === 0 ? "Open Form" : "Post Manually"}
                                    </Button>
                                    <Button onClick={inActiveBill} startIcon={<Delete color='warning' />} >Not A Bill</Button>
                                    <Button onClick={() => setOpenMailForm(true)} startIcon={<ReportProblem color='error' />}>Report</Button>
                                </ButtonGroup>

                                <ButtonGroup
                                    color='nav'
                                    orientation={'vertical'}
                                    disabled={disabledBtnGroup()}
                                >
                                    {![Bill_Process_Status.OCR_FAILED, Bill_Process_Status.TO_BE_POSTED].includes(bill_status) &&
                                        <Button disabled={checked_pages.length === 8} onClick={addToQueue} startIcon={<Visibility color='add' />}>Add to Queue</Button>
                                    }

                                    <Button onClick={downloadBill} startIcon={<Download />}>Bill</Button>
                                </ButtonGroup>
                            </>
                        }
                    </Stack>
                </Zoom>
                {openForm &&
                    <Box height={'100%'}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={submitForm}
                            validateOnMount={true}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validationSchema={validationSchema}
                        >
                            {({ handleSubmit, values, isSubmitting, setFieldValue, setSubmitting, errors }) =>
                                <Stack component={Form} onSubmit={handleSubmit} height={'100%'} width={'100%'} overflow={'auto'} alignItems={'center'}>
                                    {console.table(errors)}
                                    <ReviewForm invoiceDetails={invoiceDetails} />
                                    <ButtonGroup sx={{ pb: 1 }} color='nav' disabled={!generatedS3Key}>
                                        {values['transactionType'] === 0 &&
                                            <Button type='submit' startIcon={<Send color='nav' />}>Send</Button>
                                        }
                                        <Button
                                            disabled={values['transactionType'] === 1 ? values['selectedBillsToPay'].length === 0 : false}
                                            type='submit'
                                            onClick={() => setFieldValue('IsApprove', true)}
                                            startIcon={<ThumbUp color='primary' />}
                                        >
                                            {values['transactionType'] === 0 ? 'Approve' : 'Pay'}
                                        </Button>
                                    </ButtonGroup>

                                    <Dialog
                                        maxWidth='sm'
                                        fullWidth
                                        open={values['errorDialog']['open']}
                                        onClose={() => closeErrorDialog(setFieldValue)}
                                        TransitionComponent={Transition}
                                    >
                                        <Form onSubmit={handleSubmit}>
                                            <DialogContent>
                                                <DialogContentText>
                                                    {values['errorDialog']['message']}
                                                </DialogContentText>
                                            </DialogContent>

                                            <DialogActions>
                                                <Button size='small' color='success' onClick={async () => { closeErrorDialog(setFieldValue); attachBillToExist(values, setSubmitting); }}>Attach to exists</Button>
                                                {values['errorDialog']['status'] === 405 &&
                                                    <Button size='small' color='nav' type='submit' onClick={() => { setFieldValue('IsValidateBill', true) }}>Send as New</Button>
                                                }
                                                <Button size='small' color='warning' onClick={async () => { closeErrorDialog(setFieldValue); duplicateBill(setSubmitting); }}>duplicate</Button>
                                            </DialogActions>
                                        </Form>
                                    </Dialog>

                                    <Backdrop open={isSubmitting} sx={{ zIndex: zIndex.appBar + 1 }}>
                                        <SuspenseLoading loading />
                                    </Backdrop>
                                </Stack>
                            }
                        </Formik>
                    </Box>
                }

            </Box >

            <Dialog
                fullWidth
                maxWidth={'sm'}
                open={openMailForm}
                TransitionComponent={Transition}
                onClose={closeMailFormDialog}
            >
                <DialogTitle>Send</DialogTitle>
                <Formik
                    initialValues={mailFormInitialValues}
                    onSubmit={reportBill}
                    validateOnMount={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validationSchema={mailFormSchema}
                >
                    {({ handleSubmit, values, isSubmitting, setFieldValue, touched, handleChange, errors }) =>
                        <Form style={{ height: '100%' }} onSubmit={handleSubmit}>
                            <DialogContent>
                                <Stack gap={1} alignItems={'center'} width={'100%'}>

                                    {['to', 'cc'].map((item, itemIndex) =>
                                        <Autocomplete
                                            fullWidth
                                            key={itemIndex}
                                            multiple
                                            freeSolo
                                            size="small"
                                            value={values[item]}
                                            options={[]}
                                            onChange={(e, v) => setFieldValue(item, v)}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    return (
                                                        <Chip
                                                            color={errors[item]?.[index] ? 'error' : 'default'}
                                                            key={index}
                                                            variant="outlined"
                                                            label={option}
                                                            size="small"
                                                            {...getTagProps({ index })}
                                                        />
                                                    )
                                                })
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={item.toUpperCase()}
                                                    margin="dense"
                                                    error={Boolean(errors[item])}
                                                    helperText={errors[item] ? errors[item] : 'Press the Enter after inputting a value.'}
                                                />
                                            )}
                                        />
                                    )}

                                    <FormControl error={Boolean(errors['type'])} fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                        <FormLabel>Subject:</FormLabel>
                                        <RadioGroup value={values['type']} row name='type' onChange={handleChange}>
                                            <FormControlLabel value={0} control={<Radio />} label="Without attachment" />
                                            <FormControlLabel value={1} control={<Radio />} label="Failed" />
                                        </RadioGroup>
                                    </FormControl>

                                    <TextField
                                        label="Description"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        margin="dense"
                                        name='description'
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <Backdrop in={isSubmitting} sx={{ zIndex: zIndex.appBar + 1 }}>
                                    <SuspenseLoading loading />
                                </Backdrop>

                            </DialogContent>

                            <DialogActions>
                                <Button color="error" onClick={closeMailFormDialog}>Cancel</Button>
                                <Button color='info' type='submit '>Send</Button>
                            </DialogActions>
                        </Form>
                    }
                </Formik>
            </Dialog>

            <Backdrop open={loading} sx={{ zIndex: zIndex.appBar + 1 }}>
                <SuspenseLoading loading />
            </Backdrop>
        </>
    )
}

export default ReviewPanel