import React, { useState } from 'react';

import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Stack
} from '@mui/material';
import { CustomInput } from '../Login';
import OtpInput from 'react-otp-input';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiCheckMail, apiCheckOTP, apiPassword } from '../../../API/API';

const steps = ['Email Check', 'OTP Check', 'Reset Password'];

export default function ForgotForm({ setstates }) {
    const [activeStep, setActiveStep] = useState(0);
    const [statusState, setStatusStates] = useState({ email: false, otp: false, reset: false })
    const [emailField, setEmailField] = useState({ email: '', error: false, helperText: '' })
    const [passwordField, setPasswordField] = useState({ password: '', reEnterPassword: '', error: false, helperText: null })
    const [otpField, setOtpField] = useState({ error: false, helperText: null })
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)

    const handleNext = () => {
        if (activeStep === 0) {
            checkEmail()
        }
        else if (activeStep === 1) {
            checkotp()
        }
        else if (activeStep === 2) {
            resetPassword()
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setStatusStates({ email: false, otp: false, reset: false })
            setstates(prev => ({ ...prev, forgotPassword: false }))
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }

    };

    async function checkEmail() {
        let email = emailField.email
        if (statusState.email === false) {
            if ((/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)) {
                setLoading(true)
                let data = await apiCheckMail(email)
                if (data.status === 200) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setStatusStates(prev => ({ ...prev, email: true }))
                }
                else {
                    setEmailField(prev => ({ ...prev, error: true, helperText: data.message }))
                }
                setLoading(false)
            }
            else {
                setEmailField(prev => ({ ...prev, error: true, helperText: 'Enter valid mail' }))
            }
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    async function checkotp() {
        if (!statusState.otp) {
            let body = {
                email: emailField.email,
                otp: otp,
            };
            setLoading(true)
            let data = await apiCheckOTP(body)
            // let data = res.data
            if (data.status === 100) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setStatusStates(prev => ({ ...prev, otp: true }))
            }
            else if (data.status === 403) {
                setOtpField(prev => ({ ...prev, error: true, helperText: data.message }))
            }
            else {
                alert('Error', data.message)
            }
            setLoading(false)
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    }

    async function resetPassword() {
        let password = passwordField.password
        let rePassword = passwordField.reEnterPassword

        if (password === rePassword) {
            if (!(/^\s*$/).test(password) && !(/^\s*$/).test(rePassword)) {
                let body = {
                    email: emailField.email,
                    password: password,
                };
                setLoading(true)
                let res = await apiPassword(body)
                if (res.data.status === 100) {
                    setstates(prev => ({ ...prev, forgotPassword: false }))
                }
                setLoading(false)
            }
            else {
                setPasswordField(prev => ({ ...prev, error: true, helperText: 'Invalid password' }))
            }
        }
        else {
            setPasswordField(prev => ({ ...prev, error: true, helperText: 'Password did not match' }))
        }
    }

    return (
        <Stack sx={{ width: '100%', height: "100%", pt: 2, }} gap={2} justifyContent={'space-between'} >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) =>
                    <Step key={label + index} >
                        <StepLabel >{label}</StepLabel>
                    </Step>
                )}
            </Stepper>

            <Stack pl={5} pr={5} gap={2}>
                {activeStep === 0 &&
                    <CustomInput
                        error={emailField.error}
                        helperText={emailField.helperText}
                        onClick={() => setEmailField(prev => ({ ...prev, error: false, helperText: null }))}
                        placeholder="jondoe@gmail.com"
                        name="email"
                        onChange={(e) => {
                            setEmailField(prev => ({ ...prev, email: e.target.value }));
                            if (statusState.email) { setStatusStates(prev => ({ ...prev, email: false })) }
                        }}
                        value={emailField.email}
                    />
                }
                {activeStep === 1 &&
                    <>
                        <Typography variant='h5' color={"#18345E"} component={'label'} >OTP</Typography>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) =>
                                <input
                                    onClick={() => {
                                        setOtpField(prev => ({ ...prev, error: false, helperText: null }))
                                        if (otpField.error) { setOtp('') }
                                    }}
                                    {...props}
                                />
                            }
                            inputStyle={{ height: '2.5em', width: '2.5em', border: otpField.error ? '1px solid red' : `1px solid #18345E` }}
                            inputType='tel'
                        />
                        <Typography variant='caption'>{otpField.helperText}</Typography>
                    </>
                }
                {activeStep === 2 &&
                    <>
                        <CustomInput
                            error={passwordField.error}
                            helperText={passwordField.helperText}
                            onClick={() => setPasswordField(prev => ({ ...prev, error: false, helperText: null }))}
                            placeholder="*****************"
                            name="password"
                            onChange={(e) => setPasswordField(prev => ({ ...prev, password: e.target.value }))}
                            type='password'
                        />
                        <CustomInput
                            error={passwordField.error}
                            helperText={passwordField.helperText}
                            onClick={() => setPasswordField(prev => ({ ...prev, error: false, helperText: null }))}
                            placeholder="*****************"
                            name="re-password"
                            onChange={(e) => setPasswordField(prev => ({ ...prev, reEnterPassword: e.target.value }))}
                            type='password'
                        />
                    </>
                }
            </Stack>

            <Stack pl={5} pr={5} pb={5} direction={'row'} justifyContent={'space-between'}>
                <Button color="inherit" onClick={handleBack} disabled={activeStep === steps.length - 1}>
                    Back
                </Button>

                <LoadingButton loading={loading} variant={loading ? 'outlined' : 'text'} onClick={handleNext} >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </LoadingButton>
            </Stack>

        </Stack >
    );
}