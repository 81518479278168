export function requestSearch(
    event,
    data = [],
    keys = []
) {

    function string(str) {
        return str?.toString().toLowerCase().replace(/\s/g, "")
    }

    const query = event.target.value;
    const filteredQuery = string(query)

    let final_keys = keys.length > 0 ? keys : Object.keys(data[0])

    const filterData = data.filter(i => final_keys.some(key => {
        let value = Array.isArray(i[key]) ? i[key][0] : i[key]
        value = string(value)
        return value?.includes(filteredQuery)
    }))

    return { query, filterData }
}