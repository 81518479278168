import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CustomTable from "../../Components/CustomTable/CustomTable";

import {
    IconButton,
    Toolbar,
    TextField,
    Box,
    Stack,
    Button,
    Paper,
    Dialog,
    DialogContent,
    AppBar,
    Typography,
} from "@mui/material";

import { Clear, RemoveRedEye, } from "@mui/icons-material";

import { requestSearch } from "../../Tools/searchTool";
import { Transition } from '../../Components/Transition';

import CreateUser from './CreateUser';

export default function Users() {

    const [usersList, setUsersList] = useState([])
    const [sourceUsersList, setSourceUsersList] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(true)

    const [openRegisterDialog, setOpenRegisterDialog] = useState(false)

    useEffect(() => {
        fetchUsersList()
    }, [])

    const flexgrow = 1

    const tableColumns = [
        {

            'key': 'username',
            'label': 'Username',
            flexgrow: flexgrow,
        },
        {
            'key': 'email',
            'label': 'Email',
            flexgrow: flexgrow,
        },
        {
            key: 'employee_id',
            label: 'Employee Id',
            flexgrow: flexgrow,
        }
    ]

    async function fetchUsersList() {
        let res = await axios.get('/api/getUsersList')
        let tempUsersList = res.data
        setUsersList(tempUsersList)
        setSourceUsersList(tempUsersList)
        setLoading(false)
    }

    function searchFuntion(e) {
        let keysToSearch = tableColumns.map(i => i.key)
        let result = requestSearch(e, sourceUsersList, keysToSearch)
        setSearchQuery(result.query)
        setUsersList(result.filterData)
    }

    function closeRegisterDialog() {
        setOpenRegisterDialog(false)
    }

    return (
        <>
            <Paper sx={{ height: '100%', width: '100%', p: 1 }}>
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>

                    <Button variant='contained' onClick={() => setOpenRegisterDialog(true)} color='nav' sx={{ flexgrow: 1 }}>
                        Create user
                    </Button>

                    <TextField
                        size='small'
                        placeholder='Search'
                        value={searchQuery}
                        onChange={searchFuntion}
                        disabled={usersList.length === 0}
                    />
                </Toolbar>

                <Box height={'calc(100% - 64px)'} width={'100%'}>
                    <CustomTable
                        data={usersList}
                        columns={tableColumns}
                        action
                        ActionComponent={(rowData) => {
                            let index = sourceUsersList.findIndex(i => i['_id'] === rowData['_id'])
                            return (
                                <IconButton disabled>
                                    <RemoveRedEye />
                                </IconButton>
                            )
                        }}
                        searchQuery={searchQuery}
                        loader={loading}
                    />
                </Box>
            </Paper>

            <Dialog fullScreen TransitionComponent={Transition} open={openRegisterDialog}>
                <AppBar position='static' color='nav'>
                    <Toolbar variant='dense'>
                        <Typography variant='h6' flexGrow={1}>Create User</Typography>
                        <IconButton color='inherit' onClick={closeRegisterDialog} >
                            <Clear />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ p: 1, backgroundColor: '#f8f8ff' }}>
                    <CreateUser callBack={closeRegisterDialog} />
                </DialogContent>
            </Dialog>
        </>
    )
}