import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom'
import UserDashboard from './UserDashboard/UserDashboard'
import AdminDashboard from './AdminDashboard/AdminDashboard'
import { IconButton, Snackbar } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { fetchAccessPermission } from '../../API/API'
import { SuspenseLoading } from '../../Components/SuspenseLoading'
import { useDispatch } from 'react-redux'
import { updateClient, updateClientsList, updateCpAccessID, updateMicroServices, updatePropertiesList, updateProperty } from '../../Redux/actions'

const Home = () => {

    const user = JSON.parse(sessionStorage.getItem('user'))
    const location = useLocation()
    const state = location['state']
    const alertRef = useRef()

    const [loading, setIsLoading] = useState({ open: false, text: 'Fetching Services....' })

    const [data, setData] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (state) {
            alertRef.current = state['open']
        }
        dispatch(updateProperty(null))
        // dispatch(updateClient(null))
        dispatch(updateCpAccessID(null))

        dispatch(updatePropertiesList(null))
        dispatch(updateClientsList(null))

        let access_id = state?.['access_id'] ? state['access_id'] : user?.['AccessID']
        if (access_id) {
            fetAccessPermissions(access_id)
        }
        else {
            let text = 'Session is clear please log in again'
            setIsLoading({ open: false, text: text })
        }
    }, [])

    async function fetAccessPermissions(access_id) {
        setIsLoading({ open: true, text: '' })
        let tempData = await fetchAccessPermission(access_id)
        dispatch(updateMicroServices(tempData))
        setData(tempData)
        let text = tempData['micro_services']?.length === 0 ? 'Session is clear please log in again' : ''
        setIsLoading({ open: false, text: text })
    }

    function EntryDashboard() {
        let entryPoint = data['service']



        let tempComponents = {
            dashboard: UserDashboard,
            admin_dashboard: AdminDashboard
        }

        if (entryPoint) {
            const Component = tempComponents[entryPoint['service_name']]
            return <Component microServices={data['micro_services']} />
        }
        return <SuspenseLoading loading={loading['open']} NotFound={loading['text']} />
    }

    return (
        <>
            {/* <Snackbar
                open={alertRef.current}
                autoHideDuration={6000}
                onClose={() => alertRef.current = false}
                message={`This Micro-Service '${state?.['name']}' is not available for this user`}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            /> */}
            <EntryDashboard />
        </>)
}

export default Home