import { useState, useEffect, useRef } from "react";
import {
    Box,
    Paper,
    Toolbar,
    TextField,
    Dialog,
    IconButton,
} from "@mui/material";

import { RemoveRedEye } from "@mui/icons-material";

import { useSelector, useDispatch } from "react-redux";
import { requestSearch } from "../../Tools/searchTool";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { Transition } from "../../Components/Transition";
import { failedBills, getBills } from "../../API/API";
import { updateOpenReviewForm, updatePageProperties } from "../../Redux/actions";
import { formattedTimeStamp, getTimeStamp } from "../../Tools/formattedTimeStamp";
import { s3ToFileName } from "../../Tools/s3ToFileName";
import ReviewPage from "../../Components/ReviewPage/ReviewPage";
import { Bill_Process_Status } from "../../Tools/constants";

export default function NeedAttention() {
    const dispatch = useDispatch()
    // const cp_access_id = decryptData('cp_access_id')
    const cp_access_id = useSelector(state => state.SNAP_PERSIST_STORE.cp_access_id)
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const [tableData, setTableData] = useState([]);
    const [editableTableData, setEditableTableData] = useState([]);

    const [isLoading, setLoading] = useState(false)
    const [selectedBillIndex, setSelectedBillIndex] = useState(-1)
    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        fetchFailedBills()
    }, [selectedProperty, selectedClient]);

    async function fetchFailedBills() {
        setEditableTableData([])
        if ((selectedClient && selectedProperty) || selectedClient['url'] === "all") {
            setLoading(true)
            let body = {
                client_url: selectedClient['url'],
                property_id: selectedProperty,
                cp_access_id: cp_access_id,
                status: [
                    Bill_Process_Status.EXTRACTION_FAILED,
                    Bill_Process_Status.OCR_FAILED,
                    Bill_Process_Status.INVALID_FILE,
                    Bill_Process_Status.WITH_OUT_ATTACHMENT,
                    Bill_Process_Status.PROCESS_Failed_File,
                ]
            }
            let bills = await getBills(body)
            bills.forEach((i) => {
                i['file_name'] = s3ToFileName(i['s3_key'])
                i['formatted_timestamp'] = getTimeStamp(i['timestamp'], 'Asia/Kolkata')
                let status = i.bill_status;
                switch (status) {
                    case -7:
                        i.bill_type = 'INVALID_FILE';
                        break;
                    case -3:
                        i.bill_type = 'Extraction Failed';
                        break;
                    case -5:
                        i.bill_type = 'WITH_OUT_ATTACHMENT';
                        break;
                    case -6:
                        i.bill_type = 'OCR_FAILED';
                        break;
                    case -8:
                        i.bill_type = 'PROCESS_Failed_File';
                        break;
                }
            });
            setTableData(bills);
            setEditableTableData(bills);
            setLoading(false)
        }
    }

    function searchFuntion(e) {
        let keysToSearch = tableColumns.map(i => i.key)
        let result = requestSearch(e, tableData, keysToSearch)
        setSearchQuery(result.query)
        setEditableTableData(result.filterData)
    }

    const tableColumns = [
        {
            'key': 'from_Address',
            'label': 'From Address',
            'flexgrow': 1
        },
        {
            'key': 'file_name',
            'label': 'File Name',
            'flexgrow': 1,
        },
        {
            'key': 'formatted_timestamp',
            'label': 'Timestamp',
            'flexgrow': 1
        },
        {
            'key': 'corporation_name',
            'label': 'Property',
            'flexgrow': 1,
        },
        {
            'key': 'bill_type',
            'label': 'Bill Status',
            'flexgrow': 0.5
        },
    ]

    function closeReviewPanel() {
        setSearchQuery('')
        setSelectedBillIndex(-1)
        dispatch(updatePageProperties({
            blocked_pages: [],
            split_pages: [],
            invoice_pages: [],
            checked_pages: []
        }))
        fetchFailedBills()
        dispatch(updateOpenReviewForm(false))
    }

    function updateBills(id) {
        let tempData = [...tableData]
        let index = tempData.findIndex(i => i['_id'] === id)
        if (index !== -1) {
            tempData.splice(index, 1);
            setTableData(tempData)
        }
    }

    return (
        <>
            <Paper sx={{ height: '100%', p: 2 }}>
                <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
                    <TextField size='small' placeholder='Search' value={searchQuery} onChange={(e) => searchFuntion(e)} />
                </Toolbar>
                <Box sx={{ height: 'calc(100% - 64px)' }}>
                    <CustomTable
                        data={editableTableData}
                        columns={tableColumns}
                        action
                        ActionComponent={(rowData) => {
                            let index = tableData.findIndex(i => i['_id'] === rowData['_id'])
                            return (
                                <IconButton onClick={(e) => setSelectedBillIndex(index)}>
                                    <RemoveRedEye />
                                </IconButton>
                            )
                        }}
                        searchQuery={searchQuery}
                        loader={isLoading}
                        isProperty
                    />
                </Box>
            </Paper>

            <Dialog open={selectedBillIndex !== -1} onClose={closeReviewPanel} fullScreen TransitionComponent={Transition}>
                <ReviewPage
                    bills={tableData}
                    initialBillIndex={selectedBillIndex}
                    closeReviewPanel={closeReviewPanel}
                    updateBills={updateBills}
                />
            </Dialog>
        </>
    )
}