import React from 'react';
import { Box } from '@mui/material';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

export const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 32,
    });
    return (
        <Box {...props} ref={ref}>
            <AutoSizer>
                {({ width, height }) =>
                    <List
                        height={height}
                        width={width}
                        deferredMeasurementCache={cache}
                        rowHeight={cache.rowHeight}
                        overscanRowCount={10}
                        rowCount={itemCount}
                        rowRenderer={({ index, key, parent, style }) => {
                            return (
                                <CellMeasurer
                                    key={key}
                                    cache={cache}
                                    parent={parent}
                                    columnIndex={0}
                                    rowIndex={index}
                                >
                                    {({ registerChild }) =>
                                        <Box ref={registerChild} style={{ ...style }} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            {React.cloneElement(children[index])}
                                        </Box>
                                    }
                                </CellMeasurer>
                            );
                        }}
                    />
                }
            </AutoSizer>
        </Box>
    );
});