const Yup = require('yup')

export const validationSchema = Yup.object().shape({
    // property: Yup.object().nullable().required("Property is required"),
    // vendor: Yup.object().nullable().required("Vendor is required"),
    // contract: Yup.object().optional(),
    // payment_type: Yup.object().required('Payment type is required'),

    // transactionType: Yup.number()
    //     .nullable()
    //     .typeError('Please select the transaction type')
    //     .required('Please select the transaction type'),

    // invoice_number: Yup.string().required('Please enter invoice number'),

    // invoice_date: Yup.string()
    //     .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date format. Please use MM/DD/YYYY format')
    //     .required('Please enter the invoice date'),

    due_date: Yup.string().when('transactionType', {
        is: (transactionType) => transactionType === 0,
        then: () => Yup.string()
            .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date format. Please use MM/DD/YYYY format')
            .required('Please enter the due date')
            .test('is-after-invoice-date', 'Due date must be after invoice date', function (due_date) {
                const invoice_date = this.parent.invoice_date;
                if (!invoice_date || !due_date) return true; // Skip validation if either date is missing
                const invoiceDateObj = new Date(invoice_date);
                const dueDateObj = new Date(due_date);
                return dueDateObj >= invoiceDateObj;
            }),
    }),

    // books_date: Yup.string().when(['transactionType', 'isBooksDateEnabled'], {
    //     is: (transactionType, isBooksDateEnabled) => (isBooksDateEnabled && transactionType === 0),
    //     then: () => Yup.string()
    //         .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date format. Please use MM/DD/YYYY format')
    //         .required('Please enter the books date'),
    // }),

    // check_number: Yup.number().when(['transactionType', 'payment_type'], {
    //     is: (transactionType, payment_type) => (transactionType === 2 || transactionType === 1 && payment_type?.name?.toLowerCase() === "check"),
    //     then: () => Yup.number()
    //         .nullable()
    //         .typeError('Invalid number')
    //         .required('Please enter the check number')
    //         .test('is-valid-number', 'Invalid number', value => !isNaN(value))
    // }),

    splitDetails: Yup.array().when('transactionType', {
        is: (transactionType) => transactionType !== 1,
        then: () => Yup.array().of(
            Yup.object().shape({
                AccountCode: Yup.string().nullable().required('Account code is required'),
                Amount: Yup.string().matches(/^[0-9.,-]+$/, 'Invalid amount. Only numbers are allowed.').trim().required()
            })
        ).required('Split details are required')
    }),

    // selectedBillsToPay: Yup.array().when('transactionType', {
    //     is: (transactionType) => transactionType === 1,
    //     then: () => Yup.array().min(1, 'Select at least one bill to pay').required('Select the bills to pay')
    // })
});

export const mailFormSchema = Yup.object().shape({
    to: Yup.array().of(Yup.string().email('Invalid email format')).required('At least one email is required').min(1, 'At least one email is required'),
    cc: Yup.array().of(Yup.string().email('Invalid email format')).optional(),
    type: Yup.number().required('Type is required'),
    description: Yup.string().optional()
});