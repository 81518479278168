import React, { useEffect, useRef, useState } from 'react'
import AutoSizer from "react-virtualized-auto-sizer";
import { Table } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import './CustomTable.css'
import _ from 'lodash';
import { Box, Tooltip, IconButton, Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { MoveDown, Refresh } from '@mui/icons-material';

export default function CustomTable({
    data = [],
    columns = [{
        'key': '',
        'label': '',
        'flexgrow': undefined,
    }],
    loader = false,
    groupBy = '',
    searchQuery = '',
    groupHeader = '',
    seriesKey = '',
    action = false,
    ActionComponent = () => { },
    isTree = false,
    isProperty = false,
    rowHeight = 46,
    resizable = false
}) {
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const { Column, HeaderCell, Cell } = Table;

    const [expandRows, setExpandRows] = useState([])

    useEffect(() => {
        if (data.length > 0 && isTree) {
            setExpandRows([...new Set(data.map(i => i[groupBy]))])
        }
    }, [data])

    function fetchColumns() {
        if (columns.length === 1 && data.length > 0) {
            return Object.keys(data[0]).map(key => ({
                'key': key,
                'label': key,
                'flexgrow': 1,
            }))
        }

        try {
            if (selectedClient?.['userID'] !== 'all') {
                let index = columns.findIndex(i => ["client"].includes(i['key']))
                if (index !== -1) {
                    columns.splice(index, 1)
                }
            }

            if (selectedProperty !== "all" && selectedProperty !== "none") {
                let index = columns.findIndex(i => ["corporationName", "corporation_name"].includes(i['key']))
                if (index !== -1) {
                    columns.splice(index, 1)
                }
            }
        }
        catch (err) {
            console.err(err)
        }

        return columns
    }

    function fetchData() {
        if (isTree) {
            if (data.length > 0) {
                let cloneData = _.cloneDeep(data);

                let newArray = cloneData.reduce((acc, curr) => {
                    let existingObject = acc.find(obj => obj['rowKey'] === curr[groupBy]);
                    if (existingObject) {
                        existingObject.children.push(curr);
                    }
                    else {
                        acc.push({
                            rowKey: curr[groupBy],
                            isTreeHead: true,
                            [groupHeader]: curr[groupHeader],
                            children: [curr]
                        });
                    }
                    return acc;
                }, []);

                newArray.forEach((parent, parentIndex) => {
                    parent['#'] = `${parentIndex + 1} . ${parent[groupHeader]}`
                    parent['children'].forEach((children, childrenIndex) => children['#'] = `${parentIndex + 1}.${childrenIndex + 1}.  ${children[seriesKey]}`)
                })

                cloneData = null;
                return newArray
            }
        }
        return data
    }

    function CustomCell({ rowData, tr, searchQuery }) {
        let str = Array.isArray(rowData[tr]) ? (rowData[tr][0] || '-') : (rowData[tr] || '-');
        str = str.toString()
        return (
            <Tooltip disableHoverListener={!isTree} arrow title={str}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} className={rowData.isTreeHead && "table-tree-head"} >
                    {str ? searchQuery ?
                        <span
                            dangerouslySetInnerHTML={{
                                __html: str?.replace(new RegExp(searchQuery, 'gi'), match =>
                                    `<mark>
                                    ${match}
                                </mark>`
                                )
                            }}
                        />
                        :
                        str
                        :
                        '-'
                    }
                </span>
            </Tooltip>
        )
    }

    function CustomEmptyCell({ info }) {
        if (selectedProperty === null && isProperty) {
            return (
                <Box height={'100%'} display={'grid'} sx={{ placeItems: 'center' }}>
                    {!selectedClient ?
                        'Following the client selection, proceed to choose the property.' :
                        'Select the property'
                    }
                </Box>
            )
        }
        else if (searchQuery) {
            return (
                <Box height={'100%'} display={'grid'} sx={{ placeItems: 'center' }}>
                    No data found with "{searchQuery}"
                </Box>
            )
        }
        return info
    }

    function onRowClick(rowData) {
        if (rowData.isTreeHead && isTree) {
            let rowID = rowData['rowKey']
            let tempExpandRows = [...expandRows]
            let filteredExpandRows = tempExpandRows.includes(rowID)
                ? tempExpandRows.filter(i => i !== rowID)
                : [...tempExpandRows, rowID];
            setExpandRows(filteredExpandRows);
        }
    }

    const tableRef = useRef()

    function prevScrollPosition() {
        let ref = tableRef.current
        let scrollPosition = sessionStorage.getItem('scrollPosition')
        if (ref && scrollPosition) {
            scrollPosition = Number(scrollPosition)
            ref.scrollTop(scrollPosition)
            sessionStorage.removeItem('scrollPosition')
        }
    }

    return (
        <AutoSizer>
            {({ height, width }) =>
                <Table
                    virtualized
                    width={width}
                    height={height}
                    data={fetchData()}
                    cellBordered
                    bordered
                    translate3d
                    rowKey={isTree ? 'rowKey' : undefined}
                    isTree={isTree}
                    shouldUpdateScroll={!isTree}
                    loading={loader}
                    expandedRowKeys={isTree ? expandRows : undefined}
                    onRowClick={(rowData) => onRowClick(rowData)}
                    rowClassName={(rowData) => (rowData?.isTreeHead) && 'table-row-rs'}
                    renderEmpty={(info) => <CustomEmptyCell info={info} />}
                    rowHeight={rowData => isTree ? (rowData?.isTreeHead ? 46 : rowHeight) : rowHeight}
                    ref={tableRef}
                >

                    {!isTree &&
                        <Column fixed width={72}>
                            <HeaderCell align='center' style={{ backgroundColor: '#F1F1FB' }} className='table-header-cell-rs'>S.No</HeaderCell>
                            <Cell verticalAlign='center' align='center' children={(_, rowIndex) => rowIndex + 1} />
                        </Column>
                    }

                    {fetchColumns().map((tr, trIndex) => {
                        if (tr.key === 'property') {
                            if (selectedProperty !== 'all') {
                                return
                            }
                        }
                        return (
                            <Column
                                key={trIndex}
                                fixed={tr.key === '#'}
                                colSpan={tr.key === '#' ? fetchColumns().length + 1 : undefined}
                                flexGrow={tr['flexgrow']}
                                // fullText={tr['fullText']}
                                fullText={!isTree}
                                resizable={resizable}
                            >
                                <HeaderCell style={{ backgroundColor: '#F1F1FB' }} className='table-header-cell-rs'>
                                    {tr.label}
                                </HeaderCell>

                                <Cell verticalAlign='center'
                                    children={(rowData, rowIndex) =>
                                        (rowData.isTreeHead && trIndex !== 0) ? null :
                                            <CustomCell
                                                tr={tr.key}
                                                rowData={rowData}
                                                searchQuery={searchQuery}
                                            />
                                    }
                                />
                            </Column>
                        )
                    })}

                    {action &&
                        <Column fixed={'right'} width={72} align={'center'}>
                            <HeaderCell style={{ backgroundColor: '#F1F1FB', position: 'relative' }} className='table-header-cell-rs'>
                                <Tooltip title='Prev position' arrow>
                                    <span>
                                        <IconButton
                                            size='small'
                                            sx={{
                                                position: 'absolute',
                                                top: 2,
                                                right: 2,
                                            }}
                                            onClick={prevScrollPosition}
                                            disabled={loader || data.length === 0}
                                        >
                                            <MoveDown sx={{ fontSize: '16px' }} />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                Action
                            </HeaderCell>

                            <Cell verticalAlign='center'
                                onClick={() => {
                                    let scrollPosition = tableRef.current?.['scrollPosition']?.['top'] || 0
                                    sessionStorage.setItem('scrollPosition', scrollPosition)
                                }}
                                style={{ padding: 0 }}
                                children={(rowData, rowIndex) =>
                                    rowData.isTreeHead ? null :
                                        ActionComponent(rowData) //!Note only return rowData only if isTree 
                                }
                            />
                        </Column>
                    }

                </Table>
            }

        </AutoSizer>

    )
}