import React, { useEffect, useState } from 'react'
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';
import ReactSplit, { SplitDirection } from '@devbookhq/splitter';
import { fetchBillDetails, fetchPageProperties, getEmailBody } from '../../API/API';
import { SuspenseLoading } from '../SuspenseLoading';
import ReviewPanel from './ReviewPanel/ReviewPanel';
import FilePanel from './FilePanel/FilePanel';

import { AppBar, Button, IconButton, Toolbar, Typography, Stack, Chip, Box, Badge, Backdrop } from '@mui/material'
import { Clear, CopyAll } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { updateCheckedPages, updateOpenReviewForm, updatePageProperties } from '../../Redux/actions';
import zIndex from '@mui/material/styles/zIndex';

const ReviewPage = ({
    bills = [],
    initialBillIndex = -1,
    closeReviewPanel = () => { },
    updateBills = () => { }
}) => {

    const dispatch = useDispatch()
    const windowWidth = window.innerWidth;

    const [billIndex, setBillIndex] = useState(initialBillIndex)
    const selectedBill = bills[billIndex]
    const bill_status = selectedBill ? selectedBill['bill_status'] : null
    const title = bill_status === 0 ? 'To be Posted' : 'Need attention'

    const [invoiceDetails, setInvoiceDetails] = useState()
    const [emailBody, setEmailBody] = useState()
    const [pages, setPages] = useState([])
    const [PDFErrorOccur, setPDFErrorOccur] = useState(false)

    useEffect(() => {
        setPDFErrorOccur(false)
        if (bills.length === 0) {
            closeReviewPanel()
        }
        dispatch(updatePageProperties({
            blocked_pages: [],
            split_pages: [],
            invoice_pages: [],
            checked_pages: []
        }))
        dispatch(updateOpenReviewForm(false))
        setInvoiceDetails()
        setPages([])
        setEmailBody()
        if (selectedBill) {
            async function getData() {
                fetchSelectedBillDetails()
                getMailBody()
            }
            getData()
        }
    }, [billIndex, bills])

    async function fetchSelectedBillDetails() {
        let tempInvoiceDetails = {}
        let blocked_pages = []

        let split_pages = []
        let invoice_pages = []
        let checked_pages = []

        if (bill_status === 0) {
            tempInvoiceDetails = await fetchBillDetails(selectedBill['_id']);
            blocked_pages = tempInvoiceDetails['locked_pages'] || []
            checked_pages = tempInvoiceDetails['page_numbers'] || []

            blocked_pages = convertPageNumbers(blocked_pages)
            checked_pages = convertPageNumbers(checked_pages)
        }
        else {
            if (selectedBill['file_table_id']) {
                let tempPageProperties = await fetchPageProperties(selectedBill['file_table_id'])
                blocked_pages = tempPageProperties['locked_pages'] || []
                invoice_pages = tempPageProperties['invoice_pages'] || []
                split_pages = tempPageProperties['splitted_pages'] || []

                blocked_pages = convertPageNumbers(blocked_pages)
                invoice_pages = convertPageNumbers(invoice_pages)
                split_pages = convertPageNumbers(split_pages)
            }
            else {
                enqueueSnackbar('Warning, bill does not have file id', { variant: 'warning' })
            }
        }

        dispatch(updatePageProperties({
            blocked_pages,
            split_pages,
            invoice_pages,
            checked_pages
        }))

        setInvoiceDetails(tempInvoiceDetails)
    }

    async function getMailBody() {
        if (selectedBill.email_table_id) {
            let data = await getEmailBody(selectedBill.email_table_id)
            setEmailBody(data)
        }
        else {
            setEmailBody([])
        }
    }

    function updateBillIndex() {
        updateBills(selectedBill['_id'])
    }

    return (
        <SnackbarProvider maxSnack={2}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <AppBar position='static' color={'nav'}>
                <Toolbar variant="dense" sx={{ gap: 2 }}>
                    <Stack direction={'row'} gap={1} alignItems={'center'} flexGrow={1}>
                        <Typography color='inherit' variant="h5" >{title}</Typography>
                        <Chip
                            variant='outlined'
                            size='small'
                            label={`${bills[billIndex]?.file_name}`}
                            sx={{ color: 'inherit' }}
                        />
                    </Stack>
                    <Controls
                        billIndex={billIndex}
                        setBillIndex={setBillIndex}
                        bills={bills}
                        updateBillIndex={updateBillIndex}
                        no_of_pages={pages}
                    />
                    {/* <Badge
                        overlap='circular'
                        color='warning'
                        badgeContent={bills.length}
                        max={2000}
                    >
                        <IconButton color='inherit' sx={{ mr: 1.5 }} onClick={closeReviewPanel}>
                            <Clear />
                        </IconButton>
                    </Badge> */}
                    <IconButton color='inherit' sx={{ mr: 1.5 }} onClick={closeReviewPanel}>
                        <Clear />
                    </IconButton>
                </Toolbar>
            </AppBar>

            {(invoiceDetails && selectedBill && emailBody) ?
                <ReactSplit
                    direction={SplitDirection.Horizontal}
                    initialSizes={[60, 40]}
                    minWidths={[windowWidth * 0.4, windowWidth * 0.3]}
                >
                    <FilePanel selectedBill={selectedBill} invoiceDetails={invoiceDetails} no_of_pages={pages} updatePages={setPages} emailBody={emailBody} setPDFErrorOccur={setPDFErrorOccur} />
                    <ReviewPanel selectedBill={selectedBill} invoiceDetails={invoiceDetails} no_of_pages={pages} updateBillIndex={updateBillIndex} PDFErrorOccur={PDFErrorOccur} />
                </ReactSplit>
                :
                <SuspenseLoading loading />
            }
        </SnackbarProvider>
    )
}

function Controls({
    billIndex = -1,
    setBillIndex = {},
    bills = [],
    updateBillIndex = () => { },
    no_of_pages = []
}) {
    const page_properties = useSelector(state => state.SNAP_STORE.page_properties) || {}
    const { blocked_pages = [], split_pages = [], invoice_pages = [] } = page_properties
    const selectedBill = bills[billIndex] || {}

    function control(index) {
        setBillIndex(index)
        // if (no_of_pages.length !== 0) {
        //     let all_pages = new Set([...blocked_pages, ...invoice_pages, ...split_pages])
        //     all_pages = Array.from(all_pages)

        //     if (all_pages.length === no_of_pages.length) {
        //         updateBillIndex(selectedBill['_id'])
        //     }
        // }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.code === 'ArrowLeft' && !(billIndex === 0 || billIndex === -1)) {
                    control(billIndex - 1);
                }
                if (event.code === 'ArrowRight' && !(billIndex + 1 === bills?.length || billIndex === -1)) {
                    control(billIndex + 1);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [billIndex, bills, control]);


    return (
        <>
            <Button
                variant='outlined'
                color='inherit'
                disabled={billIndex === 0 || billIndex === -1}
                onClick={() => control(billIndex - 1)}
                sx={{
                    ':hover': { backgroundColor: '#6d7de0', borderColor: '#6d7de0' },
                    '&:disabled': { borderColor: '#6d7de0', color: 'white', opacity: 0.5 }
                }}
            >
                Prev
            </Button>

            <Button
                variant='outlined'
                color='inherit'
                disabled={billIndex + 1 === bills?.length || billIndex === -1}
                onClick={() => control(billIndex + 1)}
                sx={{
                    ':hover': { backgroundColor: '#6d7de0', borderColor: '#6d7de0' },
                    '&:disabled': { borderColor: '#6d7de0', color: 'white', opacity: 0.5 }
                }}
            >
                Next
            </Button>
        </>

    )
}

function convertPageNumbers(pageNumbers = []) {
    if (pageNumbers.length > 0) {
        return pageNumbers
            .map(Number) // Convert elements to numbers
            .filter(page => !isNaN(page)); // Filter out NaN values
    }
    return []
}

export default ReviewPage