import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import {
    Autocomplete,
    Box,
    TextField,
    Tooltip,
    Stack,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    Toolbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogActions,
    Button,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert,
    List,
    ListItem,
    Chip,
    ListItemText,
    ListItemButton,
    ListItemSecondaryAction,
    Paper,
    DialogTitle,
    Divider,
} from '@mui/material'
import { Formik, useFormikContext } from 'formik'
import { SuspenseLoading } from '../../../../SuspenseLoading'
import { Clear, GroupAdd, Refresh } from '@mui/icons-material'
import { Transition } from '../../../../Transition'
import InputMask from "react-input-mask";
import Draggable from 'react-draggable';
import { useSnackbar } from 'notistack';
import { getCountries, getStates, getVECOList, getVendors, saveVendor } from '../../../../../API/NimbleAPI'
import AddVendor from '../AddVendor/AddVendor'

function PaperComponent(props) {
    const draggableRef = useRef(null);
    return (
        <Draggable
            nodeRef={draggableRef}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper ref={draggableRef} {...props} />
        </Draggable>
    );
}

function stringComparison(matchString, data, key) {
    const stringSimilarity = require("string-similarity");
    let match_list = []
    if (key === 'address1') {
        matchString = matchString?.match(/\d+/)?.[0] || matchString
        match_list = data.map(i => {
            if (i[key] && i[key] !== "") {
                let number = i[key].toString().match(/\d+/)
                if (number?.length > 0) {
                    return number[0]
                }
            }
            return i[key]
        })
    }
    else {
        match_list = data.map(i => i[key]?.toLowerCase())
    }
    return stringSimilarity.findBestMatch(matchString?.toLocaleLowerCase(), match_list);
}

function reCheckVendor(str1, str2) {
    if (str1 && str2) {
        let ADDRESS_THRESHOLD = 0.7
        const stringSimilarity = require("string-similarity");
        let compareRating = stringSimilarity.compareTwoStrings(str1.toLowerCase(), str2.toLowerCase())
        return compareRating >= ADDRESS_THRESHOLD
    }
    return false
}

function prepareComparisonList(matches, data) {
    let tempVendorComparisonList = []
    for (let i = 0; i <= data?.length; i++) {
        let vendor = data[i]
        let rating = matches['ratings'][i]
        if (rating) {
            let matchPercentage = (Number(rating?.['rating']) * 100).toFixed(2)
            if (matchPercentage > 30) {
                let newVendorObj = {
                    id: vendor['id'],
                    name: vendor['name'],
                    address1: vendor['address1'],
                    rating: matchPercentage
                }
                tempVendorComparisonList.push(newVendorObj)
            }
        }
    }

    return tempVendorComparisonList
}

function sortByRating(data) {
    return data.sort((a, b) => b.rating - a.rating);
}

function reMatchVendorAddress(matchString, match_list) {
    const stringSimilarity = require("string-similarity");
    matchString = matchString?.toLowerCase() || ""
    return stringSimilarity.findBestMatch(matchString, match_list);
}

const Vendor = ({ invoiceDetails = {} }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { values, touched, errors, setFieldValue } = useFormikContext()

    const client = useSelector(state => state.SNAP_PERSIST_STORE.client)

    const [vendorList, setVendorList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [vendorDialog, setVendorDialog] = useState(false)

    const [vendorComparisonList, setVendorComparisonList] = useState({
        name: [],
        address: []
    })

    useEffect(() => {
        if (values['property']) {
            fetchVendorList();
        }
    }, [values['property']]);

    useEffect(() => {
        if (vendorList.length > 0) {
            if ([0, 1].includes(values['transactionType'])) {
                setFieldValue('vendor', null)
                let data = getRenderVendorList(vendorList)
                vendorValidation(data);
            }
        }
    }, [vendorList, values['transactionType']])

    async function fetchVendorList() {
        setVendorList([])
        // enqueueSnackbar('Getting vendors from nimble', { variant: 'info' });
        let startTime = performance.now();
        setIsLoading(true)
        let propertyId = values['property']['corporationID']
        let data = await getVECOList(propertyId, client)
        data = Array.isArray(data) ? data : []
        // let data = await getVendors(propertyId, client)
        // let data = VECOList.filter(i => i['type'] === 1)
        let endTime = performance.now();
        let timeTaken = endTime - startTime;

        setVendorList(data)
        setIsLoading(false)
        // enqueueSnackbar(`Time taken for getting vendor list from nimble ${timeTaken / 1000} seconds`, { variant: 'info' });

        if (data.length === 0) {
            enqueueSnackbar(`Nimble API Side Issue`, { variant: 'warning' });
        }

        return data
    }

    async function vendorValidation(data) {
        let startTime = performance.now();
        const NAME_THRESHOLD = 0.55 // 55%
        const ADDRESS_THRESHOLD = 1 // 100%
        const { vendor_name, vendor_address, ...rest } = invoiceDetails

        let name_matches;
        let address_matches;

        if ((!vendor_name || vendor_name === "") && (!vendor_address || vendor_address === "")) {
            return
        }

        if (vendor_name && vendor_name !== "") {
            name_matches = stringComparison(vendor_name, data, 'name')
        }

        if (vendor_address && vendor_address !== "") {
            address_matches = stringComparison(vendor_address, data, 'address1')
        }

        let tempNameComparisonList = []
        let tempAddressComparisonList = []

        let name_rating = name_matches?.['bestMatch']?.['rating'] || 0
        let address_rating = address_matches?.['bestMatch']?.['rating'] || 0

        if (name_rating > NAME_THRESHOLD || address_rating === ADDRESS_THRESHOLD) {
            let tempVendor;

            if (name_rating > NAME_THRESHOLD) {
                let best_name_match = name_matches['bestMatchIndex']
                tempVendor = data[best_name_match]
            }
            else if (address_rating === ADDRESS_THRESHOLD) {
                let best_address_match = address_matches['bestMatchIndex']
                if (reCheckVendor(data[best_address_match]['address1'], vendor_address)) {
                    tempVendor = data[best_address_match]
                }
            }

            if (tempVendor && tempVendor['status'] === '1') {
                setFieldValue('vendor', tempVendor)
                let endTime = performance.now();
                let timeTaken = endTime - startTime;
                // enqueueSnackbar(`Time taken for getting vendor ${timeTaken / 1000} seconds`, { variant: 'info' });
            }
        }

        if (name_matches) {
            tempNameComparisonList = prepareComparisonList(name_matches, data)
        }

        if (address_matches) {
            let match_list = data.map(i => i['address1']?.toLowerCase())
            let reMatchAddress = reMatchVendorAddress(vendor_address, match_list)
            tempAddressComparisonList = prepareComparisonList(reMatchAddress, data)
        }

        sortByRating(tempNameComparisonList)
        sortByRating(tempAddressComparisonList)

        setVendorComparisonList({
            name: tempNameComparisonList,
            address: tempAddressComparisonList
        })
    }

    function addVendorToEndAdornment(endAdornment) {
        const children = React.Children.toArray(endAdornment.props.children);
        children.push(
            <Tooltip placement='top' arrow key='add-icon' title='Add Vendor'>
                <Box component={'span'}>
                    <IconButton
                        disabled={values['transactionType'] !== 0}
                        size="small"
                        onClick={() => setVendorDialog(true)}
                    >
                        <GroupAdd fontSize="small" />
                    </IconButton>
                </Box>
            </Tooltip>,
            <Tooltip placement='top' arrow key='refresh-icon' title='Refresh '>
                <Box component={'span'}>
                    <IconButton
                        disabled={values['transactionType'] !== 0}
                        size="small"
                        onClick={fetchVendorList}
                    >
                        <Refresh fontSize="small" />
                    </IconButton>
                </Box>
            </Tooltip>,

        );
        return React.cloneElement(endAdornment, {}, children);
    }

    function closeAddVendorDialog() {
        setVendorDialog(false)
    }

    function getRenderVendorList(data) {
        data.sort((a, b) => a.type - b.type);
        data.forEach(i => {
            let type = i['type']
            // 0 Customer
            // 1 Vendor
            // 2 Employee
            // 13 Others
            switch (type) {
                case 0:
                    i['typeDescription'] = "Customers";
                    break;
                case 1:
                    i['typeDescription'] = "Vendors";
                    break;
                case 2:
                    i['typeDescription'] = "Employee's";
                    break;
                case 13:
                    i['typeDescription'] = "Others";
                    break;
            }
        })

        if ([0, 1].includes(values['transactionType'])) {
            return data.filter(i => i['type'] === 1)
        }
        return data
    }

    const vendorDefaultPurpose = values["vendorDefaultPurpose"]?.["purpose"] || ""

    return !isLoading ?
        <>
            <Autocomplete
                options={getRenderVendorList(vendorList)}
                getOptionLabel={option => option.name}
                value={values['vendor']}
                fullWidth
                size='small'
                onChange={(e, value) => setFieldValue("vendor", value)}
                renderOption={(props, option, { index }) => (
                    <Box {...props} key={index} component={"li"}>
                        {option.name || ""}
                    </Box>
                )}
                getOptionDisabled={(option) => option['status'] !== "1"}
                groupBy={(option) => [0, 1].includes(values['transactionType']) ? undefined : option.typeDescription}
                renderInput={params => (
                    <TextField
                        {...params}
                        required
                        label={[0, 1].includes(values['transactionType']) ? "Vendors" : "Payee Name"}
                        error={
                            Boolean(touched.vendor && errors.vendor) ||
                            values['vendor']?.address1 === ""
                        }
                        helperText={
                            <>
                                {touched.vendor ?
                                    errors.vendor :
                                    vendorList.length === 0 ?
                                        'Vendors not found' :
                                        (values["contract"]?.['address1'] && values["contract"]?.['address1'] !== "") ?
                                            values["contract"]?.['address1'] :
                                            (values['vendor']?.address1 === "") ?
                                                "Address not found" :
                                                values['vendor']?.address1}
                                <Box component={'span'} sx={{ float: 'right' }}>
                                    {vendorDefaultPurpose.replace(/_/g, ' ')}
                                </Box>
                            </>
                        }
                        FormHelperTextProps={{ sx: { color: 'black' } }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: addVendorToEndAdornment(params.InputProps.endAdornment)
                        }}
                    />
                )}
            />

            <Dialog
                PaperComponent={PaperComponent}
                onClose={closeAddVendorDialog}
                open={vendorDialog}
                fullWidth
                maxWidth='md'
                TransitionComponent={Transition}
            >
                <Toolbar
                    id="draggable-dialog-title" //!Its important need to drag
                    variant='dense'
                    sx={{ borderBottom: 1, borderColor: 'divider', cursor: 'move' }}
                >
                    <Typography flexGrow={1} variant='h5'>Add Vendor</Typography>
                    <IconButton onClick={closeAddVendorDialog}>
                        <Clear />
                    </IconButton>
                </Toolbar>

                <DialogContent sx={{ p: 0 }}>
                    <Stack gap={1} p={1}>
                        <AddVendor invoiceDetails={invoiceDetails} closeAddVendorDialog={closeAddVendorDialog} reFetchingVendors={fetchVendorList} />
                        <Divider />
                        <Stack direction={'row'} gap={1}>
                            {vendorComparisonList['name'].length > 0 &&
                                <List sx={{ border: 1, borderColor: 'divider', width: '50%', }} disablePadding>
                                    <ListItem divider>
                                        <ListItemText primary={'Name Comparison'} />
                                    </ListItem>
                                    {vendorComparisonList['name'].map((vendor, index) =>
                                        <ListItemButton
                                            key={index}
                                            divider={vendorComparisonList.length !== index + 1}
                                            onClick={() => {
                                                closeAddVendorDialog()
                                                let tempVendor = vendorList.find(i => i['id'] === vendor['id']) || null
                                                setFieldValue('vendor', tempVendor)
                                            }}
                                        >
                                            <ListItemSecondaryAction>
                                                <Chip label={`${vendor['rating']}%`} color='info' />
                                            </ListItemSecondaryAction>

                                            <ListItemText
                                                primary={vendor['name']}
                                                secondary={vendor['address1']}
                                            />
                                        </ListItemButton>
                                    )}
                                </List>
                            }

                            {vendorComparisonList['address'].length > 0 &&
                                <List sx={{ border: 1, borderColor: 'divider', width: '50%' }} disablePadding>
                                    <ListItem divider>
                                        <ListItemText primary={'Address Comparison'} />
                                    </ListItem>
                                    {vendorComparisonList['address'].map((vendor, index) =>
                                        <ListItemButton
                                            key={index}
                                            divider={vendorComparisonList.length !== index + 1}
                                            onClick={() => {
                                                closeAddVendorDialog()
                                                let tempVendor = vendorList.find(i => i['id'] === vendor['id']) || null
                                                setFieldValue('vendor', tempVendor)
                                            }}
                                        >
                                            <ListItemSecondaryAction>
                                                <Chip label={`${vendor['rating']}%`} color='info' />
                                            </ListItemSecondaryAction>

                                            <ListItemText
                                                primary={vendor['name']}
                                                secondary={vendor['address1']}
                                            />
                                        </ListItemButton>
                                    )}
                                </List>
                            }
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
        :
        <SuspenseLoading loading={isLoading} />

}

export default Vendor