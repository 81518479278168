import React, { memo, useEffect, useMemo, useState } from 'react'
import {
    Paper,
    Stack,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    CircularProgress,
    LinearProgress,
    Box,
} from '@mui/material'
import BillSummary from './BillSummary/BillSummary';
import ToBeApproved from './ToBeApproved/ToBeApproved';
import moment from 'moment';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { updateClient, updateClientsList, updateProperty, updatePropertiesList } from '../../Redux/actions';
import { getBills, getToBeApprovedBills, getVerifiedBills, summaryBills } from '../../API/API';
import { formattedTimeStamp, getTimeStamp } from '../../Tools/formattedTimeStamp';
import { Bill_Process_Status } from '../../Tools/constants';
import { s3ToFileName } from '../../Tools/s3ToFileName';

function BillCard({
    tab = '',
    count = 0,
    loading
}) {
    return (
        <>
            <Typography fontSize={15} color={'#8a909d'} > {tab}</Typography>
            {loading ?
                <Box pt={2} pb={1}>
                    <LinearProgress />
                </Box>
                :
                <Typography fontSize={25} fontWeight={'bold'} color={'black'}>{count}</Typography>
            }
        </>
    )
}

function Tab({ tab, index, children }) {
    return (
        <Paper
            sx={{
                display: tab !== index ? 'none' : 'block',
                p: 1.5,
                borderRadius: 2,
                flexGrow: 1
            }}
        >
            {children}
        </Paper>
    );
};

let _BillSummaryController;
let _BillApprovedController;

const Bills = () => {

    const dispatch = useDispatch()

    const selectedClient = useSelector(state => state.SNAP_PERSIST_STORE.client)
    const selectedProperty = useSelector(state => state.SNAP_PERSIST_STORE.property)
    const cp_access_id = useSelector(state => state.SNAP_PERSIST_STORE.cp_access_id)

    const defaultTab = Number(sessionStorage.getItem('tabvalue') || 0)
    const [tab, setTab] = useState(defaultTab)
    const [toBeApprovedBills, setToApprovedBills] = useState()
    const [postedBills, setPostedBills] = useState([])
    const [isFetchingPostedBills, setIsFetchingPostedBills] = useState(false)
    const [isFetchingToBeApprovedBills, setIsFetchingToBeApprovedBills] = useState(false)
    const [dateRange, setDateRange] = useState([startOfDay(subDays(new Date(), 6)), endOfDay(new Date())])

    useEffect(() => {
        fetchPostedBills()
    }, [selectedProperty, selectedClient, dateRange])

    useEffect(() => {
        fetchToBeApprovedBills()
    }, [selectedProperty, selectedClient])

    const TabButton = ({ tabNumber, label }) => {
        const isActive = tab === tabNumber;
        return (
            <Card sx={{ borderRadius: 2, boxShadow: 'none', }}>
                <CardActionArea
                    onClick={() => setTab(tabNumber)}
                    sx={{
                        backgroundColor: isActive ? '#E5E5FF' : 'transparent',
                        textAlign: 'center',
                    }}
                >
                    <CardContent
                        sx={{
                            pl: 12,
                            pr: 12,
                            pt: 1,
                            pb: 1,
                            height: 72
                        }}
                    >
                        {label}
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    };

    async function fetchPostedBills(client = selectedClient) {
        setPostedBills([])
        if (dateRange && ((client && selectedProperty) || client['url'] === "all")) {
            setIsFetchingPostedBills(true)
            let body = {
                client_url: client['url'],
                property_id: selectedProperty,
                cp_access_id: cp_access_id,
                start: moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss'),
            }
            let bills = await getVerifiedBills(body)
            bills.forEach(i => {
                let transac_type = i.payload.Transac_type
                if (i.payload.hasOwnProperty('AttachmentInfo')) {
                    i.payload.Transac_type = 'Attach to exist';
                } else {
                    switch (transac_type) {
                        case 0:
                            i.payload.Transac_type = 'Entry';
                            break;
                        case 1:
                            i.payload.Transac_type = 'Pay';
                            i.payload.InvoiceNumber = i.payload.RefNumber;
                            i.payload.BillDate = i.payload.EntryDate;
                            break;
                        case 2:
                            i.payload.Transac_type = 'Check';
                            i.payload.InvoiceNumber = i.payload.RefrenceNumber;
                            i.payload.BillDate = i.payload.Date;
                            break;
                        case 3:
                            i.payload.Transac_type = 'Card';
                            i.payload.InvoiceNumber = i.payload.RefrenceNumber;
                            i.payload.BillDate = i.payload.Date;
                            break;
                    }
                }
                i.payload.createdTime = getTimeStamp(i.payload.createdTime, 'Asia/Kolkata')
                i.payload.received_time = getTimeStamp(i.payload.received_time, 'Asia/Kolkata')
            })
            setPostedBills(bills)
            setIsFetchingPostedBills(false)
        }
    }

    async function fetchToBeApprovedBills() {
        setToApprovedBills([])
        if ((selectedClient && selectedProperty) || selectedClient['url'] === "all") {
            setIsFetchingToBeApprovedBills(true)
            let body = {
                client_url: selectedClient['url'],
                property_id: selectedProperty,
                cp_access_id: cp_access_id,
                status: [Bill_Process_Status.TO_BE_POSTED]
            }
            let bills = await getBills(body)
            bills.forEach((obj) => {
                obj['file_name'] = s3ToFileName(obj['s3_key'])
                obj['formatted_timestamp'] = getTimeStamp(obj['timestamp'], 'Asia/Kolkata')
            });
            setToApprovedBills(bills)
            setIsFetchingToBeApprovedBills(false)
        }
    }

    async function fetchBills() {
        fetchPostedBills();
        fetchToBeApprovedBills();
    }

    return useMemo(() =>
        <Stack gap={2} height={'100%'}>
            <Paper sx={{ display: 'flex', justifyContent: 'center', gap: 2, borderRadius: 2, p: 1.5 }}>
                <TabButton
                    tabNumber={0}
                    label={
                        <BillCard
                            loading={isFetchingPostedBills}
                            count={postedBills?.length}
                            tab='Posted Bills'
                        />
                    }
                />
                <TabButton
                    tabNumber={1}
                    label={
                        <BillCard
                            loading={isFetchingToBeApprovedBills}
                            count={toBeApprovedBills?.length}
                            tab='To Be Posted'
                        />
                    }
                />
            </Paper>

            <Tab tab={tab} index={0}>
                <BillSummary
                    data={postedBills}
                    dateRange={dateRange}
                    loading={isFetchingPostedBills}
                    setDateRange={setDateRange}
                    fetchPostedBills={fetchPostedBills}
                />

            </Tab>

            <Tab tab={tab} index={1}>
                <ToBeApproved
                    data={toBeApprovedBills}
                    loading={isFetchingToBeApprovedBills}
                    callback={fetchBills}
                />
            </Tab>
        </Stack>,
        [postedBills, toBeApprovedBills, tab])
}

export default Bills