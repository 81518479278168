import axios from "axios"
import controllers from "./controllersObject";

const BACKEND_API =
    process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_BACKEND_API_PRODUCTION
        : process.env.REACT_APP_BACKEND_API_STAGING;

const backend = axios.create({
    baseURL: `/api`
})

export async function userLogin(userData) {  //Contains a email and password (FormData)
    try {
        // await backend.get("/setSession") //Starting Session
        const response = await backend.post("/login", userData)
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function logout() {
    try {
        await backend.get("/signout",)
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchAccessPermission(access_id) {
    try {
        const response = await backend.get("/get_access_permissions", {
            params: { access_id }
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchServiceRoutes(group_id) {
    if (controllers.SERVICE_ROUTES) {
        controllers.SERVICE_ROUTES.abort()
    }
    controllers.SERVICE_ROUTES = new AbortController()
    try {
        const response = await backend.get("/get_service_routes", {
            params: { group_id },
            signal: controllers.SERVICE_ROUTES.signal
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchAllowedActions(service_id, group_id) {
    if (controllers.ALLOWED_ACTIONS) {
        controllers.ALLOWED_ACTIONS.abort()
    }
    controllers.ALLOWED_ACTIONS = new AbortController()
    try {
        const response = await backend.get("/get_allowed_actions", {
            params: { service_id, group_id },
            signal: controllers.ALLOWED_ACTIONS.signal
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchAdminClientsList(cp_Access_id) {
    if (controllers.ADMIN_CLIENTS_LIST) {
        controllers.ADMIN_CLIENTS_LIST.abort()
    }
    controllers.ADMIN_CLIENTS_LIST = new AbortController()
    try {
        const response = await backend.get("/admin_dashboard/invoice_processing/bill_stats_by_clients", {
            params: { cp_Access_id },
            signal: controllers.ADMIN_CLIENTS_LIST.signal
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchSortOrder() {
    try {
        const response = await backend.get("/get_client_sort_order")
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchPropertySortOrder(client_uuid, client_url) {
    try {
        const response = await backend.get("/get_property_sort_order", { params: { client_uuid, client_url } })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchAdminPropertiesList({ cp_Access_id = null, client_uuid = null, client_url }) {
    if (controllers.ADMIN_PROPERTIES_LIST) {
        controllers.ADMIN_PROPERTIES_LIST.abort()
    }
    controllers.ADMIN_PROPERTIES_LIST = new AbortController()
    try {
        const response = await backend.get("/admin_dashboard/invoice_processing/bill_stats_by_properties", {
            params: { cp_Access_id, client_uuid, client_url },
            signal: controllers.ADMIN_PROPERTIES_LIST.signal
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchUnknownPropertyStats(client_uuid) {
    try {
        const response = await backend.get("/user_dashboard/invoice_processing/bill_stats_for_unknown_property", {
            params: { client_uuid },
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchUserPropertiesList(cp_Access_id) {
    try {
        const response = await backend.get("/user_dashboard/invoice_processing/bill_stats_by_properties", {
            params: { cp_Access_id }
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchClientList(cp_Access_id) {
    try {
        const response = await backend.get("/get_client_list", {
            params: { cp_Access_id, }
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}


export async function fetchPropertiesList(cp_Access_id, client_uuid, client_url) {
    try {
        const response = await backend.get("/get_properties_list", {
            params: { cp_Access_id, client_uuid, client_url }
        })
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function move_to_need_attention(body) {
    try {
        const response = await backend.post("/failed_fails/move_to_need_attention", body)
        return response.data
    }
    catch (error) {
        console.log(error);
    }
}

export async function fetchBillDetails(billId) {
    try {
        const response = await backend.get("/bill_details", {
            params: { billId },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function fetchPageProperties(file_table_id) {
    try {
        const response = await backend.get("/bill/page_properties/", {
            params: { file_table_id },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function fetchBlockedPages(file_table_id) {
    try {
        const response = await backend.get("/failed/blocked_pages", {
            params: { file_table_id },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function fetchModalRecognizedPages(file_table_id) {
    try {
        const response = await backend.get("/failed/modal_recognized_pages", {
            params: { file_table_id },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function SplitAndSendToQueue(body) {
    try {
        const response = await backend.post("/bill/add_to_queue", body);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getEmailBody(email_table_id) {
    try {
        const response = await backend.get("/get_emailbody", { params: { email_table_id } });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function splitBills(body) {
    try {
        const response = await backend.post('/split_bills', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function markNotABill(body) {
    try {
        const response = await backend.post('/bill/not_a_bill', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function toBePostedUpdateBill(body) {
    try {
        const response = await backend.post('/bills/to_be_posted/update_bill', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function needAttentionUpdateBill(body) {
    try {
        const response = await backend.post('/bills/need_attention/update_bill', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function setBillasInactive(body) {
    try {
        const response = await backend.post('/set_bill_as_duplicate', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function setBillasFailed(body) {
    try {
        const response = await backend.post('/set_bill_as_failed', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function postToSendMail(body) {
    try {
        const response = await backend.post('/send_mail', body)
        return response
    } catch (error) {
        console.log(error)
    }
}

export async function upload(body) {
    try {
        const response = await backend.post('/upload', body,
            { headers: { "Content-Type": "multipart/form-data" } })

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function summaryBills(body) {
    try {
        const response = await backend.post('/summary_bills', body,
            { headers: { "Content-Type": "application/json" } })

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function getToBeApprovedBills(property, selectedClient, cp_access_id) {
    try {
        const response = await backend.post("/to_be_approved_bills", null, {
            params: {
                'propertyId': property,
                'clientID': selectedClient?.userID,
                'cp_access_id': cp_access_id,
                'client_url': selectedClient['url']
            },
            headers: { 'Content-Type': 'application/json' },
        });

        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function getInactiveBills(selectedProperty, client, cp_access_id, client_url) {
    try {
        const response = await backend.post("/inactive_bills", null, {
            params: {
                'propertyId': selectedProperty,
                'clientID': client.userID,
                "cp_access_id": cp_access_id,
                'client_url': client.url
            },
            headers: { 'Content-Type': 'application/json' }
        })

        return response.data;
    } catch (error) {
        console.log(error)
    }
}


export async function apiCheckMail(email) {
    try {
        const response = await backend.get("/check/email", {
            params: { "email": email }
        })

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function apiCheckOTP(body) {
    try {
        const response = await backend.post("/check/otp", body)

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function apiPassword(body) {
    try {
        const response = await backend.post("/password", body)

        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function failedBills(propertyId, selectedClient, cp_access_id) {
    try {
        const response = await backend.post("/failed_bills", null, {
            params: { 'propertyId': propertyId, 'clientID': selectedClient.userID, cp_access_id, client_url: selectedClient.url },
            headers: { 'Content-Type': 'application/json' },
        })

        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function send_mail(body) {
    try {
        const response = await backend.post('/send_mail', body, { headers: { 'Content-Type': 'application/json' } })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function getOCR_Response(file_id) {
    try {
        const response = await backend.get('/ocr_response', { headers: { 'Content-Type': 'application/json' }, params: { file_id } })

        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function get_ocr_text(file_id, checked_pages) {
    try {
        const response = await backend.post('/get_ocr_text', { file_id, checked_pages })
        return response
    } catch (error) {
        console.log(error)
    }
}

export async function update_verified_table(body) {
    try {
        const response = await backend.post('/bill/update_verified_table', body)
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function update_bill_status(body) {
    try {
        const response = await backend.post('/bill/update_bill_status', body)
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function remove_bill(bill_id, status) {
    try {
        const response = await backend.get('/bill/remove_bill/', { params: { bill_id, status } })
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function update_mail_body(body) {
    try {
        const response = await backend.post('/bill/update_mail_body/', body)
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function update_track_changes(body) {
    try {
        const response = await backend.post('/bill/track_changes', body)
        return response;
    } catch (error) {
        console.log(error)
    }
}

export async function get_track_changes(from_date, to_date) {
    try {
        const response = await backend.get('/accuracy/entities/', { params: { from_date, to_date } })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function get_users_data(from_date, to_date) {
    try {
        const response = await backend.get('/accuracy/users_data/', { params: { from_date, to_date } })
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function getBills(body) {
    try {
        const response = await backend.post('/get_bills', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function getVerifiedBills(body) {
    try {
        const response = await backend.post('/get_posted_bills', body)
        return response.data;
    } catch (error) {
        console.log(error)
    }
}