import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';

import { useNavigate } from 'react-router-dom';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session' // defaults to session for web
import { encryptTransform } from 'redux-persist-transform-encrypt';

import { SNAP_STORE } from './reducer';
import { SNAP_PERSIST_STORE } from './persistReducer';

const reducer = combineReducers({
    SNAP_PERSIST_STORE,
    SNAP_STORE
})

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        transforms: [
            encryptTransform({
                secretKey: 'sjadjoaskdhjfjkewugq5j', //Add unique key based every user do not maintain for every user
                onError: function (error) {
                    console.log(error)
                },
            }),
        ],
        whitelist: ['SNAP_PERSIST_STORE']
    },
    reducer
);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: persistedReducer,
    middleware: () => [sagaMiddleware],
    devTools: process.env.NODE_ENV !== "production"
});
const persistor = persistStore(store)

export { store, persistor };