import React from 'react'
import { useRouteLoaderData } from 'react-router-dom'
import AdminInvoiceProcessing from './AdminInvoiceProcessing/AdminInvoiceProcessing'
import { Box } from '@mui/material'

const AdminDashboard = ({ microServices }) => {

    const MicroServiceCard = ({ value }) => {
        let service_name = value['micro_service_name']

        const components = {
            invoice_processing: AdminInvoiceProcessing
            // daily_sales: ,
            // str: ,
            // payroll: ,
        };
        const Component = components[service_name];

        if (!Component) {
            return null; // or handle unknown service names accordingly
        }

        return (
            <Component serviceDetails={value} />
        );
    };

    return microServices.map((service, serviceIndex) =>
        <Box key={serviceIndex} height={'100%'}>
            <MicroServiceCard value={service} />
        </Box>
    )
}

export default AdminDashboard