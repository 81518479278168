import { useEffect, useState } from "react";
import {
    Box,
    Button,
    List,
    ListItem,
    Stack,
    Typography,
    IconButton,
    ListItemText,
    ListItemIcon,
    Backdrop,
    CircularProgress,
} from "@mui/material";
// import { fetchuser } from "../Helpers";

import { Delete } from "@mui/icons-material";

import "./Upload.css";
import { useSelector } from 'react-redux'
import { styled } from "@mui/material/styles";
import { upload } from "../../API/API";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

let _uploadController;

export default function Upload({ callback = () => { }, setAlert, property, client }) {

    let user = JSON.parse(sessionStorage.getItem('user'))
    const [memo, setMemo] = useState('')
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUplaoding] = useState(false)

    const handleFileDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        const allowedExtensions = [
            '.pdf', '.png', '.jpg', '.jpeg', '.bmp', '.tif', '.tiff', '.gif',
            '.xlsx', '.xls', '.csv', '.rar', '.zip'
        ];

        const filteredFiles = droppedFiles.filter(file => {
            const fileNameParts = file.name.split('.');
            const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
            return allowedExtensions.includes(`.${fileExtension}`);
        });

        const unsupportedFiles = droppedFiles.filter(file => !filteredFiles.includes(file));

        if (unsupportedFiles.length > 0) {
            setAlert({ message: `Unsupported file(s): ${unsupportedFiles.map(file => file.name).join(', ')}`, open: true, severity: 'error' })
        }

        setFiles([...files, ...filteredFiles]);

        // let allowedExt = /\.(pdf|png|jpg|jpeg|bmp|tif|tiff|gif|xlsx|xls|csv|rar|zip)$/i;
        // if (!allowedExt.exec(file.name)) {
        //   alert('Please upload file having extensions pdf|png|jpg|jpeg|bmp|tif|tiff|gif|xlsx|xls|csv|rar|zip only.');
        //   fileInput.value = '';
        //   return false;
        // }
        // setFiles([...files, ...droppedFiles]);

    };

    const handleFileInputChange = (event) => {
        event.preventDefault();
        const selectedFiles = Array.from(event.target.files);
        setFiles([...files, ...selectedFiles]);
        event.target.value = null
    };

    function removeFile(index) {
        let tempFileList = [...files];
        tempFileList.splice(index, 1);
        setFiles(tempFileList);
    }

    function createCancelSignal() {
        if (_uploadController) {
            _uploadController.abort()
        }

        _uploadController = new AbortController()
        return _uploadController.signal
    }

    const handleSubmit = async () => {
        setIsUplaoding(true)
        let signal = createCancelSignal()
        const formData = new FormData();
        let data = {
            client: client.name,
            uploader: user.email,
            property: property,
            memo: memo
        };
        formData.append("uploadFields", JSON.stringify(data));

        files.forEach(file => formData.append("files", file))

        try {
            const output = await upload(formData)

            if (output.status == 100) {
                setAlert({ message: output.message, open: true, severity: 'success' })
                setFiles([]);
                setMemo('')
                callback()
            }
            else {
                setAlert({ message: output.message, open: true, severity: 'warning' })
            }

        } catch (error) {
            setAlert({ message: `Error ${error}`, open: true, severity: 'error' })
        }

        finally { setIsUplaoding(false) }
    };

    function fetchTotalFileSize() {
        if (files.length) {
            let totalMB = files.reduce((acc, obj) => acc + obj.size, 0)
            return bytesToMB(totalMB)
        }
        return 0
    }

    function bytesToMB(value) {
        return (value / (1024 * 1024))
    }

    useEffect(() => {
        if (fetchTotalFileSize() > 80) {
            setAlert({ message: `Reached max size(${fetchTotalFileSize().toFixed(2)}MB) limit.`, open: true, severity: 'error' })
        }
    }, [files])

    return (
        <>

            <Typography color={"#151D48"} fontWeight={600}>Memo</Typography>

            <Box
                name="memo"
                className="upload-memo"
                component={"textarea"}
                rows={2}
                onChange={(e) => setMemo(e.target.value)}
            />

            <Box
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
                component={"label"}
                className="drag-drop-wrapper"
            >
                <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileInputChange}
                    multiple
                    accept=".pdf, .png, .jpg, .jpeg, .bmp, .tif, .tiff, .gif, .xlsx, .xls, .csv, .rar, .zip"
                />
                <Typography>Drag & Drop files here or click to upload</Typography>
            </Box>

            <List dense sx={{ width: '100%' }}>
                {files.map((file, fileIndex) =>
                    <ListItem
                        dense
                        secondaryAction={
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <ListItemText>{bytesToMB(file.size).toFixed(2)}MB</ListItemText>
                                <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={() => removeFile(fileIndex)}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </Stack>
                        }
                        key={fileIndex}
                    >
                        <ListItemIcon>{fileIndex + 1}.</ListItemIcon>
                        <ListItemText>{file.name}</ListItemText>
                    </ListItem>
                )}
            </List>

            <Button
                variant="contained"
                color={"add"}
                sx={{ color: "white", borderRadius: 5 }}
                onClick={handleSubmit}
                disabled={
                    property == undefined ||
                    files.length == 0 ||
                    fetchTotalFileSize() > 80
                }
            >
                Submit
            </Button>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUploading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    );
}
