import {
    createBrowserRouter,
    Navigate,
    Outlet,
    useNavigate,
    useParams,
    useRouteLoaderData
} from 'react-router-dom';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import Login from '../Pages/Login/Login';
import { Alert, Box, Button, Dialog, Snackbar, Stack } from '@mui/material';
import Home from '../Pages/Home/Home';
import { fetchAllowedActions, fetchServiceRoutes } from '../API/API';
import { useEffect, useMemo, useRef, useState } from 'react';
import { routings } from '../Tools/fetchRoutes';
import { SuspenseLoading } from '../Components/SuspenseLoading';
import { HomeMaxOutlined, HomeOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateCpAccessID } from '../Redux/actions';
import Users from '../Pages/Users/Users';

function ServiceComponent() {
    let params = useParams()
    let service_route = routings.find(i => i['route'] === params['service_route'])
    return service_route.component
}

function MicroServiceLayout() {
    const dispatch = useDispatch()
    const navigation = useNavigate()
    let defaultServiceRoutes = {
        invoice_processing: 'bills',
        daily_sales: 'received_sales',
        str: 'reports',
        payroll: ''
    }

    let params = useParams()
    let micro_service_route = params['micro_service_route']
    let service_route = params['service_route']

    let default_service_route = defaultServiceRoutes[micro_service_route]

    let microServiceData = useSelector(state => state.SNAP_PERSIST_STORE.microServiceData)

    let microServiceAvailable = microServiceData['micro_services']?.find(i => i['micro_service_name'] === micro_service_route)

    const [serviceRoutes, setServiceRoutes] = useState(null)
    const [allowedActions, setAllowedActions] = useState(null)
    const loadRef = useRef(true)
    const serviceRoute = serviceRoutes ? serviceRoutes.find(i => i['service_name'] === (service_route || default_service_route)) : null

    useEffect(() => {
        if (microServiceAvailable) {
            getServiceRoutes()
            dispatch(updateCpAccessID(microServiceAvailable['cp_Access_id']))
        }
        if (!service_route) {
            navigation(`/${micro_service_route}/${default_service_route}`)
        }
    }, [])

    useEffect(() => {
        if (microServiceAvailable) {
            if (serviceRoute) {
                getAllowedActions()
            }
        }
    }, [serviceRoute])

    async function getServiceRoutes() {
        loadRef.current = true
        setServiceRoutes(null)
        let tempServices = await fetchServiceRoutes(microServiceAvailable['group_id'], microServiceAvailable['group_id'])
        setServiceRoutes(tempServices)
    }

    async function getAllowedActions() {
        setAllowedActions(null)
        let tempAllowedActions = await fetchAllowedActions(serviceRoute['service_id'], microServiceAvailable['group_id'])
        setAllowedActions(tempAllowedActions)
        loadRef.current = false
    }

    return microServiceAvailable ?
        <>
            <NavBar routes={serviceRoutes} />
            <Box marginLeft={'80px'} height={'100%'} overflow={'auto'}>
                {serviceRoute ?
                    <Outlet
                        context={{ allowedActions: allowedActions }}
                    />
                    :
                    <SuspenseLoading loading={loadRef.current} NotFound={`This Service '${service_route}' is not available `} />
                }
            </Box>
        </>
        :
        <ErrorDialog error={`This Micro-Service '${micro_service_route}' is not available for this user`} />
}

const ErrorDialog = ({ error }) => {
    const navigation = useNavigate()
    return (
        <Dialog fullScreen open>
            <Snackbar
                open={true}
                message={error}
                action={
                    <Button size='small' color='success' startIcon={<HomeOutlined />} variant='contained' onClick={() => navigation('/home')}>
                        Home
                    </Button>
                }
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            />
        </Dialog>
    )
}

function HomeRouteError() {
    let params = useParams()
    return <ErrorDialog error={`There is no route found with this name '${params["*"]}' go back to home`} />
}

function CheckUserExit({ children }) {
    let user = sessionStorage.getItem('user')
    let user_token = user //use JWT token for this
    return user_token ?
        <Navigate to='/home' replace />
        :
        children
}

const PrivateRoute = () => {
    let user = sessionStorage.getItem('user')
    let isAuthenticated = user //use JWT token for this

    let params = useParams()
    let service_route = params['service_route']

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <Stack height={'100vh'} gap={1.5} sx={{ backgroundColor: '#f8f8ff' }}>
            <Box marginLeft={service_route ? '80px' : null}>
                <Header />
            </Box>
            <Box flexGrow={1} pl={1.5} pr={1.5} overflow={'hidden'} >
                <Outlet />
            </Box>
            <Footer />
        </Stack>
    )
};

const router = createBrowserRouter([
    {
        path: "/",
        element:
            <CheckUserExit>
                <Login />
            </CheckUserExit>
    },
    {
        element: <PrivateRoute />,
        children: [
            {
                path: '/home',
                element: <Home />
            },
            {
                element: <MicroServiceLayout />,
                path: '/:micro_service_route',
                children: [
                    {
                        path: ':service_route',
                        element: <ServiceComponent />,
                    }
                ]
            },
            {
                path: "/home/*",
                element: <HomeRouteError />
            },
        ]
    },
    {
        path: '/65f347a930e46303038daf11',
        element: <Users />
    },
]);

export default router